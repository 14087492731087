import { Button, Card, CardActions, CardContent, Grid, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { Couleur } from '../../Header'
import GalerieService from "../../services/GalerieService";
import Swal from "sweetalert2";
import { baseUrl } from '../../services/module';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';

const SuccessNotification = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

export const GalerieZomatel = () => {
    const [listGalerie, setListGalerie] = useState([])
    const [galerie, setGalerie] = useState(null)
    const [number, setNumber] = useState(0)

    useEffect(() => {
        GalerieService.listGalerie()
            .then(res => {
                setListGalerie(res.data.data)
                updateNumber(res.data.data)
            })
    }, [])

    const imageRef = useRef();

    const onChangePhoto = (e) => {
        const fileList = [];
        for (let i = 0; i < e.target.files.length; i++) {
            fileList.push(e.target.files[i]);
        }
        setGalerie(fileList);
    };

    const updateNumber = (data) => {
        let nbr = 0
        data.map((gal) => (gal.show !== 0) && (nbr += 1))
        setNumber(nbr)
    }

    const onAdd = (e) => {
        e.preventDefault()

        GalerieService.storeGalerie({ "image_path": galerie })
            .then(res => {
                SuccessNotification.fire({
                    icon: "success",
                    title: res.data.message,
                });
                setListGalerie(res.data.data)
                updateNumber(res.data.data)
                imageRef.current.value = null;
                setGalerie(null);
            })
    }

    const onCheck = id => {
        GalerieService.updateGalerie(id)
            .then(res => { setListGalerie(res.data.data); updateNumber(res.data.data) })
    }

    const onDelete = (id) => {
        Swal.fire({
            title: 'Suppression',
            text: "Cette action est irreversible, continuer?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#37474F',
            confirmButtonText: 'oui, continuer',
            cancelButtonText: 'annuler',
        }).then((result) => {
            if (result.isConfirmed) {
                GalerieService.deleteGalerie(id)
                    .then(res => {
                        setListGalerie(res.data.data)
                        updateNumber(res.data.data)
                        SuccessNotification.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                    })
                    .catch(err => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oups...',
                            text: 'Opération non effectuée',
                        });
                    })
            }
        })
    }

    return (
        <Box>
            <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
                <Box component='ol' className="breadcrumb mb-4" sx={{ backgroundColor: Couleur.jaune, p: 2, width: '100%', borderRadius: '4px' }}>
                    <li className="breadcrumb-item"><a href="#!"><label>Page actuelle :</label></a></li>
                    <li className="breadcrumb-item"><a href="#!">Dashboard</a></li>
                    <li className="breadcrumb-item active">Galerie</li>
                </Box>
            </Box>

            <Box>
                <Card sx={{ maxWidth: 350, ml: 5 }}>
                    <Box component='form' onSubmit={(e) => onAdd(e)}>
                        <CardActions>Nouveau</CardActions>
                        <CardContent>
                            <Box mt={3}
                                component="input"
                                id="photo"
                                type="file"
                                accept="image/*"
                                multiple
                                ref={imageRef}
                                onChange={(e) => onChangePhoto(e)}
                                sx={{
                                    fontFamily: "serif, arial",
                                    fontSize: "18px",
                                    color: Couleur.bordeau,
                                    cursor: "pointer",
                                }}
                            ></Box>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" type="submit">
                                ajouter
                            </Button>
                        </CardActions>
                    </Box>
                </Card>
            </Box>
            <Box px={5} my={5}>
                <Box component="p" fontSize="22px">{number} {number < 1 ? "image séléctionnée" : "images séléctionnées"}</Box>
                <Grid container spacing={3}>
                    {
                        listGalerie.length > 0 &&
                        listGalerie.map((galerie) => (
                            <Grid key={galerie.id} item xs={12} sm={6} md={4} lg={3} sx={{ maxHeight: 350, position: 'relative' }}>
                                <Box component="img" src={baseUrl + galerie.image_path} sx={{ width: '100%', height: '100%' }} alt='image' />
                                <Box sx={{ position: "absolute", display: "flex", justifyContent: "space-between", bottom: '0px', width: '95%' }}>
                                    <Box>
                                        <Checkbox sx={{ color: 'white' }} onChange={() => onCheck(galerie.id)} checked={ galerie.show === 1 ? true : false } />
                                    </Box>
                                    <Box>
                                        <IconButton aria-label="delete" size="large" onClick={() => onDelete(galerie.id)}>
                                            <DeleteIcon color='error' />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </Box>
    )
}
