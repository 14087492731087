import React, { useState, useEffect } from "react";
import { HighlightOffOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, NativeSelect } from "@mui/material";
import { Couleur } from "../../../Header";
import ReserverService from "../../../services/ReserverService";

const MAX_PERS = 4;

const ChambreResume = (props) => {
  const [modelChambre, setModelChambre] = useState({});
  const [maxPers, setMaxPers] = useState(MAX_PERS);
  const [person, setPerson] = useState({
    adults: maxPers,
    kids: maxPers - 1,
  });
  const [chambre, setChambre] = useState(0);
  const [isModifiable, setIsModifiable] = useState(true);
  const { index, removeRoom } = props;

  useEffect(() => {
    setModelChambre(props.modelChambre);
  }, [props.modelChambre]);

  useEffect(() => {
    setIsModifiable(props.modifiable);
  }, [props.modifiable]);
  useEffect(() => {
    setChambre(props.chambreSelected);
  }, [props.chambreSelected]);
  useEffect(() => {
    ReserverService.getMaxPerson().then((res) => {
      setMaxPers(res.data.data);
      setPerson({
        adults: res.data.data,
        kids: res.data.data - 1,
      });
    });
  }, []);

  const inputChange = (target, index) => {
    const { name, value } = target;
    const newChambre = {
      ...modelChambre,
      [name]: value,
      chambre: index + 1,
    };
    setModelChambre({ ...newChambre });
    props.onChange(newChambre, index);

    let copy_person = { ...person };
    if (name === "kids") {
      copy_person.adults = maxPers - value;
    } else {
      copy_person.kids = maxPers - value;
    }
    setPerson(copy_person);
  };

  const htmlSelection = (dep = 0, max) => {
    let html = [];
    for (let i = dep; i <= max; i++) {
      html.push(
        <Box component="option" sx={{ color: "black" }} value={i}>
          {i}
        </Box>
      );
    }
    return html;
  };
  return (
    <Box my={4} border="1px solid white" p={2} key={index} position="relative">
      {!props.isShowInfoClient && (
        <Box
          sx={{
            position: "absolute",
            display: `${index === 0 ? "none" : "block"}`,
            right: "-20px",
            top: "-20px",
          }}
        >
          <IconButton
            onClick={(e) =>
              removeRoom(
                index,
                chambre.tarif ? chambre.tarif : 0,
                chambre ? chambre : null
              )
            }
          >
            <HighlightOffOutlined
              sx={{
                backgroundColor: "white",
                borderRadius: "50%",
                fill: "black",
              }}
            />
          </IconButton>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between">
        <Box component="span" fontWeight="bold" className="family">
          CHAMBRE {index + 1}
        </Box>
        {chambre !== 0 && (
          <Box component="span" className="family resumeText">
            {chambre.type}
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" my={2}>
        <Box component="span" className="family">
          ADULTES
        </Box>
        {isModifiable ? (
          <Box>
            <NativeSelect
              onChange={(e) => inputChange(e.target, index)}
              value={modelChambre.adults}
              inputProps={{
                name: "adults",
              }}
              sx={{ color: "white", width: "150px" }}
            >
              {htmlSelection(1, person.adults).map((select) => select)}
            </NativeSelect>
          </Box>
        ) : (
          <Box pr={5}>
            <Box component="span">{modelChambre.adults}</Box>
          </Box>
        )}
      </Box>
      {person.kids !== 0 && (
        <Box display="flex" justifyContent="space-between">
          <Box component="span" className="family">
            ENFANTS
          </Box>
          {isModifiable ? (
            <Box>
              <NativeSelect
                onChange={(e) => inputChange(e.target, index)}
                value={modelChambre.kids}
                inputProps={{
                  name: "kids",
                }}
                sx={{ color: "white", width: "150px" }}
              >
                {htmlSelection(0, person.kids).map((select) => select)}
              </NativeSelect>
            </Box>
          ) : (
            <Box pr={5}>
              <Box component="span">{modelChambre.kids}</Box>
            </Box>
          )}
        </Box>
      )}
      {!props.isShowInfoClient &&
        index + 1 < props.currentStep &&
        chambre !== 0 && (
          <Box align="right">
            <Button
              onClick={() => props.onChangeStep(index + 1)}
              sx={{
                backgroundColor: "white",
                color: Couleur.bordeau,
                fontFamily: "'Glacial Indifference', serif",
                "&:hover": { backgroundColor: Couleur.jaune },
              }}
            >
              Modifier
            </Button>
          </Box>
        )}
    </Box>
  );
};

export default ChambreResume;
