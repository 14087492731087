import axios from "axios";
import { apiUrl } from "./module";


const header = ()=>{

    const user_token = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
    const config = {
        headers: {
            Authorization: `Bearer ${user_token}`,
            'Content-Type':'multipart/form-data',
            "Access-Control-Allow-Origin": "*",
        }
    }
    return config;
}

class ModeleChambreService {
    storeModeleChambre(datas){
        return axios.post(apiUrl + "/storeModeleChambre", datas, header());
    }
    updateModeleChambre(datas,id){
        return axios.post(apiUrl + "/updateModeleChambre/"+id, datas, header());
    }
    listModeleChambre(){
        return axios.get(apiUrl + "/listModeleChambre", header());
    }
    deleteModeleChambre(id){
        return axios.get(apiUrl + "/deleteModeleChambre/"+id, header());
    }
    addImage(idModeleChambre, data){
        return axios.post(apiUrl + "/addImage/"+idModeleChambre, data, header());
    }
    setPrincipalImage(idModeleChambre, idImageChambre){
        return axios.get(apiUrl + "/setPrincipalImage/"+idModeleChambre+"/"+idImageChambre, header());
    }
    deleteImage(idImageChambre){
        return axios.get(apiUrl + "/deleteImage/"+idImageChambre, header());
    }
}

export default new ModeleChambreService();