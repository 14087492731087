import axios from "axios";
import { apiUrl } from "./module";


const header = ()=>{

    const user_token = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
    const config = {
        headers: {
            Authorization: `Bearer ${user_token}`,
            'Content-Type':'multipart/form-data',
            "Access-Control-Allow-Origin": "*",
        }
    }
    return config;
}
class AccessoireService {
    storeAccessoire(accessoire_data){
        return axios.post(apiUrl + "/storeAccessoire", accessoire_data, header());
    }
    updateAccessoire(accessoire_data, id){
        return axios.post(apiUrl + "/updateAccessoire/"+id, accessoire_data, header());
    }
    
    accessoireList(){
        return axios.get(apiUrl + "/accessoireList", header());
    }

    deleteAccessoire(id){
        return axios.get(apiUrl + "/deleteAccessoire/"+id, header());
    }
}

export default new AccessoireService();