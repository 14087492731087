import React, { useEffect } from 'react'
import '../assets/scss/footer.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const [t, i18n] = useTranslation("common");

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <Box className='footer-section' data-aos="zoom-out" data-aos-duration="1500">
            <div>
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use href="#gentle-wave" x="48" y="0" fill="rgba(133,0,0,0.7)" />
                        <use href="#gentle-wave" x="48" y="3" fill="rgba(133,0,0,0.5)" />
                        <use href="#gentle-wave" x="48" y="5" fill="rgba(133,0,0,0.3)" />
                        <use href="#gentle-wave" x="48" y="7" fill="#850000" />
                    </g>
                </svg>
            </div>
            <footer className="footer family">
                <Box>
                    <Box component="p" className="titleFooter"><span className="textFooter">CONTACT</span></Box>
                    <Box component="p" pt={2}><span className="textFooter">resa@zomatel.com</span></Box>
                    <Box component="p"><span className="textFooter">BP: 1367, Place du Zoma</span></Box>
                    <Box component="p"><span className="textFooter">(+261) 20 75 507 97 / (+261) 34 07 255 27</span></Box>
                </Box>
                <Box className="secondText">
                    <Box component="p" className="titleFooter" ><span className="textFooter"> {t("footer.A PROPOS")}</span></Box>
                    <ul className="social-icon">
                        <li className="social-icon__item"><a className="social-icon__link" href="https://www.facebook.com/hotelzomatel" target="_blank">
                            <i className="fab fa-facebook text-primary"></i>
                        </a></li>
                        <li className="social-icon__item"><a className="social-icon__link" href="" target="_blank">
                            {/* <i className="fas fa-link"></i> */}
                        </a></li>
                        <li className="social-icon__item"><a className="social-icon__link" href="" target="_blank">
                            {/* <i className="fas fa-link"></i> */}
                        </a></li>
                        <li className="social-icon__item"><a className="social-icon__link" href="" target="_blank">
                            {/* <i className="fas fa-link"></i> */}
                        </a></li>
                        <li className="social-icon__item"><Link className="social-icon__link" to="/">
                            <i className="fas fa-link"></i>
                        </Link></li>
                    </ul>
                    <ul className="menu">
                        <li className="menu__item"><Link className="menu__link" to="/conditions-generales" target="_blank"><span className='textFooter'> {t("footer.Conditions générales")}</span></Link></li>
                        <li className="menu__item"><Link className="menu__link" to="/mentions-legales" target="_blank"><span className='textFooter'> {t("footer.Mentions Légales")}</span></Link></li>
                    </ul>
                    <ul className="">
                        <li className="menu__item"><span className='textFooter text-light'>&copy;2022 Zomatel | {t("footer.Tout droits Réservés")}</span></li>
                    </ul>
                </Box>
            </footer>
        </Box>
    )
}
