import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Couleur } from "../Header";

export const UnderlinedText = styled('p')(({ colors }) => ({
    color: `${colors ? colors : '#850000'}`,
    fontSize: '28px',
    cursor: 'pointer',
    lineHeight: '60px',
    transition: 'all .4s ease',
    '&:hover': {
        backgroundColor: Couleur.bordeau,
        color: 'white',
        paddingLeft: '15px',
    },
}))

export const FigureText = styled(Typography)({
    color: Couleur.bordeau,
    fontSize: '30px',
    fontStyle: 'italic',
    fontWeight: 'ligther',
    fontFamily: "'Oleo Script', 'Times New Roman', serif",
    '&:hover': { paddingLeft: '20px' },
    transition: 'all .4s'
})

export const SimpleTitle = (props)=>{
    return(
        <Typography color={Couleur.bordeau} component='p'
              sx={{
                fontSize: { xs: '60px', sm: '100px' },
                lineHeight: { xs: '55px', sm: '100px' },
                fontFamily: "'Oleo Script', 'Times New Roman', serif"
              }}
            >{props.children}</Typography>
    )
}

export const SubTitle = (props) => {
    const [colored, setColored] = useState(Couleur.bordeau)
    const [index, setIndex] = useState(0)

    const dataColor = [Couleur.jaune, Couleur.bordeau, Couleur.gris, Couleur.marron, Couleur.grisBleue, Couleur.orange]


    const changeColor = () => {
        setColored(dataColor[index])
        index < dataColor.length - 1 ? setIndex(index + 1) : setIndex(0)
    }
    return (
        <Box textAlign='center' display='flex' justifyContent='center' my={4} data-aos="fade-up" data-aos-duration="800">
            <Box position='relative' sx={{ width: '10%' }}><Box position='absolute' sx={{ border: '1px solid ' + colored, top: '50%', right: 0, width: '50%' }}></Box></Box>
            <Typography component='p' mx={2} color={colored} variant='h3' fontFamily="'Oleo Script', 'Times New Roman', serif" >
                {props.children}
            </Typography>
            <Box position='relative' sx={{ width: '10%' }}><Box position='absolute' sx={{ border: '1px solid ' + colored, top: '50%', width: '50%' }}></Box></Box>
        </Box>
    )
}