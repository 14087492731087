import { Box, Grid, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Header, Couleur } from "../Header";
import photoChambre from "../../assets/images/chambre.jpg";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";
import BedIcon from "@mui/icons-material/Bed";
import { Link, useParams } from "react-router-dom";
import AOS from "aos";
import "../../assets/scss/chambre.scss";
import { ArrowedCarousel } from "../tools/ArrowedCarousel";
import { Footer } from "../Footer";
import ModeleChambreService from "../services/ModeleChambreService";
import { FigureText, SimpleTitle } from "../tools/Texts";
import { baseUrl } from "../services/module";
import GroupIcon from "@mui/icons-material/Group";
import { useTranslation } from "react-i18next";
import { useLangue } from '../../i18n'



export const DetailChambre = () => {
  const [currentRoom, setCurrentRoom] = useState(null);
  const [dataCarousel, setDataCarousel] = useState([]);
  const [toSuggest, setToSuggest] = useState([]);
  const [firstImage, setFirstImage] = useState("");
  const isFrancais = useLangue();
  const [t, i18n] = useTranslation("common");



  const { idParam, modeleParam } = useParams();

  useEffect(() => {
    AOS.init();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    ModeleChambreService.listModeleChambre().then((res) => {
      // find the room to show detail
      const item = res.data.data.find((ls) => ls.id === Number(idParam));
      setCurrentRoom(item);
      setFirstImage(baseUrl + item.image_chambre[0].libelle);

      // load carousel image
      const imageList = [];
      item.image_chambre.forEach((img) => {
        imageList.push({ src: baseUrl + img.libelle, alt: "image" });
      });
      setDataCarousel(imageList);

      // load suggested rooms
      const items = res.data.data.filter(
        (room) =>
          room.id !== Number(idParam) &&
          room.type.toLowerCase().includes(modeleParam)
      );
      setToSuggest(items);

      // specific suggestion for suite , familliale, appartement
      if (modeleParam === "suite") {
        const specificItem = res.data.data.filter(
          (room) =>
            room.id !== Number(idParam) &&
            (room.type.toLowerCase().includes("familiale") ||
              room.type.toLowerCase().includes("appartement") ||
              room.type.toLowerCase().includes("suite"))
        );
        setToSuggest(specificItem);
      } else if (modeleParam === "familiale") {
        const specificItem = res.data.data.filter(
          (room) =>
            room.id !== Number(idParam) &&
            (room.type.toLowerCase().includes("suite") ||
              room.type.toLowerCase().includes("appartement") ||
              room.type.toLowerCase().includes("familiale"))
        );
        setToSuggest(specificItem);
      } else if (modeleParam === "appartement") {
        const specificItem = res.data.data.filter(
          (room) =>
            room.id !== Number(idParam) &&
            (room.type.toLowerCase().includes("suite") ||
              room.type.toLowerCase().includes("familiale") ||
              room.type.toLowerCase().includes("appartement"))
        );
        setToSuggest(specificItem);
      }


      // check if there is a principal image
      item.image_chambre.forEach((img) => {
        if (img.est_principale === 1) {
          setFirstImage(baseUrl + img.libelle);
        }
      });
    });
  }, [idParam]);

  const checkImage = (suggest) => {
    let found = false
    let image
    suggest.image_chambre.forEach((img) => {
      if (img.est_principale === 1) {
        found = true
        image = baseUrl + img.libelle
      }
    })

    if(found){
      return image
    }
    else{
      return baseUrl + suggest.image_chambre[0].libelle
    }
  }

  return (
    <Box>
      {currentRoom && (
        <>
          <Header headerTitle={currentRoom.type} backgUrl={photoChambre} />

          <Box
            textAlign="center"
            mt={3}
            mb={8}
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <SimpleTitle>Un cocon hors du temps</SimpleTitle>
          </Box>
          <Box data-aos="fade-up" data-aos-duration="1000">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                widht: "100%",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                },
              }}
            >
              <ListItem style={{ display: "flex", flexDirection: "column" }}>
                <ListItemIcon>
                  <GroupIcon
                    style={{ fill: "#850000", height: "80px", width: "100px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Box className="family" component="p" fontSize="25px" textAlign="center">
                    {currentRoom.max_personne} personnes
                  </Box>
                </ListItemText>
              </ListItem>
              <ListItem style={{ display: "flex", flexDirection: "column" }}>
                <ListItemIcon>
                  <SquareOutlinedIcon
                    style={{ fill: "#850000", height: "80px", width: "100px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Box className="family" component="p" fontSize="25px" textAlign="center">
                    { isFrancais? currentRoom.caracteristique
                      .split(",")
                      .map(
                        (caracteristique) => (caracteristique.includes("m2") || caracteristique.includes("m²"))
                          && caracteristique
                      ):
                      currentRoom.en_caracteristique
                      .split(",")
                      .map(
                        (caracteristique) => (caracteristique.includes("m2") || caracteristique.includes("m²"))
                          && caracteristique
                      )
                      }
                  </Box>
                </ListItemText>
              </ListItem>
              <ListItem style={{ display: "flex", flexDirection: "column" }}>
                <ListItemIcon>
                  <BedIcon
                    style={{ fill: "#850000", height: "80px", width: "100px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Box className="family" component="p" fontSize="25px" textAlign="center">
                    {isFrancais? currentRoom.caracteristique
                      .split(",")
                      .map(
                        (caracteristique) =>
                          caracteristique.includes("lit") && caracteristique
                      ) : 
                      currentRoom.en_caracteristique
                      .split(",")
                      .map(
                        (caracteristique) =>
                          caracteristique.includes("bed") && caracteristique
                      )
                      }
                  </Box>
                </ListItemText>
              </ListItem>
            </Box>
          </Box>
          <Box
            className="family"
            fontSize="25px"
            component="p"
            sx={{
              textAlign: "center",
              margin: "0 10% 60px 10%",
              lineHeight: "50px",
            }}
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            {isFrancais? currentRoom.description : currentRoom.en_description?? currentRoom.description}
          </Box>
          <Box px={9}>
            <Grid container>
              <Grid
                item
                sm={12}
                md={7}
                lg={7}
                sx={{ position: "relative", height: "auto" }}
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <Box
                  component="img"
                  sx={{ width: "100%", height: "100%" }}
                  alt="chambre image"
                  src={firstImage}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={5}
                lg={5}
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <Box
                  component="p"
                  textAlign="center"
                  sx={{
                    fontWeight: 900,
                    fontSize: 40,
                    fontFamily: "Satisfy, serif, arial",
                    color: Couleur.gris,
                  }}
                >
                  {t("btn.carcteristique")} {isFrancais? currentRoom.type : currentRoom.en_type?? currentRoom.type}
                </Box>
                <Box>
                  {currentRoom.accessoire.map((access) => (
                    <ListItem key={access.id}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          mt={1}
                          src={baseUrl + access.icon}
                          alt={access.libelle}
                          sx={{ width: "35px", height: "35px" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Box
                          component="span"
                          className="family"
                          sx={{ fontSize: "22px" }}
                        >
                          {isFrancais? access.libelle : access.en_libelle?? access.libelle}
                        </Box>
                      </ListItemText>
                    </ListItem>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={12} data-aos="fade-up" data-aos-duration="1500">
            <Box>
              <Box
                component="p"
                textAlign="center"
                sx={{ fontWeight: 900, fontSize: 35, color: Couleur.gris, fontFamily: "Satisfy, serif, arial", }}
              >
                Photos {currentRoom.type}
              </Box>
            </Box>
            <Box display="flex" mt={1} justifyContent="center">
              <ArrowedCarousel images={dataCarousel} />
            </Box>
          </Box>

          {toSuggest.length > 0 && (
            <Box mb={10} px={{ sm: 5, md: 20 }}>
              <Box
                my={3}
                component="p"
                textAlign="center"
                data-aos="fade-up"
                data-aos-duration="800"
                sx={{ fontWeight: 900, fontSize: 50, color: Couleur.gris, fontFamily: "Satisfy, serif, arial", }}
              >
                {t("btn.decouvrerAussi")}
              </Box>
              <Box>
                <Grid container spacing={10} justifyContent="center">
                  {toSuggest.map((suggest, index) => (
                    <Grid
                      className="roomImage"
                      key={index}
                      item
                      sm={12}
                      md={6}
                      position="relative"
                      display="flex"
                      justifyContent={"center"}
                      data-aos={`${index % 2 === 0 ? "fade-right" : "fade-left"
                        }`}
                      data-aos-duration="1500"
                    >
                      <Box
                        className="overlap-img"
                        component="figure"
                        sx={{ height: "50vh" }}
                      >
                        <Box
                          component="img"
                          src={checkImage(suggest)}
                          sx={{ width: "100%", height: "90%" }}
                          alt={suggest.type}
                        />
                        <Link
                          to={
                            "/detailChambre/" + suggest.id + "/" + modeleParam
                          }
                        >
                          <figcaption>
                            <FigureText component="p">
                              {suggest.type}
                            </FigureText>
                          </figcaption>
                        </Link>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}

          <Footer />
        </>
      )}
    </Box>
  );
};
