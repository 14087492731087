import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./components/accueil/App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Chambre } from "./components/chambre/Chambre";
import BienEtre from "./components/activites/Bien-etre";
import Evenement from "./components/activites/Evenement";
import Gastronomie from "./components/gastronomie/Gastronomie";
import { Galerie } from "./components/galerie/Galerie";
import { Art } from "./components/art/Art";
import { NosServices } from "./components/nosServices/nosServices";
import { Information } from "./components/information/Information";
import { Reserver } from "./components/reserver/Reserver";
import { Login } from "./components/admin/Login";
import { Dashboard } from "./components/admin/Dashboard";
import { ModeleChambre } from "./components/admin/pages/ModeleChambre";
import { Accessoire } from "./components/admin/pages/Accessoire";
import { ChambreDashboard } from "./components/admin/pages/ChambreDashboard";
import { Devise } from "./components/admin/pages/Devise";
import EntrecodeDuZoma from "./components/gastronomie/EntrecoteDuZoma";
import { DetailChambre } from "./components/chambre/DetailChambre";
import { ConditionsGenerales } from "./components/footerText/ConditionsGenerales";
import { MentionsLegales } from "./components/footerText/MentionsLegales";
import { GalerieZomatel } from "./components/admin/pages/GalerieZomatel";
import { Compte } from "./components/admin/pages/Compte";
import ArtsProduitMalagasy from "./components/admin/pages/ArtsProduitMalagasy";
import { SelectImage } from "./components/admin/pages/SelectImages";
import Service from "./components/admin/pages/Service";
import ServiceList from "./components/admin/pages/ServiceList";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Suspense fallback="loading">
      <Routes>
        <Route path="/" element={<App />} />
        <Route
          path="/chambre"
          element={
            <React.StrictMode>
              {" "}
              <Chambre />
            </React.StrictMode>
          }
        />
        <Route path="/chambre/:modeleParam" element={<Chambre />} />
        <Route
          path="/detailChambre/:idParam/:modeleParam"
          element={<DetailChambre />}
        />
        <Route path="/entrecote" element={<EntrecodeDuZoma />} />
        <Route path="/gastronomie" element={<Gastronomie />} />
        <Route path="/service" element={<NosServices />} />
        <Route path="/information" element={<Information />} />
        <Route path="/art" element={<Art />} />
        <Route path="/art/:idParam" element={<Art />} />
        <Route path="/galerie" element={<Galerie />} />
        <Route path="/bien-etre" element={<BienEtre />} />
        <Route path="/bien-etre/:modeleParam" element={<BienEtre />} />
        <Route path="/evenement-seminaire" element={<Evenement />} />
        <Route path="/evenement-seminaire/:idParam" element={<Evenement />} />
        <Route path="/conditions-generales" element={<ConditionsGenerales />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/reserver" element={<Reserver />} />
        <Route path="/reserver/:transToken" element={<Reserver />} />

        {/* new Url */}
        <Route path="/gastronomie/:idMenu" element={<EntrecodeDuZoma />} />

        <Route path="/entrecote" element={<EntrecodeDuZoma />} />
        {/* <Route path="/zomagril" element={<Zomagril />} />
      <Route path="/obistro" element={<Obistro />} /> */}
        <Route path="/gastronomie" element={<Gastronomie />} />
        <Route path="/service" element={<NosServices />} />
        <Route path="/information" element={<Information />} />
        <Route path="/art" element={<Art />} />
        <Route path="/art/:idParam" element={<Art />} />
        <Route path="/galerie" element={<Galerie />} />
        <Route path="/bien-etre" element={<BienEtre />} />
        <Route path="/bien-etre/:modeleParam" element={<BienEtre />} />
        <Route path="/evenement-seminaire" element={<Evenement />} />
        <Route path="/evenement-seminaire/:idParam" element={<Evenement />} />
        <Route path="/conditions-generales" element={<ConditionsGenerales />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/reserver" element={<Reserver />} />
        <Route path="/reserver/:transToken" element={<Reserver />} />

        {/* PARTIES ADMIN */}
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/dashboard/" element={<Dashboard />}>
          <Route
            path="/admin/dashboard/modele-chambre"
            element={<ModeleChambre />}
          />
          <Route path="/admin/dashboard/accessoire" element={<Accessoire />} />
          <Route
            path="/admin/dashboard/chambre"
            element={<ChambreDashboard />}
          />
          <Route path="/admin/dashboard/devise" element={<Devise />} />
          <Route path="/admin/dashboard/service" element={<Service id={5} />} />
          <Route
            path="/admin/dashboard/services/:idService/serviceList"
            element={<ServiceList />}
          />
          <Route path="/admin/dashboard/compte" element={<Compte />} />
          <Route path="/admin/dashboard/galerie" element={<GalerieZomatel />} />
          <Route
            path="/admin/dashboard/sous_menu/:idMenu/images"
            element={<SelectImage />}
          />
          <Route
            path="/admin/dashboard/menu/:idMenu/images"
            element={<SelectImage />}
          />
          <Route
            path="/admin/dashboard/services/:idMenu/images"
            element={<SelectImage />}
          />
          <Route
            path="/admin/dashboard/art_produit_malagasy"
            element={<ArtsProduitMalagasy id={1} />}
          />
          <Route
            path="/admin/dashboard/evenement_seminaire"
            element={<ArtsProduitMalagasy id={2} />}
          />
          <Route
            path="/admin/dashboard/bien_etre_loisirs"
            element={<ArtsProduitMalagasy id={3} />}
          />
          <Route
            path="/admin/dashboard/gastronomie"
            element={<ArtsProduitMalagasy id={4} />}
          />
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
);
