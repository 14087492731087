import axios from "axios";
import { apiUrl } from "./module";

const header = ()=>{

    const user_token = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
    const config = {
        headers: {
            Authorization: `Bearer ${user_token}`,
            "Access-Control-Allow-Origin": "*",
        }
    }
    return config;
}

class ContactService {
    contactingZomatel(datas){
        return axios.post(apiUrl + "/contactingZomatel", datas, header());
    }
}

export default new ContactService();