import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Couleur } from "../../Header";
import AccessoireService from "../../services/AccessoireService";
import ModeleChambreService from "../../services/ModeleChambreService";
import Swal from "sweetalert2";
import { TableModeleChambre } from "./extends/TableModeleChambre";

export const SuccessNotification = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const ModeleChambre = () => {
  const [listAccessoire, setListAccessoire] = useState([]);
  const [listModeleChambre, setListModeleChambre] = useState([]);
  const [idToUpdate, setIdToUpdate] = useState(null);
  const [titleOperation, setTitleOperation] = useState("NOUVEAU");
  const [state, setState] = useState(true);

  const [typeChambre, setTypeChambre] = useState("");
  const [enTypeChambre, setEnTypeChambre] = useState("");
  const [description, setDescription] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [tarif, setTarif] = useState(0);
  const [maxPersonne, setMaxPersonne] = useState(1);
  const [caracteristique, setCaracteristique] = useState("");
  const [enCaracteristique, setEnCaracteristique] = useState("");
  const [nombre, setNombre] = useState(1);
  const [imageChambre, setImageChambre] = useState(null);
  const [idListAccessoire, setIdListAccessoire] = useState([]);
  const [resetData, setResetData] = useState(true);

  const imageRef = useRef();
  const loadRef = useRef();
  const loadAccessoireRef = useRef();
  const listAccessoireRef = useRef();
  const listModelRef = useRef();
  const modifierAction = useRef();
  const nouveauAction = useRef();

  useEffect(() => {
    AccessoireService.accessoireList().then((res) => {
      setListAccessoire(res.data.data);
      loadAccessoireRef.current.hidden = true;
      listAccessoireRef.current.hidden = false;
    });
    ModeleChambreService.listModeleChambre().then((res) => {
      setListModeleChambre(res.data.data);
      loadRef.current.hidden = true;
      listModelRef.current.hidden = false;
    });
  }, [resetData]);

  const clearValue = () => {
    setState(true);
    setTypeChambre("");
    setEnTypeChambre("");
    setDescription("");
    setEnDescription("");
    setTarif(0);
    setMaxPersonne(1);
    setCaracteristique("");
    setEnCaracteristique("");
    setNombre(1);
    setIdListAccessoire([]);
    setTitleOperation("NOUVEAU");
    setIdToUpdate(null);
    !idToUpdate && (imageRef.current.value = null);
    setImageChambre(null);
    for (
      let i = 0;
      i < document.getElementsByName("checkAccessoire").length;
      i++
    ) {
      document.getElementsByName("checkAccessoire")[i].checked = false;
    }
  };

  const onChangePhoto = (e) => {
    const fileList = [];
    for (let i = 0; i < e.target.files.length; i++) {
      fileList.push(e.target.files[i]);
    }
    setImageChambre(fileList);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const id_accessoires = [];

    for (
      let i = 0;
      i < document.getElementsByName("checkAccessoire").length;
      i++
    ) {
      if (document.getElementsByName("checkAccessoire")[i].checked) {
        id_accessoires.push(listAccessoire[i].id);
      }
    }
    setIdListAccessoire(id_accessoires);

    let datas = {
      description: description,
      en_description: enDescription,
      type: typeChambre,
      en_type: enTypeChambre,
      tarif: tarif,
      max_personne: maxPersonne,
      caracteristique: caracteristique,
      en_caracteristique: enCaracteristique,
      nombre: nombre,
      id_accessoires,
    };
    if (imageChambre) {
      datas["imageChambre"] = imageChambre;
    }

    if (
      typeChambre &&
      enTypeChambre &&
      description &&
      enDescription &&
      caracteristique &&
      enCaracteristique &&
      id_accessoires.length > 0
    ) {
      if (idToUpdate) {
        // modifier
        ModeleChambreService.updateModeleChambre(datas, idToUpdate)
          .then((res) => {
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
            ModeleChambreService.listModeleChambre().then((res) => {
              setListModeleChambre(res.data.data);
            });
            clearValue();
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: err,
            });
          });
      } else {
        // ajouter
        ModeleChambreService.storeModeleChambre(datas)
          .then((res) => {
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
            ModeleChambreService.listModeleChambre().then((res) => {
              setListModeleChambre(res.data.data);
            });
            clearValue();
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: err,
            });
          });
      }
    }
  };

  const onEdit = (id) => {
    const item = listModeleChambre.find((modeleC) => modeleC.id === id);
    // modifierAction.current.hidden = false;
    // nouveauAction.current.hidden = true;
    if (item) {
      setTitleOperation("MODIFIER");
      setIdToUpdate(id);
      setTypeChambre(item.type);
      setEnTypeChambre(item.en_type);
      setDescription(item.description);
      setEnDescription(item.en_description);
      setTarif(item.tarif);
      setMaxPersonne(item.max_personne);
      setCaracteristique(item.caracteristique);
      setEnCaracteristique(item.en_caracteristique);
      setNombre(item.nombre);

      const id_accessoires = [];
      item.accessoire.forEach((element) => {
        for (
          let i = 0;
          i < document.getElementsByName("checkAccessoire").length;
          i++
        ) {
          if (element.id === listAccessoire[i].id) {
            document.getElementsByName("checkAccessoire")[i].checked = true;
            id_accessoires.push(listAccessoire[i].id);
          }
        }
      });

      setIdListAccessoire(id_accessoires);
      setState(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };
  const onCancel = () => {
    clearValue();
  };

  return (
    <Box>
      <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
        <Box
          component="ol"
          className="breadcrumb mb-4"
          sx={{
            backgroundColor: Couleur.jaune,
            p: 2,
            width: "100%",
            borderRadius: "4px",
          }}
        >
          <li className="breadcrumb-item">
            <a href="#!">
              <label>Page actuelle :</label>
            </a>
          </li>
          <li className="breadcrumb-item">
            <a href="#!">Dashboard</a>
          </li>
          <li className="breadcrumb-item active">Modèle Chambre</li>
        </Box>
      </Box>
      <Box component="form" onSubmit={(e) => onSubmit(e)} px={8}>
        <Card>
          <CardActions>{titleOperation}</CardActions>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={5}>
                <Stack direction="row" spacing={3}>
                  <TextField
                    label="Type"
                    sx={{ width: "100%" }}
                    onChange={(e) => setTypeChambre(e.target.value)}
                    value={typeChambre}
                    variant="standard"
                  />
                </Stack>
                <Stack  mt={3}>
                  <TextField
                    label="Type en anglais"
                    sx={{ width: "100%" }}
                    onChange={(e) => setEnTypeChambre(e.target.value)}
                    value={enTypeChambre}
                    variant="standard"
                  />
                </Stack>
                <Stack mt={3}>
                  <TextField
                      label="Tarif (Ar)"
                      sx={{ width: "100%" }}
                      min={0}
                      onChange={(e) => setTarif(e.target.value)}
                      value={tarif}
                      type="number"
                      variant="standard"
                    />
                  </Stack>
                  <Stack mt={3} direction="row" spacing={3}>
                  <TextField
                    label="Max personne"
                    sx={{ width: "100%" }}
                    min={1}
                    onChange={(e) => setMaxPersonne(e.target.value)}
                    value={maxPersonne}
                    type="number"
                    variant="standard"
                  />
                  <TextField
                    label="Nombre"
                    sx={{ width: "100%" }}
                    min={1}
                    onChange={(e) => setNombre(e.target.value)}
                    value={nombre}
                    type="number"
                    variant="standard"
                  />
                </Stack>
              </Grid>
              <Stack mt={3} ml={3} >
                <Stack direction="row" spacing={3}>
                  <Grid item>
                    <TextField
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      label="Description"
                      rows={4}
                      multiline
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item >
                    <TextField
                      onChange={(e) => setEnDescription(e.target.value)}
                      value={enDescription}
                      label="Description en anglais"
                      rows={4}
                      multiline
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Stack>
                <Stack mt={3} direction="row" spacing={3}>
                  <Grid item>
                    <TextField
                      onChange={(e) => setCaracteristique(e.target.value)}
                      value={caracteristique}
                      label="Caractéristiques"
                      rows={4}
                      multiline
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      onChange={(e) => setEnCaracteristique(e.target.value)}
                      value={enCaracteristique}
                      label="Caractéristiques en anglais"
                      rows={4}
                      multiline
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Stack>
              </Stack>
              <Grid item md={12} px={3} pt={2}>
                <Box ref={loadAccessoireRef} sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
                <Stack ref={listAccessoireRef} direction="row" spacing={3}>
                  {listAccessoire.length > 0 &&
                    listAccessoire.map((access, index) => (
                      <div className="form-check" key={access.id}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkAccessoire"
                          id={"flexCheckDefault" + index}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"flexCheckDefault" + index}
                        >
                          {access.libelle}
                        </label>
                      </div>
                    ))}
                </Stack>
              </Grid>
              {!idToUpdate && (
                <Grid item md={12} px={3} py={2}>
                  <Box
                    component="input"
                    id="photo"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => onChangePhoto(e)}
                    ref={imageRef}
                    sx={{
                      fontFamily: "serif, arial",
                      fontSize: "18px",
                      color: Couleur.bordeau,
                      cursor: "pointer",
                    }}
                  ></Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          {state ? (
            <CardActions>
              <Button variant="contained" type="submit">
                ajouter
              </Button>
            </CardActions>
          ) : (
            <CardActions>
              <Button variant="contained" color="warning" type="submit">
                modifier
              </Button>
              <Button
                variant="contained"
                onClick={onCancel}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  "&:hover": { backgroundColor: "#eeefff" },
                }}
              >
                annuler
              </Button>
            </CardActions>
          )}
        </Card>
      </Box>
      <Box mt={8} px={8}>
        <Box ref={loadRef} sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
        <TableModeleChambre
          ref={listModelRef}
          hidden
          listModeleChambre={listModeleChambre}
          statusData={resetData}
          resetData={setResetData}
          onEdit={onEdit}
          clearValue={clearValue}
        />
      </Box>
    </Box>
  );
};
