import axios from "axios";
import { apiUrl } from "./module";


const header = ()=>{

    const user_token = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
    const config = {
        headers: {
            Authorization: `Bearer ${user_token}`,
            "Access-Control-Allow-Origin": "*",
        }
    }
    return config;
}
class Auth {
    newLogin(data){
        return axios.post(apiUrl + "/newLogin", data);
    }
    login(user_data){
        return axios.post(apiUrl + "/login", user_data);
    }
    
    logout(){
        return axios.get(apiUrl + "/logout", header());
    }

    listUser(){
        return axios.get(apiUrl + "/user", header());
    }
    storeUser(data){
        return axios.post(apiUrl + "/user", data, header());
    }
    updateUser(id, data){
        return axios.put(apiUrl + "/user/"+id, data, header());
    }
    deleteUser(id){
        return axios.delete(apiUrl + "/user/"+id, header());
    }
}

export default new Auth();