import {
  Box,
  Button,
  Card,
  CardActions,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";


import { Couleur } from "../../Header";
import { SuccessNotification } from "./ModeleChambre";
import { useNavigate, useParams } from "react-router-dom";
import ServiceService from "../../services/ServiceService";

const TableServiceList = (props) => {
  const [services, setServices] = useState([]);

  const loadRef = useRef();
  const listRef = useRef();

  useEffect(() => {
    if (props.services) {
      setServices(props.services);
      loadRef.current.hidden = true;
    }
  }, [props.services]);
  const row = (data) => {
    return (
      <TableRow
        key={data.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="right">{data?.en_libelle}</TableCell>
        <TableCell align="right">{data?.libelle}</TableCell>

        <TableCell align="right">
          <IconButton
            aria-label="delete"
            onClick={() => props.onEdit(data)}
            size="large"
          >
            <EditIcon color="warning" />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => props.onDelete(data)}
            size="large"
          >
            <DeleteIcon color="warning" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Box ref={loadRef} sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
      <TableContainer ref={listRef} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Item service en engalais</TableCell>
              <TableCell align="right">Item service</TableCell>
              <TableCell align="right">action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.length > 0 && services.map((d) => row(d))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const ServiceList = (props) => {
  const [ID_MENU, setID_MENU] = useState();

  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const { idService } = useParams();
  const [service, setService] = useState(null);
  const [idItem, setIdItem] = useState(null);
  const [itemServices, setItemServices] = useState([]);
  const [libelle, setLibelle] = useState("");
  const [enLibelle, setEnLibelle] = useState("");

  const onCancel = () => {
    setState(false);
    setIdItem(null);
    clearValue();
  };

  useEffect(() => {
    setID_MENU(idService);
    getData(idService);
  }, [idService]);

  const getData = (id) => {
    ServiceService.getService(id ?? ID_MENU).then((res) => {
      setService(res.data.data);
    });
    ServiceService.listItemService(id ?? ID_MENU).then((res) => {
      setItemServices([...res.data.data]);
    });
  };
  const clearValue = () => {
    setLibelle("");
    setEnLibelle("")
    setState(false);
  };
  const onEdit = (data) => {
    setState(true);
    setIdItem(data.id);
    setLibelle(data.libelle);
    setEnLibelle(data.en_libelle);
  };
  const onDelete = (data) => {
    Swal.fire({
      title: "Suppression",
      text: "Cette action est irreversible, continuer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#37474F",
      confirmButtonText: "oui, continuer",
      cancelButtonText: "annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        ServiceService.deleteItemService(data.id)
          .then((res) => {
            getData(ID_MENU);
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: "Opération non effectuée",
            });
          });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let datas = { libelle, en_libelle: enLibelle };

    if (!idItem) {
      ServiceService.storeItemService({ ...datas, service_id: service.id })
        .then((res) => {
          SuccessNotification.fire({
            icon: "success",
            title: res.data.message,
          });
          getData();
          clearValue();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oups...",
            text: err,
          });
        });
    } else {
      ServiceService.updateItemService(datas, idItem)
        .then((res) => {
          SuccessNotification.fire({
            icon: "success",
            title: res.data.message,
          });
          getData();
          clearValue();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oups...",
            text: err,
          });
        });
    }
  };
  return (
    <div style={{ padding: "0 1rem" }}>
      <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
        <Box
          component="ol"
          className="breadcrumb mb-4"
          sx={{
            backgroundColor: Couleur.jaune,
            p: 2,
            width: "100%",
            borderRadius: "4px",
          }}
        >
          <li className="breadcrumb-item">
            <a href="#!">
              <label>Page actuelle :</label>
            </a>
          </li>
          <li className="breadcrumb-item">
            <a href="#!">Dashboard</a>
          </li>
          <li className="breadcrumb-item active">{service?.titre}</li>
          <li className="breadcrumb-item active">list Service</li>
        </Box>
      </Box>
      <div style={{ padding: "1rem" }}>
        <Card
          component="form"
          onSubmit={(e) => onSubmit(e)}
          sx={{ width: "800px", padding: "1rem" }}
        >
        <Stack direction="row" spacing={3}>
          <Box>
            <CardActions>Nouveau Sous Titre</CardActions>
            <TextField
              label="libelle"
              value={libelle}
              onChange={(e) => setLibelle(e.target.value)}
              variant="standard"
              sx={{
                fontFamily: "serif, arial",
                fontSize: "18px",
                width: "100%",
                color: Couleur.bordeau,
                cursor: "pointer",
              }}
            />
          </Box>
          <Box>
            <CardActions>Nouveau Sous Titre en englais</CardActions>
            <TextField
              label="libelle"
              value={enLibelle}
              onChange={(e) => setEnLibelle(e.target.value)}
              variant="standard"
              sx={{
                fontFamily: "serif, arial",
                fontSize: "18px",
                width: "100%",
                color: Couleur.bordeau,
                cursor: "pointer",
              }}
            />
          </Box>
        </Stack>
          

          {!state ? (
            <CardActions>
              <Button variant="contained" type="submit">
                ajouter
              </Button>
            </CardActions>
          ) : (
            <CardActions>
              <Button variant="contained" color="warning" type="submit">
                modifier
              </Button>
              <Button
                variant="contained"
                onClick={onCancel}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  "&:hover": { backgroundColor: "#eeefff" },
                }}
              >
                annuler
              </Button>
            </CardActions>
          )}
        </Card>
      </div>

      <Box>
        <TableServiceList
          onDelete={onDelete}
          onEdit={onEdit}
          selectItemService={(id) => {
            navigate(`/admin/dashboard/service/${id}/item_menu`);
          }}
          services={itemServices}
          titre={true}
        />
      </Box>
    </div>
  );
};

export default ServiceList;
