import { Box, Typography, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Header, Couleur } from "../Header";
import menuHeader from "../../assets/images/menuHeader.JPG";
import { ArrowedCarousel } from "../tools/ArrowedCarousel";
import "../../assets/scss/chambre.scss";
import { FigureText } from "../tools/Texts";
import { YellowButton } from "../tools/Buttons";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import AOS from "aos";
import "aos/dist/aos.css";
import { Footer } from "../Footer";
import MenuCarte from "../../assets/pdf/CARTE-MENU-FRANCAIS-1.pdf";
import SnackCarte from "../../assets/pdf/CARTE SNACK (42 × 29.7 cm).pdf";
import MenuService, { getImg } from "../services/MenuService";
import { baseUrl } from "../services/module";
import { MENU_ID_SITE } from "../services/menuID";
import { useLangue } from "../../i18n";
import { useTranslation } from "react-i18next";



export default function EntrecodeDuZoma() {
  const [menu, setMenu] = useState(null);
  const [sousMenu, setSousMenu] = useState([]);
  const { idMenu } = useParams();
  const isFrancais = useLangue();
  const [t, i18n] = useTranslation("common");


  useEffect(() => {
    AOS.init();
    MenuService.getSousMenu(idMenu).then((res) => {
      setMenu(res.data.data);
    });
    MenuService.getAllSousMenu(MENU_ID_SITE.GASTRONOMIE).then((res) => {
      setSousMenu(res.data.data.filter((d) => d.id !== idMenu));
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [idMenu]);

  const openCarte = (linkTo) => {
    if (linkTo.toLowerCase().includes("grill")) {
      window.open(SnackCarte, "_blank", "noopener,noreferrer");
    } else {
      window.open(MenuCarte, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Box>
      <Header
        backgUrl={menu?.images.length === 0 ? menuHeader : getImg(menu?.images)}
        headerTitle={isFrancais? menu?.titre : menu?.en_titre ?? menu?.titre }
      />
      <Box marginTop="100px" px={9} mb={10}>
        <Box
          textAlign="center"
          py={3}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Typography
            color={Couleur.bordeau}
            component="p"
            sx={{
              fontSize: { xs: "60px", sm: "100px" },
              lineHeight: { xs: "55px", sm: "100px" },
              fontFamily: "'Oleo Script', 'Times New Roman', serif",
            }}
          >
            {isFrancais? menu?.sousTitre ?? menu?.titre : menu?.en_sousTitre ?? menu?.en_titre ?? menu?.sousTitre ?? menu?.titre}
          </Typography>
        </Box>
        <Box
          component="p"
          className="text-adjust"
          px="10%"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          {isFrancais? menu?.description : menu?.en_description ?? menu?.description } <br />
        </Box>
        <Box
          width="100%"
          textAlign="center"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <YellowButton
            startIcon={<ReceiptLongIcon />}
            onClick={() => openCarte(menu.titre)}
          >
            Carte
          </YellowButton>
        </Box>
      </Box>

      <Box px={9} h="77vh" data-aos="fade-up" data-aos-duration="1000">
        <Box display="flex" mt={1} justifyContent="center">
          <ArrowedCarousel
            images={menu?.images?.map((i) => {
              return {
                src: baseUrl + i.libelle,
                alt: "images",
              };
            })}
          />
        </Box>
      </Box>

      <Box px={9} mb={8} mt={20}>
        <Box
          textAlign="center"
          my={5}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Typography
            component="p"
            mx={2}
            color={Couleur.bordeau}
            variant="h3"
            fontFamily="'Oleo Script', 'Times New Roman', serif"
          >
            {t("btn.decouvrerAussi")}
          </Typography>
        </Box>
        <Box>
          <Stack
            data-aos="fade-up"
            data-aos-duration="1500"
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            rowGap={3}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ sm: 0, md: 4, lg: 10 }}
          >
            {sousMenu.map((s) => (
              <Box key={s.id} className="overlap-img" component="figure">
                <img width={700} src={getImg(s.images)} alt={isFrancais? s.titre : s.en_titre ?? s.en_sousTitre ?? s.titre} />
                <Link to={`/gastronomie/${s.id}`}>
                  <figcaption>
                    <FigureText component="p">{isFrancais? s.titre : s.en_titre ?? s.en_sousTitre ?? s.titre }</FigureText>
                  </figcaption>
                </Link>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
