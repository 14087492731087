import axios from "axios";
import { apiUrl } from "./module";


const header = ()=>{

    const user_token = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
    const config = {
        headers: {
            Authorization: `Bearer ${user_token}`,
            "Access-Control-Allow-Origin": "*",
        }
    }
    return config;
}

class DeviseService {
    storeDevise(datas){
        return axios.post(apiUrl + "/storeDevise", datas, header());
    }
    updateDevise(datas,id){
        return axios.put(apiUrl + "/updateDevise/"+id, datas, header());
    }
    listDevise(){
        return axios.get(apiUrl + "/listDevise", header());
    }
    deleteDevise(id){
        return axios.get(apiUrl + "/deleteDevise/"+id, header());
    }
}

export default new DeviseService();