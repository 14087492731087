import { Box, Button } from '@mui/material'
import React from 'react'
import { PropTypes } from 'prop-types'
import styled from '@emotion/styled'
import '../../assets/scss/reserve.scss'
import { Link } from 'react-router-dom'

const ArrowMenu = styled(Button)({
  background: "#850000",
  color: 'white',
  textTransform: 'capitalize',
  fontSize: '18px',
  fontFamily: "'Glacial Indifference', serif",
  borderRadius: '0px',
  '&:hover': {
    backgroundColor: "#850000",
    // width: "200px",
  }
})


export default function Arrow(props) {
  return (

    <Box className='wrap-arrow' pl={6} my={1}>
      <Box className='arrow-box' sx={{
        '&::before': {
          opacity: props.active
        },
        '&::after': {
          opacity: props.active
        }
      }}>
        <ArrowMenu sx={{ opacity: props.active }} onClick={props.onClick}>
          {props.arrowContent}</ArrowMenu>
      </Box>
    </Box>

  )
}

Arrow.defaultProps = {
  active: "0.5"
}
Arrow.propTypes = {
  arrowContent: PropTypes.string
}