import { Box, Button, Card, CardActions, CardContent, Divider, Grid, IconButton, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { Couleur } from '../../Header'
import Swal from "sweetalert2";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Auth from '../../services/Auth';
import { useNavigate } from 'react-router-dom';

const SuccessNotification = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const Compte = () => {
    const [listUser, setListUser] = useState([])
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [idToUpdate, setIdToUpdate] = useState(null)
    const [title, setTitle] = useState('NOUVEAU')
    
    let navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("user_email") !== "josuetouv@gmail.com"){
            navigate("/admin/dashboard");
        }
        Auth.listUser()
            .then(res => {
                setListUser(res.data.data)
            })
    }, [])

    const clearValue = () => {
        setName('')
        setEmail('')
        setTitle('NOUVEAU')
        setIdToUpdate(null)
    }

    const onSubmit = e => {
        e.preventDefault()

        if (name && email) {
            if (!idToUpdate) { // ajouter
                Auth.storeUser({ 'name': name, 'email': email })
                    .then(res => {
                        SuccessNotification.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                        setListUser(res.data.data)
                        clearValue()
                    })
                    .catch(err => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oups...',
                            text: err.response.data.message,
                        });
                    });
            }
            else { // modifier
                Auth.updateUser(idToUpdate, { 'name': name, 'email': email })
                    .then(res => {
                        SuccessNotification.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                        setListUser(res.data.data)
                        clearValue()
                    })
                    .catch(err => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oups...',
                            text: err.response.data.message,
                        });
                    });
            }
        }
    }

    const onEdit = (id) => {
        const item = listUser.find(ls => ls.id === id)
        if (item) {
            setName(item.name)
            setEmail(item.email)
            setTitle('MODIFIER')
            setIdToUpdate(id)
        }
    }

    const onCancel = () => {
        clearValue()
    }

    const onDelete = (id) => {
        Swal.fire({
            title: 'Suppression',
            text: "Cette action est irreversible, continuer?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#37474F',
            confirmButtonText: 'oui, continuer',
            cancelButtonText: 'annuler',
        }).then((result) => {
            if (result.isConfirmed) {
                Auth.deleteUser(id)
                    .then(res => {
                        setListUser(res.data.data)
                        clearValue()
                        SuccessNotification.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                    })
                    .catch(err => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oups...',
                            text: 'Opération non effectuée',
                        });
                    })
            }
        })
    }

    return (
        <Box>
            <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
                <Box component='ol' className="breadcrumb mb-4" sx={{ backgroundColor: Couleur.jaune, p: 2, width: '100%', borderRadius: '4px' }}>
                    <li className="breadcrumb-item"><a href="#!"><label>Page actuelle :</label></a></li>
                    <li className="breadcrumb-item"><a href="#!">Dashboard</a></li>
                    <li className="breadcrumb-item active">Compte</li>
                </Box>
            </Box>
            <Grid container spacing={4}>
                <Grid item md={3}>
                    <Card sx={{ maxWidth: 350, ml: 5 }}>
                        <Box component='form' onSubmit={(e) => onSubmit(e)}>
                            <CardActions>{title}</CardActions>
                            <CardContent>
                                <Stack spacing={2.5}>
                                    <TextField label="Nom" value={name} onChange={e => setName(e.target.value)} variant="standard" />
                                    <TextField label="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} variant="standard" />
                                </Stack>
                            </CardContent>
                            {
                                !idToUpdate ?
                                    <CardActions>
                                        <Button variant='contained' type='submit'>ajouter</Button>
                                    </CardActions>
                                    :
                                    <CardActions>
                                        <Button variant='contained' color='warning' type='submit'>modifier</Button>
                                        <Button variant='contained' onClick={onCancel} sx={{ backgroundColor: '#eeeeee', color: 'black', '&:hover': { backgroundColor: '#eeefff' } }}>annuler</Button>
                                    </CardActions>
                            }
                        </Box>
                    </Card>
                </Grid>
                <Grid item>
                    {
                        listUser.length > 0 &&
                        <Card sx={{ minWidth: 350 }}>
                            <CardActions sx={{ backgroundColor: 'black', color: 'white' }}>Liste de comptes</CardActions>
                            <CardContent>
                                <Stack>
                                    {
                                        listUser.map((user, index) => (
                                            <>
                                                {index !== 0 && <Divider />}
                                                <Box key={user.id} display='flex' justifyContent='space-between'>
                                                    <Box pt={1}>
                                                        {user.name} | {user.email}
                                                    </Box>
                                                    <Box>
                                                        <IconButton aria-label="delete" size="sm" onClick={() => onEdit(user.id)}>
                                                            <EditIcon color='warning' />
                                                        </IconButton>
                                                        {user.email !== "josuetouv@gmail.com" &&
                                                            <IconButton aria-label="delete" size="sm" onClick={() => onDelete(user.id)}>
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        }
                                                    </Box>
                                                </Box>
                                            </>
                                        ))
                                    }
                                </Stack>
                            </CardContent>
                        </Card>
                    }

                </Grid>
            </Grid>
        </Box>
    )
}
