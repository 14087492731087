import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { baseUrl } from '../../../services/module';
import { Button, Checkbox, Grid, IconButton, LinearProgress, Modal } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PhotoIcon from '@mui/icons-material/Photo';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Swal from "sweetalert2";
import ModeleChambreService from '../../../services/ModeleChambreService';
import { Couleur } from '../../../Header';

const SuccessNotification = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    height: "100%",
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "auto"
};


export const TableModeleChambre = (props) => {
    const [listModeleChambre, setListModeleChambre] = useState(null)
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState(null)
    const [item, setItem] = useState(null)
    const [imageChambre, setImageChambre] = useState(null);
    const [currentID, setCurrentID] = useState(0);


    const loadRef = useRef()
    const listRef = useRef()
    const imageRef = useRef();

    useEffect(() => {
        if (props.listModeleChambre) {
            setListModeleChambre(props.listModeleChambre)
            loadRef.current.hidden = true
        }
    }, [props.listModeleChambre])

    const handleOpen = (item) => {
        const currentImage = []
        item.image_chambre.map((img) => (
            currentImage.push({ "src": baseUrl + img.libelle, "alt": "" })
        ))
        setImages(currentImage)
        setOpen(true)
        setItem(item)
        setCurrentID(item.id)
    };
    const handleClose = () => {
        setOpen(false)
        props.resetData(!props.statusData)
    }

    const onChangePhoto = (e) => {
        const fileList = [];
        for (let i = 0; i < e.target.files.length; i++) {
            fileList.push(e.target.files[i]);
        }
        setImageChambre(fileList);
    };

    const clearValue = () => {
        setImageChambre(null)
        imageRef.current.value = null
        setCurrentID(0)
    }

    const onAddImage = (id) => {
        if (imageChambre) {
            ModeleChambreService.addImage(id, { "imageChambre": imageChambre })
                .then(res => {
                    setItem(res.data.data)
                    clearValue()
                })
                .catch(err => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oups...',
                        text: 'Opération non effectuée',
                    });
                })
        }
    }
    const onDeleteImage = idImageChambre => {
        ModeleChambreService.deleteImage(idImageChambre)
            .then(res => {
                setItem(res.data.data)
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oups...',
                    text: 'Opération non effectuée',
                });
            })
    }
    const onSetPrinciplaImage = (idModeleChambre, idImageChambre) => {
        ModeleChambreService.setPrincipalImage(idModeleChambre, idImageChambre)
            .then(res => {
                setItem(res.data.data)
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oups...',
                    text: 'Opération non effectuée',
                });
            })
    }


    const onDelete = (id) => { // delete a ModeleChambre 
        Swal.fire({
            title: 'Suppression',
            text: "Cette action est irreversible, continuer?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#37474F',
            confirmButtonText: 'oui, continuer',
            cancelButtonText: 'annuler',
        }).then((result) => {
            if (result.isConfirmed) {
                loadRef.current.hidden = false
                ModeleChambreService.deleteModeleChambre(id)
                    .then(res => {
                        ModeleChambreService.listModeleChambre()
                            .then(res => {
                                setListModeleChambre(res.data.data);
                            });
                        loadRef.current.hidden = true
                        props.clearValue()
                        SuccessNotification.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                    })
                    .catch(err => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oups...',
                            text: 'Opération non effectuée',
                        });
                        loadRef.current.hidden = true
                    })
            }
        })
    }

    return (
        <Box>
            <Box ref={loadRef} sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
            <TableContainer ref={listRef} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Description</TableCell>
                            <TableCell align="right">Caractéristiques</TableCell>
                            <TableCell align="right">Accessoire</TableCell>
                            <TableCell align="right">Tarif</TableCell>
                            <TableCell align="right">Max personne</TableCell>
                            <TableCell align="right">Nombre</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listModeleChambre &&
                            listModeleChambre.map((row) => (
                                <>
                                <TableRow
                                    key={`fr${row.id}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell rowSpan={2} component="th" scope="row">
                                        {
                                            row.image_chambre.length > 0 &&
                                            <Box component='img' sx={{ maxWidth: '200px' }} src={baseUrl + row.image_chambre[0].libelle} />
                                        }
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.type}</TableCell>
                                    <TableCell align="right">{row.description}</TableCell>
                                    <TableCell align="right">{row.caracteristique}</TableCell>
                                    <TableCell align="right">
                                        {row.accessoire.map((access, index) => (
                                            <span key={access.id}>{`${index === 0 ? '' : ','}`}{access.libelle}</span>
                                        ))}
                                    </TableCell>
                                    <TableCell rowSpan={2} align="right">{row.tarif} Ar/Nuité</TableCell>
                                    <TableCell rowSpan={2} align="right">{row.max_personne}</TableCell>
                                    <TableCell rowSpan={2} align="right">{row.nombre}</TableCell>
                                    <TableCell rowSpan={2} align="right">
                                        <IconButton aria-label="image" onClick={() => handleOpen(row)} size="large">
                                            <PhotoIcon color='primary' />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={() => props.onEdit(row.id)} size="large">
                                            <EditIcon color='warning' />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={() => onDelete(row.id)} size="large">
                                            <DeleteIcon color='error' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key={`en${row.id}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="right">{row.en_type}</TableCell>
                                    <TableCell align="right">{row.en_description}</TableCell>
                                    <TableCell align="right">{row.en_caracteristique}</TableCell>
                                    <TableCell align="right">
                                        {row.accessoire.map((access, index) => (
                                            <span key={access.id}>{`${index === 0 ? '' : ','}`}{access.en_libelle}</span>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            </>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Box sx={style}>
                        {/* <ArrowedCarousel images={images} /> */}
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Box
                                    component="input"
                                    id="photo"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={(e) => onChangePhoto(e)}
                                    ref={imageRef}
                                    sx={{
                                        fontFamily: "serif, arial",
                                        fontSize: "18px",
                                        color: Couleur.bordeau,
                                        cursor: "pointer",
                                    }}
                                />
                                <Button variant="contained" onClick={() => onAddImage(currentID)}>ajouter</Button>
                            </Box>
                            <Box>
                                <IconButton
                                    sx={{
                                        color: 'red',
                                        // position: 'absolute',
                                        // left: '50%',
                                        transition: 'all .5s',
                                        '&:hover': { color: '#850000' },
                                        zIndex: 100
                                    }}
                                    onClick={handleClose}>
                                    <HighlightOffIcon sx={{ fontSize: '50px', fontStyle: 'light' }} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box px={5} my={5}>
                            <Grid container spacing={3}>
                                {
                                    item &&
                                    <>
                                        {item.image_chambre.map((img) => (
                                            <Grid key={img.id} item xs={12} sm={6} md={4} lg={3} sx={{ maxHeight: 300, position: 'relative' }}>
                                                <Box component="img" src={baseUrl + img.libelle} sx={{ width: '100%', height: '100%' }} alt='image' />
                                                {item.image_chambre.length > 1 &&
                                                    <Box sx={{ position: "absolute", display: "flex", justifyContent: "space-between", bottom: '0px', width: '95%'}}>
                                                        <Box>
                                                            <Checkbox sx={{ color: 'white' }} onChange={() => onSetPrinciplaImage(currentID, img.id)} checked={img.est_principale === 1 ? true : false} />
                                                        </Box>
                                                        <Box>
                                                            <IconButton aria-label="delete" size="large">
                                                                <DeleteIcon color='error' onClick={() => onDeleteImage(img.id)} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Grid>
                                        ))}
                                    </>
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}