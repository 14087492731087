import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Couleur } from "../../Header";
import MenuService from "../../services/MenuService";
import { SuccessNotification } from "./ModeleChambre";
import { TableArtsMalagasy } from "./extends/TableArtsMalagasy";
import { useNavigate } from "react-router-dom";

const ArtsProduitMalagasy = (props) => {
  const [ID_MENU, setID_MENU] = useState();
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const [idMenu, setIdMenu] = useState(null);
  const [menu, setMenu] = useState(null);
  const [sousMenus, setSousMenus] = useState([]);
  const [titre, setTitre] = useState("");
  const [enTitre, setEnTitre] = useState("");
  const [sousTitre, setSousTitre] = useState("");
  const [enSousTitre, setEnSousTitre] = useState("");
  const [description, setDescription] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [images, setImages] = useState(null);
  const imageRef = useRef();

  const onCancel = () => {
    setState(false);
    clearValue();
  };

  useEffect(() => {
    clearValue();
    setID_MENU(props.id);
    getData(props.id);
    console.log("menu id",props.id);
  }, [props.id]);

  const getData = (id) => {

    MenuService.getMenu(id ?? ID_MENU).then((res) => {
      setMenu({...res.data.data});
    });
    MenuService.getAllSousMenu(id ?? ID_MENU).then((res) => {
      setSousMenus([...res.data.data]);
    });
  };
  const clearValue = () => {
    setDescription("");
    setSousTitre("");
    setTitre("");
    setEnDescription("");
    setEnSousTitre("");
    setEnTitre("");
    setImages(null);
    setIdMenu(null);
    setState(false);
    imageRef.current.value = null;
  };
  const onChangePhoto = (e) => {
    const fileList = [];
    for (let i = 0; i < e.target.files.length; i++) {
      fileList.push(e.target.files[i]);
    }
    setImages(fileList);
  };

  const onEditMenu = (data) => {
    setState(true);
    setIdMenu(data.id);
    if(data.estMenu === 0){
      setTitre(data.titre)
      setEnTitre(data.en_titre ?? "")
    } else {
      setTitre(null);
      setEnTitre(null);
    }
    setSousTitre(data.sousTitre ?? "");
    setDescription(data.description ?? "");
    setEnSousTitre(data.en_sousTitre ?? "");
    setEnDescription(data.en_description ?? "");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let datas = {
      sousTitre,
      en_sousTitre : enSousTitre,
      description,
      en_description : enDescription,
    };

    if (images) datas["imageContentMenu"] = images;
    if (titre ) datas["titre"] = titre;
    if (enTitre) datas["en_titre"] = enTitre;

    if (idMenu === null) {
      MenuService.storeSous_Menu({ ...datas, menu_id: ID_MENU }, idMenu)
        .then((res) => {
          SuccessNotification.fire({
            icon: "success",
            title: res.data.message,
          });
          getData();
          clearValue();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oups...",
            text: err,
          });
        });
    } else {
      if (titre) {
        MenuService.updateSousMenu({ ...datas, menu_id: ID_MENU }, idMenu)
          .then((res) => {
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
            getData();
            clearValue();
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: err,
            });
          });
      } else {
        MenuService.updateMenu(datas, idMenu)
          .then((res) => {
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
            getData();
            clearValue();
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: err,
            });
          });
      }
    }
  };

  const onDelete = (data) => {
    Swal.fire({
      title: "Suppression",
      text: "Cette action est irreversible, continuer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#37474F",
      confirmButtonText: "oui, continuer",
      cancelButtonText: "annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        MenuService.deleteMenu(data?.id)
          .then((res) => {
            getData(idMenu);
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: "Opération non effectuée",
            });
          });
      }
    });
  };
  return (
    <div style={{ padding: "0 1rem" }}>
      <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
        <Box
          component="ol"
          className="breadcrumb mb-4"
          sx={{
            backgroundColor: Couleur.jaune,
            p: 2,
            width: "100%",
            borderRadius: "4px",
          }}
        >
          <li className="breadcrumb-item">
            <a href="#!">
              <label>Page actuelle :</label>
            </a>
          </li>
          <li className="breadcrumb-item">
            <a href="#!">Dashboard</a>
          </li>
          <li className="breadcrumb-item active">{menu?.titre}</li>
        </Box>
      </Box>
      <div style={{ padding: "1rem" }}>
        <Card
          component="form"
          onSubmit={(e) => onSubmit(e)}
          sx={{ width: "800px", padding: "1rem" }}
        >
        <Stack  direction="row" spacing={3}>
          <Box sx={{ width: "100%" }}>
            <CardActions>Nouveau Sous Titre</CardActions>
            {titre !== null && (
            <TextField
              mt={3}
              label="titre"
              value={titre}
              onChange={(e) => setTitre(e.target.value)}
              variant="standard"
              sx={{
                fontFamily: "serif, arial",
                fontSize: "18px",
                width: "100%",
                color: Couleur.bordeau,
                cursor: "pointer",
              }}
            />
          )}
          <TextField
            mt={3}
            label="sous titre"
            value={sousTitre}
            onChange={(e) => setSousTitre(e.target.value)}
            variant="standard"
            sx={{
              fontFamily: "serif, arial",
              fontSize: "18px",
              width: "100%",
              color: Couleur.bordeau,
              cursor: "pointer",
            }}
          />
           <TextField
            mt={3}
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="standard"
            rows={4}
            multiline
            sx={{ width: "100%" }}
          />
          </Box>
          <Box sx={{ width: "100%" }}>
            <CardActions>Sous Titre en englais</CardActions>
            {titre !== null && (
            <TextField
              mt={3}
              label="titre en englais"
              value={enTitre}
              onChange={(e) => setEnTitre(e.target.value)}
              variant="standard"
              sx={{
                fontFamily: "serif, arial",
                fontSize: "18px",
                width: "100%",
                color: Couleur.bordeau,
                cursor: "pointer",
              }}
            />
          )}
          <TextField
            mt={3}
            label="sous titre en englais"
            value={enSousTitre}
            onChange={(e) => setEnSousTitre(e.target.value)}
            variant="standard"
            style={{ width: "100%" }}
          />
           <TextField
            mt={4}
            label="Description en englais"
            value={enDescription}
            onChange={(e) => setEnDescription(e.target.value)}
            variant="standard"
            rows={4}
            multiline
            style={{ width: "100%" }}
          />
          </Box>
        </Stack>
          <Grid mt={3} item md={12} px={3} py={2}>
            <Box
              component="input"
              id="photo"
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => onChangePhoto(e)}
              ref={imageRef}
              sx={{
                fontFamily: "serif, arial",
                fontSize: "18px",
                color: Couleur.bordeau,
                cursor: "pointer",
              }}
            ></Box>
          </Grid>

          {!state ? (
            <CardActions>
              <Button variant="contained" type="submit">
                ajouter
              </Button>
            </CardActions>
          ) : (
            <CardActions>
              <Button variant="contained" color="warning" type="submit">
                modifier
              </Button>
              <Button
                variant="contained"
                onClick={onCancel}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  "&:hover": { backgroundColor: "#eeefff" },
                }}
              >
                annuler
              </Button>
            </CardActions>
          )}
        </Card>
      </div>
      <Box>
        <TableArtsMalagasy
          selectImage={(id) => navigate(`/admin/dashboard/menu/${id}/images`)}
          onEdit={onEditMenu}
          menu={menu}
        />
      </Box>
      <Box>
        <Box component="h1">les sous Menu</Box>
        <TableArtsMalagasy
          selectImage={(id) =>
            navigate(`/admin/dashboard/sous_menu/${id}/images`)
          }
          onEdit={onEditMenu}
          delete={true}
          onDelete={onDelete}
          menu={sousMenus}
          titre={true}
        />
      </Box>
    </div>
  );
};

export default ArtsProduitMalagasy;
