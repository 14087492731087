import axios from "axios";
import { apiUrl } from "./module";

const header = ()=>{

    const user_token = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
    const config = {
        headers: {
            Authorization: `Bearer ${user_token}`,
            "Access-Control-Allow-Origin": "*",
        }
    }
    return config;
}

class ReserverService {
    availableRoom(dates){
        return axios.post(apiUrl + "/availableRoom", dates, header());
    }
    reserver(datas){
        return axios.post(apiUrl + "/reserverChambre", datas, header());
    }
    checkClient(email){
        return axios.get(apiUrl + "/checkClient/"+ email, header());
    }
    getMaxPerson(){
        return axios.get(apiUrl + "/getMaxPerson", header());
    }
    validateReserver(trans_token){
        return axios.get(apiUrl + "/validateReserver/"+trans_token, header());
    }
}

export default new ReserverService();