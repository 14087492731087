import React, { useEffect, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import styled from '@emotion/styled';
import ModeleChambreService from '../../services/ModeleChambreService';
import logo from '../../../assets/images/logo.png'
import '../../../assets/scss/resumeFinal.scss'

const Text = styled('p')({
    fontSize: 20,
    textAlign: "center",
});


export const ResumeFinal = (props) => {
    const [total, setTotal] = useState(0)
    const [listChoosed, setListChoosed] = useState([])
    const [client, setClient] = useState(null)
    const [totalPerson, setTotalPerson] = useState(0)

    useEffect(() => {
        setClient(props.client)
        if (props.client.roomData && props.client.roomData.length > 0) {
            let persons = 0
            props.client.roomData.forEach(rd => {
                persons += rd.personNumber
            })
            setTotalPerson(persons)
        }

        ModeleChambreService.listModeleChambre()
            .then(res => {
                let newTotal = 0
                const newListChoosed = []
                props.listChoixChambre.forEach(element => {
                    const room = res.data.data.find(roomItem => roomItem.id === element.id)
                    const item = {
                        title: room.type,
                        person: Number(element.kids) + Number(element.adults),
                        price: element.montant
                    }
                    newTotal = newTotal + element.montant
                    newListChoosed.push(item)
                    setListChoosed(newListChoosed)

                });
                setTotal(newTotal)
            })
    }, [])

    return (
        <Box className='resumClass'>
            <Box className="" m={2} my={5}>
                <a className='custom-btn topButton' href="/reserver">retour</a>
            </Box>
            <Grid container
                sx={{
                    position: "absolute",
                    top: { xs: "1%", sm: "5%", md: "20%", lg: "20%" }
                }}
            >
                <Grid item xs={12} sm={12} md={5} lg={5}
                ><Box component="img" sx={{ width: "100%", height: "auto" }} src={logo} /></Grid>

                <Grid item xs={12} sm={12} md={7} lg={7}
                    p={5} pt={"3%"}
                >
                    <Box mb={8}>
                        <Text component="p">Cher(e) {`${client?.nom} ${client?.prenom},`} </Text>
                    </Box>
                    <Text component="p">Merci d'avoir choisi ZOMATEL pour votre séjour du {`${client?.arrivee} au ${client?.depart}`} dans notre
                        {listChoosed.length > 0 &&
                            listChoosed.map((rooms, index) => (
                                <Box key={index} component="span"> {index !== 0 && ", "} {rooms.title} </Box>
                            ))
                        }
                        pour {totalPerson} personne{totalPerson > 1 && "s"}.
                    </Text>
                    <Text component="p"> Nous serons ravis de vous accueillir dans notre établissement et serons à votre disposition afin que vous
                        viviez une expérience unique tout au long de votre séjour.
                    </Text>
                    <Box my={4}>
                        <Text component="p"><b>Vous paierez votre séjour à votre arrivée.</b></Text>
                    </Box>
                    <Text component="p">
                        Pour tous renseignements, vous pouvez contacter la réception au +261 34 07 255 27 ou par mail à resa@zomatel.com
                    </Text>
                </Grid>
            </Grid>
        </Box>
    )
}