import { Autocomplete, Button, Card, CardActions, CardContent, Grid, IconButton, LinearProgress, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import Swal from "sweetalert2";
import styled from '@emotion/styled';
import ChambreService from '../../services/ChambreService';
import { Couleur } from '../../Header';

const SuccessNotification = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});


const ListItems = styled('li')({
  display: 'flex',
  justifyContent: 'space-between'
})

export const ChambreDashboard = () => {
  const [listChambre, setListChambre] = useState([])
  const [listToChoose, setListToChoose] = useState([])
  const [selectionValue, setSelectionValue] = useState(null)

  const [nomChambre, setNomChambe] = useState('')
  const [modeleID, setModeleID] = useState(null)

  const loadRef = useRef()
  const listRef = useRef()

  useEffect(() => {
    ChambreService.listChambre()
      .then(res => {
        setListChambre(res.data.data)
        loadRef.current.hidden = true
        listRef.current.hidden = false
        setChooseList(res.data.data)
      })
  }, [])

  const setChooseList = (datas) => {
    const getList = []
    datas.forEach(element => {
      if (element.chambre.length < element.nombre) {
        getList.push(element.type)
      }
    })
    setListToChoose(getList)
  }

  const onChoose = (newValue) => {
    const modeleC = listChambre.find(ls => ls.type === newValue)
    modeleC && setModeleID(modeleC.id)
    setSelectionValue(newValue)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const datas = {
      'libelle': nomChambre,
      'modele_chambre_id': modeleID
    }
    if (nomChambre && modeleID) {
      loadRef.current.hidden = false
      ChambreService.storeChambre(datas)
        .then(res => {
          SuccessNotification.fire({
            icon: 'success',
            title: res.data.message
          });
          ChambreService.listChambre()
            .then(res => {
              setListChambre(res.data.data);
              setChooseList(res.data.data)
              loadRef.current.hidden = true
            });
          setNomChambe('')
          setModeleID(null)
          setSelectionValue(null)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Oups...',
            text: err.response.data.message,
          });
          loadRef.current.hidden = true
        });
    }
  }

  const onDelete = (id) => {
    Swal.fire({
      title: 'Suppression',
      text: "Cette action est irreversible, continuer?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#37474F',
      confirmButtonText: 'oui, continuer',
      cancelButtonText: 'annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        loadRef.current.hidden = false
        ChambreService.deleteChambre(id)
          .then(res => {
            ChambreService.listChambre()
              .then(res => {
                setListChambre(res.data.data);
                setChooseList(res.data.data)
              });
            SuccessNotification.fire({
              icon: 'success',
              title: res.data.message
            });
            loadRef.current.hidden = true
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Oups...',
              text: 'Opération non effectuée',
            });
          })
      }
    })
  }

  return (
    <Box>
      <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
        <Box component='ol' className="breadcrumb mb-4" sx={{ backgroundColor: Couleur.jaune, p: 2, width: '100%', borderRadius: '4px' }}>
          <li className="breadcrumb-item"><a href="#!"><label>Page actuelle :</label></a></li>
          <li className="breadcrumb-item"><a href="#!">Dashboard</a></li>
          <li className="breadcrumb-item active">Chambre</li>
        </Box>
      </Box>
      <Card sx={{ maxWidth: 350, ml: 5 }}>
        <Box component='form' onSubmit={(e) => onSubmit(e)}>
          <CardActions>Nouveau</CardActions>
          <CardContent>
            <TextField label="num chambre" maxLength='3' value={nomChambre} onChange={e => setNomChambe(e.target.value)} variant="standard" />
            <Autocomplete
              disablePortal
              value={selectionValue}
              options={listToChoose}
              onChange={(event, newValue) => onChoose(newValue)}
              sx={{ width: 208, mt: 2 }}
              renderInput={(params) => <TextField {...params} label="Type" variant='standard' />}
            />
          </CardContent>
          <CardActions>
            <Button variant='contained' type='submit'>ajouter</Button>
          </CardActions>
        </Box>
      </Card>

      <Box sx={{ mt: 4, ml: 5 }}>
        <Box ref={loadRef} sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
        <Box hidden ref={listRef}>
          {
            listChambre.length > 0 &&
            <Grid container spacing={3}>
              {
                listChambre.map((modeles, index) => (
                  <Grid item key={modeles.id}>
                    <Box component='ul' className="list-group">
                      <li className="list-group-item active" aria-current="true">{modeles.type}</li>
                      {
                        modeles.chambre.length > 0 &&
                        modeles.chambre.map((room) => (
                          <ListItems className="list-group-item" key={room.id}>
                            <span className='mt-2'>
                              <IconButton aria-label="delete" size="sm" >
                                <DoorFrontIcon />
                              </IconButton>
                              {room.libelle}
                            </span>
                            <IconButton aria-label="delete" size="sm" onClick={() => onDelete(room.id)} >
                              <DeleteIcon color='error' />
                            </IconButton>
                          </ListItems>
                        ))
                      }
                    </Box>
                  </Grid>
                ))
              }
            </Grid>
          }
        </Box>
      </Box>
    </Box>
  )
}
