import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { Couleur, Header } from "../Header";
import { Box, Grid, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkIcon from "@mui/icons-material/Link";
import "../../assets/scss/reserve.scss";
import ReserverService from "../services/ReserverService";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { SelectionDate } from "./etapes/SelectionDate";
import { SelectionChambre } from "./etapes/SelectionChambre";
import { InformationClient } from "./etapes/InformationClient";
import { Footer } from "../Footer";
import { ResumeFinal } from "./etapes/ResumeFinal";
import Arrow from "../tools/Arrow";
import { DEFAULT_LIST_CHAMBRE, Resume } from "./etapes/composants/Resume";

const UseDisponibilite = () => {
  const [disponibibilteChambre, setDisponibiliteChambre] = useState([]);

  const setDisponible = (modelChambre) => {
    const indexDisponibleChambre = disponibibilteChambre.findIndex(
      (dispoChambre) => dispoChambre.id === modelChambre.id
    );
    let copy_disponibibilteChambre = [...disponibibilteChambre];

    if (indexDisponibleChambre >= 0) {
      let copy_oneChambredispo =
        copy_disponibibilteChambre[indexDisponibleChambre];
      copy_disponibibilteChambre[indexDisponibleChambre] = {
        id: copy_oneChambredispo.id,
        disponibite: copy_oneChambredispo.disponibite + 1,
      };
    } else {
      copy_disponibibilteChambre = [
        ...copy_disponibibilteChambre,
        {
          id: modelChambre.id,
          disponibite: 1,
        },
      ];
    }
    setDisponibiliteChambre([...copy_disponibibilteChambre]);
  };

  const setInDisponible = (modelChambre) => {
    const indexDisponibleChambre = disponibibilteChambre.findIndex(
      (dispoChambre) => dispoChambre.id === modelChambre.id
    );

    let copy_disponibibilteChambre = [...disponibibilteChambre];
    let copy_oneChambredispo =
      copy_disponibibilteChambre[indexDisponibleChambre];
    copy_disponibibilteChambre[indexDisponibleChambre] = {
      id: copy_oneChambredispo.id,
      disponibite: copy_oneChambredispo.disponibite - 1,
    };
    copy_disponibibilteChambre = copy_disponibibilteChambre.filter(
      (value) => value.disponibite > 0
    );
    setDisponibiliteChambre([...copy_disponibibilteChambre]);
  };

  const findDisponibilite = (modelChambre) => {
    if (disponibibilteChambre.length === 0) return 0;
    const indexDisponibleChambre = disponibibilteChambre.findIndex(
      (dispoChambre) => dispoChambre.id === modelChambre.id
    );

    if (indexDisponibleChambre >= 0) {
      return Number(disponibibilteChambre[indexDisponibleChambre].disponibite);
    } else {
      return 0;
    }
  };
  return {
    setDisponible,
    setInDisponible,
    findDisponibilite,
  };
};

export const Reserver = () => {
  const [minH, setMinH] = useState("100vh");
  const [backgUrl, setBackgUrl] = useState(null);

  const { setDisponible, setInDisponible, findDisponibilite } =
    UseDisponibilite();

  const [listChoixChambre, setListChoixChambre] =
    useState(DEFAULT_LIST_CHAMBRE);
  const [listModeleChambre, setListModeleChambre] = useState([]); // room list from database
  const [listChambreSelected, setListChambreSelected] = useState([0]);
  const [indexListChambre, setIndexListChambre] = useState(0);

  const [dates, setDates] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isShowSelectionChambre, setIsShowSelectionChambre] = useState(false);
  const [isShowSelectionDate, setIsShowSelectionDate] = useState(true);
  const [isShowInfoClient, setIsShowInfoClient] = useState(false);
  const [isShowFinalResume, setIsShowFinalResume] = useState(false);
  const [sejour, setSejour] = useState(0);
  const [totalTarif, setTotalTarif] = useState(0);
  const [devise, setDevise] = useState(null);
  const [checkedArrivee, setCheckedArrivee] = useState(new Date());
  const [checkedDepart, setCheckedDepart] = useState(new Date());
  const [showPayementButton, setShowPayementButton] = useState(false);
  const [client, setClient] = useState(null);
  const [open, setOpen] = React.useState(false); // open the backdrop

  const [dateArrivee, setDateArrivee] = useState("");
  const [dateDepart, setDateDepart] = useState("");

  const headerNormal = useRef();
  const headerInfo = useRef();
  const informationRef = useRef();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    $("body").css({
      overflow: "visible",
    });
  }, []);

  const onChangeStep = (index) => {
    // while clicking arrow back or trying to go next step by arrow
    if (listChoixChambre) {
      if (index < currentStep) {
        setIndexListChambre(index - 1);
        setCurrentStep(index);
        step2(index);
      }
    }
  };

  const onNextStep = () => {
    // while clicking next button for example
    if (listChoixChambre[currentStep - 1].id !== 0) {
      currentStep === listChoixChambre.length && step3();
      setIndexListChambre(indexListChambre + 1);
      setCurrentStep(currentStep + 1);
      setIndexListChambre(currentStep);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Attention",
        text: "N'oubliez pas de choisir une chambre",
      });
    }
  };

  const updateDevise = (newDevise) => {
    let total = totalTarif;
    if (total > 0) {
      if (devise) total *= devise.valeur_ariary;
      if (newDevise) total /= newDevise.valeur_ariary;
    }
    setTotalTarif(total);
    setDevise(newDevise);
  };

  const updateGlobaleTable = (checked, chambreSelected, indexModify) => {
    let newSelected = [...listChambreSelected];

    let totalValue = 0;

    if (!checked) {
      newSelected[indexModify] = 0;
      setInDisponible(chambreSelected);

      // update total
      if (devise) {
        totalValue =
          totalTarif - (chambreSelected.tarif / devise.valeur_ariary) * sejour;
      } else {
        totalValue = totalTarif - chambreSelected.tarif * sejour;
      }
      setTotalTarif(totalValue);

      // update listChoix chambre
      listChoixChambre[indexModify]["id"] = 0;
      listChoixChambre[indexModify]["montant"] = 0;
    } else {
      newSelected[indexModify] = chambreSelected;
      setDisponible(chambreSelected);

      // update total
      if (devise) {
        totalValue =
          totalTarif + (chambreSelected.tarif / devise.valeur_ariary) * sejour;
      } else {
        totalValue = totalTarif + chambreSelected.tarif * sejour;
      }
      setTotalTarif(totalValue);

      // update listChoix chambre
      listChoixChambre[indexModify]["id"] = chambreSelected.id;
      listChoixChambre[indexModify]["montant"] = chambreSelected.tarif * sejour;
    }
    setListChambreSelected([...newSelected]);
  };

  const handlePaiement = (e) => {
    informationRef.current.handleSubmit(e);
  };

  // show the date selection on step 1
  const step1 = () => {
    setIsShowSelectionDate(true);
    setIsShowSelectionChambre(false);
    setIsShowInfoClient(false);
    setCurrentStep(0);
    setMinH("100vh");
    setBackgUrl(null);
    headerInfo.current.hidden = false;
  };

  // show the room selection step
  const step2 = (currentStep = null) => {
    setMinH("10vh");
    setBackgUrl("none");
    headerInfo.current.hidden = true;

    if (dates) {
      if (!currentStep) {
        setCurrentStep(currentStep + 1);
        setIndexListChambre(0);
      }
      ReserverService.availableRoom({
        arrivee: dates.dtArrivee,
        depart: dates.dtDepart,
      }).then((res) => {
        setListModeleChambre(res.data.data);
      });
      setIsShowSelectionChambre(true);
      isShowInfoClient && setIsShowInfoClient(false);
      setIsShowSelectionDate(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  // show the client information form step
  const step3 = () => {
    setIsShowSelectionChambre(false);
    setIsShowSelectionDate(false);
    setIsShowInfoClient(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // put the room name on resume
  const onRoomName = (index, name, id, montant) => {
    listChoixChambre[index]["name"] = name;
    listChoixChambre[index]["id"] = id;
    listChoixChambre[index]["montant"] = montant;
  };

  return (
    <Box>
      {open && (
        <Backdrop
          sx={{
            color: Couleur.bordeau,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isShowFinalResume && (
        <Box ref={headerNormal}>
          <Header
            className="contact-header"
            minH={minH}
            backgUrl={backgUrl}
            showRes="none"
            showCall="block"
          />
        </Box>
      )}
      <Box ref={headerInfo} className="contact-info">
        <Box className="contact-content">
          <ListItem>
            <ListItemIcon>
              <AddHomeWorkIcon
                style={{
                  fill: "#850000",
                  width: "50px",
                  height: "50px",
                  marginRight: "20px",
                }}
              />
            </ListItemIcon>
            <ListItemText>BP: 1367, Place du Zoma</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon
                style={{
                  fill: "#850000",
                  width: "50px",
                  height: "50px",
                  marginRight: "20px",
                }}
              />
            </ListItemIcon>
            <ListItemText>
              (+261) 20 75 507 97 / (+261) 34 07 255 27
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LinkIcon
                style={{
                  fill: "#850000",
                  width: "50px",
                  height: "50px",
                  marginRight: "20px",
                }}
              />
            </ListItemIcon>
            <ListItemText>zomatel-madagascar.com</ListItemText>
          </ListItem>
        </Box>
      </Box>
      {isShowFinalResume ? (
        <Box>
          <ResumeFinal
            dateArrivee={dateArrivee}
            dateDepart={dateDepart}
            sejour={sejour}
            listChoixChambre={listChoixChambre}
            client={client}
          />
        </Box>
      ) : (
        <Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <Box>
                {/* STEP 1 => select date */}
                {isShowSelectionDate && (
                  <Box>
                    <SelectionDate
                      step2={step2}
                      newSejour={setSejour}
                      setDate={setDates}
                      sejour={sejour}
                      listChambreSelected={listChambreSelected}
                      setListChambreSelected={setListChambreSelected}
                      setDevise={setDevise}
                      updateTarif={setTotalTarif}
                      setDateArrivee={setDateArrivee}
                      setDateDepart={setDateDepart}
                      checkedArrivee={checkedArrivee}
                      checkedDepart={checkedDepart}
                      setCheckedArrivee={setCheckedArrivee}
                      setCheckedDepart={setCheckedDepart}
                    />
                  </Box>
                )}

                {/* STEP 2 => select room */}
                {isShowSelectionChambre && (
                  <Box>
                    <Grid container pl={10}>
                      <Grid item>
                        <Arrow arrowContent="Selection date" onClick={step1} />
                      </Grid>
                      {listChoixChambre &&
                        listChoixChambre.map((choix, index) => (
                          <Grid item key={index}>
                            <Arrow
                              key={index}
                              active={index + 1 === currentStep ? "1" : "0.5"}
                              arrowContent={"Chambre " + (index + 1)}
                              onClick={() => {
                                onChangeStep(index + 1);
                              }}
                            />
                          </Grid>
                        ))}
                      <Grid item>
                        <Arrow arrowContent="Information du client" />
                      </Grid>
                      <Grid item>
                        <Arrow arrowContent="Résumé" />
                      </Grid>
                    </Grid>
                    <Box
                      component="p"
                      sx={{
                        color: Couleur.jaune,
                        fontSize: "50px",
                        fontFamily: "'Glacial Indifference', serif",
                        pl: 10,
                      }}
                    >
                      Sélectionner une chambre
                    </Box>
                    <SelectionChambre
                      listModeleChambre={listModeleChambre}
                      modelChambre={listChoixChambre[indexListChambre]}
                      indexModify={indexListChambre}
                      currentSelected={listChambreSelected[indexListChambre]}
                      currentDevise={devise}
                      updateTotal={setTotalTarif}
                      updateGlobaleTable={updateGlobaleTable}
                      onRoomName={onRoomName}
                      findDisponibilite={findDisponibilite}
                    />
                  </Box>
                )}

                {/* STEP 3 => information client */}
                {isShowInfoClient && (
                  <Box>
                    <Grid container pl={10}>
                      <Grid item>
                        <Arrow arrowContent="Selection date" onClick={step1} />
                      </Grid>
                      {listChoixChambre &&
                        listChoixChambre.map((choix, index) => (
                          <Grid item key={index}>
                            <Arrow
                              key={index}
                              active={index + 1 === currentStep ? "1" : "0.5"}
                              arrowContent={"Chambre " + (index + 1)}
                              onClick={() => {
                                onChangeStep(index + 1);
                              }}
                            />
                          </Grid>
                        ))}
                      <Grid item>
                        <Arrow
                          active={
                            listChoixChambre &&
                            currentStep === listChoixChambre.length + 1
                              ? "1"
                              : "0.5"
                          }
                          arrowContent="Information du client"
                        />
                      </Grid>
                      <Grid item>
                        <Arrow arrowContent="Résumé" />
                      </Grid>
                    </Grid>
                    <Box
                      component="p"
                      sx={{
                        color: Couleur.jaune,
                        fontSize: "50px",
                        fontFamily: "'Glacial Indifference', serif",
                        pl: 10,
                      }}
                    >
                      Information client
                    </Box>
                    <InformationClient
                      ref={informationRef}
                      setShowPayementButton={setShowPayementButton}
                      setIsShowFinalResume={setIsShowFinalResume}
                      handlePaiement={handlePaiement}
                      dates={dates}
                      listChoixChambre={listChoixChambre}
                      total={totalTarif}
                      currentDevise={devise}
                      sejour={sejour}
                      dateArrivee={dateArrivee}
                      dateDepart={dateDepart}
                      listChambreSelected={listChambreSelected}
                      setClient={setClient}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              pt={
                isShowSelectionDate
                  ? { xs: 2, sm: 2, md: 20, lg: 20 }
                  : { xs: 2, sm: 4, md: 28.5, lg: 21 }
              }
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box id="resumeSection">
                <Resume
                  showPayementButton={showPayementButton}
                  handlePaiement={handlePaiement}
                  setInDisponible={setInDisponible}
                  dates={dates}
                  step2={step2}
                  nextStep={onNextStep}
                  dateArrivee={dateArrivee}
                  dateDepart={dateDepart}
                  setListChoixChambre={setListChoixChambre}
                  setIndexListChambre={setIndexListChambre}
                  setCurrentStep={setCurrentStep}
                  setListChambreSelected={setListChambreSelected}
                  listChambreSelected={listChambreSelected}
                  currentStep={currentStep}
                  currentDevise={devise}
                  updateDevise={updateDevise}
                  isShowInfoClient={isShowInfoClient}
                  onChangeStep={onChangeStep}
                  firstStep={step1}
                  total={totalTarif}
                  updateTotal={setTotalTarif}
                  sejour={sejour}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {!isShowFinalResume && (
        <Box mt={8}>
          <Footer />
        </Box>
      )}
    </Box>
  );
};
