import axios from "axios";
import { apiUrl } from "./module";

const header = () => {
  const user_token = localStorage.getItem("user_token")
    ? localStorage.getItem("user_token")
    : "";
  const config = {
    headers: {
      Authorization: `Bearer ${user_token}`,
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return config;
};

class ServiceService {
  storeService(datas) {
    return axios.post(apiUrl + "/services", datas, header());
  }
  storeItemService(datas) {
    return axios.post(apiUrl + "/itemServices", datas, header());
  }
  storeServiceImage(datas, id) {
    return axios.post(apiUrl + "/services/" + id + "/images", datas, header());
  }
  listService() {
    return axios.get(apiUrl + "/services");
  }
  getService(id) {
    return axios.get(apiUrl + "/services/" + id);
  }
  listItemService(id) {
    return axios.get(apiUrl + "/services/" + id + "/itemService");
  }
  updateService(datas, id) {
    return axios.post(apiUrl + "/services/" + id, datas, header());
  }
  updateItemService(datas, id) {
    return axios.post(apiUrl + "/itemServices/" + id, datas, header());
  }
  deleteService(id) {
    return axios.delete(apiUrl + "/services/" + id, header());
  }
  deleteServiceImage(id) {
    return axios.delete(apiUrl + "/services/images/" + id, header());
  }
  deleteItemService(id) {
    return axios.delete(apiUrl + "/itemServices/" + id, header());
  }
}

export default new ServiceService();
