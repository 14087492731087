import React, { useEffect, useState } from 'react'
import { Header } from '../Header'

import pplanGalerie from '../../assets/images/Galerie/pplanGalerie.JPG'
import { Box, Grid, IconButton, Modal } from '@mui/material'


// images par défaut
import Carousel1 from '../../assets/images/defaultCarousel/1.jpg'
import Carousel2 from '../../assets/images/defaultCarousel/2.jpg'
import Carousel3 from '../../assets/images/defaultCarousel/3.jpg'
import Carousel4 from '../../assets/images/defaultCarousel/4.jpg'
import '../../assets/scss/galeriePart.scss'

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Footer } from '../Footer'
import GalerieService from '../services/GalerieService'
import { baseUrl } from '../services/module'

const defaultImages = [
  {
    src: Carousel1,
    alt: 'Carousel 1'
  },
  {
    src: Carousel2,
    alt: 'Carousel 2'
  },
  {
    src: Carousel3,
    alt: 'Carousel 3'
  },
  {
    src: Carousel4,
    alt: 'Carousel 4'
  },
  {
    src: Carousel3,
    alt: 'Carousel 1'
  },
  {
    src: Carousel4,
    alt: 'Carousel 2'
  },
  {
    src: Carousel1,
    alt: 'Carousel 3'
  },
  {
    src: Carousel2,
    alt: 'Carousel 4'
  },
  {
    src: Carousel1,
    alt: 'Carousel 1'
  },
  {
    src: Carousel2,
    alt: 'Carousel 2'
  },
  {
    src: Carousel3,
    alt: 'Carousel 3'
  },
  {
    src: Carousel4,
    alt: 'Carousel 4'
  },
  {
    src: Carousel3,
    alt: 'Carousel 1'
  },
  {
    src: Carousel4,
    alt: 'Carousel 2'
  },
  {
    src: Carousel1,
    alt: 'Carousel 3'
  },
  {
    src: Carousel2,
    alt: 'Carousel 4'
  },
]


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '80vh',
  bgcolor: 'white',
  border: '1px solid #fff',
  boxShadow: 24,
};

export const Galerie = () => {

  const [images, setImages] = useState([])
  const [currentImage, setCurrentImage] = useState({})

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (imgSrc, imgAlt) => {
    setOpen(true)
    setCurrentImage({ src: baseUrl+ imgSrc, alt: imgAlt })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    GalerieService.listGalerie()
    .then(res=>{setImages(res.data.data)})
    
  }, [])

  return (
    <Box>
      <Header backgUrl={pplanGalerie} headerTitle="Galerie photo" />
      <Box className='galleryContainer' px={9} pb='8%'>
        <Grid container spacing={2}>
          {
            images.map((image, index) => (
              <Grid item lg={3} md={4} sm={6} xs={12} position='relative' key={index} className='imageContainer' sx={{ maxHeight:400 }}>
                <img src={baseUrl + image.image_path} alt="IMAGES" height='100%' width='100%' />
                <Box className='boxOverlay'>
                  <Box className='textOverlay'>
                    <IconButton sx={{ color: 'black' }} onClick={() => handleOpen(image.image_path, "IMAGE")}>
                      <ZoomInIcon sx={{ fontSize: '40px' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))
          }
        </Grid>

        {/* the modal image */}
        <Box>
          <Modal
            open={open}
            onClose={handleClose}
          >
            <Box>
              <IconButton
                sx={{
                  color: 'white',
                  position: 'absolute',
                  left: '50%',
                  top: '35px',
                  transition: 'all .5s',
                  '&:hover': { color: '#850000' }
                }}
                onClick={handleClose}>
                <HighlightOffIcon sx={{ fontSize: '50px', fontStyle: 'light' }} />
              </IconButton>
              <Box sx={style}>
                <img src={currentImage.src} alt={currentImage.alt} width='100%' height='100%' />
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>

      <Footer/>
    </Box>
  )
}
