import styled from "@emotion/styled";
import { Button, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Link, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "../../assets/scss/dashboard.scss";
import { Couleur } from "../Header";
import { ModeleChambre } from "./pages/ModeleChambre";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Auth from "../services/Auth";
import Swal from "sweetalert2";

const MenuItem = styled("span")({
  color: Couleur.bordeau,
});

export const Dashboard = () => {
  let navigate = useNavigate();
  useEffect(() => {
    !localStorage.getItem("user_token") && navigate("/admin");
  }, []);

  const onLogout = () => {
    Auth.logout()
      .then((res) => {
        localStorage.removeItem("user_token");
        localStorage.removeItem("user_email");
        navigate("/admin");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Opération non permise",
          text: "Impossible de se déconnecter",
        });
      });
  };
  return (
    <div className="s-layout">
      {/* <!-- Sidebar --> */}
      <div className="s-layout__sidebar leftSide">
        <a className="s-sidebar__trigger" href="#0">
          <i className="fa fa-bars"></i>
        </a>

        <Box component="nav" position="relative" className="s-sidebar__nav">
          <ul>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/accessoire"
              >
                <i className="fa fa-toolbox"></i>
                <MenuItem>Accessoire</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/modele-chambre"
              >
                <i className="fa fa-warehouse"></i>
                <MenuItem>Modèle chambre</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/chambre"
              >
                <i className="fas fa-bed"></i>
                <MenuItem>Chambre</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/galerie"
              >
                <i className="fas fa-images"></i>
                <MenuItem>Galerie</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/devise"
              >
                <i className="fas fa-coins"></i>
                <MenuItem>Devise</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/art_produit_malagasy"
              >
                <i className="fa fa-toolbox"></i>
                <MenuItem>Arts & produit Malagasy</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/evenement_seminaire"
              >
                <i className="fa fa-toolbox"></i>
                <MenuItem>&Eacute;v&egrave;nenemt & séminaire</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/bien_etre_loisirs"
              >
                <i className="fa fa-toolbox"></i>
                <MenuItem>Bien-être et loisirs</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/gastronomie"
              >
                <i className="fa fa-toolbox"></i>
                <MenuItem>Gastronomie</MenuItem>
              </Link>
            </li>
            <li>
              <Link
                className="s-sidebar__nav-link"
                to="/admin/dashboard/service"
              >
                <i className="fa fa-toolbox"></i>
                <MenuItem>Service</MenuItem>
              </Link>
            </li>
            {localStorage.getItem("user_email") === "josuetouv@gmail.com" && (
              <li>
                <Link
                  className="s-sidebar__nav-link"
                  to="/admin/dashboard/compte"
                >
                  <i className="fas fa-cogs"></i>
                  <MenuItem>Compte</MenuItem>
                </Link>
              </li>
            )}
            <li className="logout">
              <Button
                startIcon={<PowerSettingsNewIcon />}
                onClick={onLogout}
                sx={{
                  backgroundColor: "white",
                  color: Couleur.bordeau,
                  "&:hover": { backgroundColor: "brown", color: "white" },
                }}
              >
                <Box
                  component="span"
                  display={{ xs: "block", sm: "none", md: "block" }}
                >
                  se déconnecter
                </Box>
              </Button>
            </li>
          </ul>
        </Box>
      </div>

      {/* <!-- Content --> */}
      <main className="s-layout__content w-100 mt-2 zIndex-20">
        <Routes>
          <Route path="/" element={<ModeleChambre />} />
        </Routes>
        <Outlet></Outlet>
      </main>
    </div>
  );
};
