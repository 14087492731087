import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Couleur, Header } from "../Header";
import menuHeader from "../../assets/images/Evenement/event.jpg";
import "../../assets/scss/chambre.scss";

import $ from "jquery";

import { Stack } from "@mui/system";
import styled from "@emotion/styled";
import { Footer } from "../Footer";
import { useParams } from "react-router-dom";
import MenuService, { getImg } from "../services/MenuService";
import { MENU_ID_SITE } from "../services/menuID";
import { useLangue } from "../../i18n";

const TextEvent = styled(Box)({
  marginLeft: "10%",
  cursor: "pointer",
  "&:hover p": {
    fontWeight: "bold",
  },
  textTransform: "uppercase",
  transition: "all 0.5s ease",
  zIndex: 4,
});

export const Art = () => {
  const [menu, setMenu] = useState();
  const [sousMenu, setSousMenu] = useState();
  const [currentData, setCurrentData] = useState(null);
  const { idParam } = useParams();
  const isFrancais = useLangue();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    MenuService.getMenu(MENU_ID_SITE.ART_PRODUIT_MALAGASY).then((res) => {
      setMenu(res.data.data);
    });
    MenuService.getAllSousMenu(MENU_ID_SITE.ART_PRODUIT_MALAGASY).then(
      (res) => {
        setSousMenu(res.data.data);
        setCurrentData(res.data.data[0]);
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (idParam && isFinite(idParam)) {
      toggleData(idParam);
    }
  }, [idParam]);

  const toggleData = async (id) => {
    let data = await MenuService.getAllSousMenu(
      MENU_ID_SITE.ART_PRODUIT_MALAGASY
    );
    data = data.data.data;
    const item = data.find((sm) => sm.id === Number(id));
    setCurrentData(item ? item : data[id]);
    const ID = item ? data.indexOf(item) : id;
    $(".toActive").removeClass("activeText");
    $(`.toActive:eq(${ID})`).addClass("activeText");
  };

  return (
    <Box>
      <Header
        backgUrl={menu?.images.length === 0 ? menuHeader : getImg(menu?.images)}
        headerTitle={isFrancais? menu?.titre : menu?.en_titre ?? menu?.titre }
      />
      <Box px={9}>
        <Box
          textAlign="center"
          pb={3}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Typography
            color={Couleur.bordeau}
            component="p"
            sx={{
              fontSize: { xs: "60px", sm: "100px" },
              lineHeight: { xs: "55px", sm: "100px" },
              fontFamily: "'Oleo Script', 'Times New Roman', serif",
            }}
          >
            {isFrancais?  menu?.sousTitre ?? menu?.titre : menu?.en_sousTitre ?? menu?.en_titre ?? menu?.sousTitre ?? menu?.titre}
          </Typography>
        </Box>
        <Box
          component="p"
          className="text-adjust"
          px="10%"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          {isFrancais? menu?.description : menu?.en_description ?? menu?.description }
        </Box>
      </Box>

      <Box
        px={9}
        pt={8}
        sx={{ mb: { xs: "42%", sm: "12%", md: "10%", lg: "8%" } }}
        position="relative"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <Grid container sx={{ height: "90%" }}>
          <Grid item lg={3}>
            <Stack mt="20%">
              {sousMenu?.map((s, index) => (
                <TextEvent key={index} onClick={() => toggleData(index)}>
                  <Typography
                    sx={{ fontSize: "28px" }}
                    className={`toActive ${index === 0 && "activeText"}`}
                    component="p"
                  >
                    {isFrancais? s.titre : s.en_titre ?? s.en_sousTitre ?? s.titre}
                  </Typography>
                </TextEvent>
              ))}
            </Stack>
            <Stack mt={9}>
              <Box
                className="eventDetail"
                sx={{
                  position: {
                    xs: "absolute",
                    sm: "absolute",
                    md: "absolute",
                    lg: "relative",
                  },
                  left: { sm: "5%", md: "10%", lg: "17%" },
                  height: "400px",
                  width: "400px",
                  zIndex: 5,
                }}
                p={4}
              >
                <Box
                  sx={{ fontSize: "30px", fontWeight: "bold" }}
                  className="pText"
                  component="p"
                >
                  {isFrancais? currentData?.titre : currentData?.en_titre ?? currentData?.en_sousTitre ?? currentData?.titre}
                </Box>{" "}
                <br />
                <Box sx={{ fontSize: "20px" }} component="p" className="pText">
                {isFrancais? currentData?.description : currentData?.en_description ?? currentData?.description }
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item sm={12} md={9} position="relative">
            <img
              src={getImg(currentData?.images)}
              height="100%"
              width="100%"
              alt="event"
            />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};
