import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import pplanService from "../../assets/images/notreEquipe.jpg";
import imageMenu from "../../assets/images/menuInformation.jpg";
import { Couleur, Header } from "../Header";
import { Box, Grid, Typography } from "@mui/material";

import "../../assets/scss/information.scss";
import { Contact } from "./Contact";

import { Footer } from "../Footer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { Stack } from "react-bootstrap";

export const Information = () => {
  const [open, setOpen] = useState(false);
  const [t, i18n] = useTranslation("common");


  useEffect(() => {
    AOS.init();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      <Backdrop
        px={9}
        sx={{
          color: Couleur.bordeau,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header backgUrl={imageMenu} headerTitle="Informations et contacts" />
      <Box px={9} mb={10}>
        <Box
          textAlign="center"
          pb={3}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Typography
            color={Couleur.bordeau}
            component="p"
            sx={{
              fontSize: { xs: "60px", sm: "100px" },
              lineHeight: { xs: "55px", sm: "100px" },
              fontFamily: "'Oleo Script', 'Times New Roman', serif",
            }}
          >
          {t("contact.Établissement 3 étoiles")}
          </Typography>
        </Box>
        <Box
          component="p"
          className="text-adjust"
          px="10%"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          {t("contact.paragraphes.p1")}
          <br />
          {t("contact.paragraphes.p2")}
        </Box>
      </Box>
      <Box px={9}>
        <Grid container>
          <Grid
            itspan
            md={7}
            position="relative"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <img
              src={pplanService}
              alt="image service"
              width="100%"
              height="100%"
            />
          </Grid>
          <Grid itspan md={5} data-aos="fade-left" data-aos-duration="800">
            <Box textAlign="center">
              <Typography
                component="p"
                sx={{
                  fontSize: { xs: "40px", sm: "50px" },
                  lineHeight: { xs: "55px", sm: "100px" },
                  fontFamily: "'Oleo Script', 'Times New Roman', serif",
                  color: Couleur.bordeau,
                }}
              >
             {t("contact.notre equipe")}
              </Typography>
            </Box>
            <Box component="ul" className="BoxInfo">
              <li className="listInfo">
                <strong>Directeur Hébergement </strong>
                <Grid container>
                  <Grid md="auto">
                    <Box component="a" href="#!">
                      {" "}
                      malalasoa@zomatel/{" "}
                    </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box>RAKOTONIRAINY Malalasoa/ </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> + 261 34 07 256 04</Box>
                  </Grid>
                </Grid>
              </li>
              <li className="listInfo">
                <strong>Directeurs Restaurations </strong>
                <Grid container>
                  <Grid md="auto">
                    <Box component="a" href="#!">
                      omega@zomatel.com/{" "}
                    </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> + 261 34 07 256 05</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid md="auto">
                    <Box component="a" href="#!">
                      ayoub@zomatel.com/{" "}
                    </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> + 261 34 07 255 26</Box>
                  </Grid>
                </Grid>
              </li>
              <li className="listInfo">
                <strong>Chef de Réception </strong>
                <Grid container>
                  <Grid md="auto">
                    <Box component="a" href="#!">
                      zomatel@zomatel.com{" "}
                    </Box>
                  </Grid>
                </Grid>
              </li>
              <li className="listInfo">
                <strong>Réservation </strong>
                <Grid container>
                  <Grid md="auto">
                    <Box component="a" href="#!">
                      resa@zomatel.com/{" "}
                    </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> + 261 34 07 255 27/ </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> + 261 20 75 507 97/ </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> + 261 32 03 604 63</Box>
                  </Grid>
                </Grid>
              </li>
              <li className="listInfo">
                <strong>
                  Service Communication, Commercial & Evènementiel
                </strong>
                <Grid container>
                  <Grid md="auto">
                    <Box component="a" href="#!">
                      harimani@zomatel.com/{" "}
                    </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> + 261 34 07 255 04</Box>
                  </Grid>
                </Grid>
              </li>
              <li className="listInfo">
                <strong>Snack-bar Zomagrill </strong> <br/>
                <span> + 261 34 07 277 02 </span>
              </li>
              <li className="listInfo">
                <strong>Restaurant L'entrecôte du Zoma </strong> <br />
                <span> + 261 34 07 256 02 </span>
              </li>
              <li className="listInfo">
                <strong>Service comptabilité </strong>
                <Grid container>
                  <Grid md="auto">
                    <Box component="a" href="#!">
                      vanessa@zomatel.com/{" "}
                    </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> +261 34 07 255 24</Box>
                  </Grid>
                </Grid>
              </li>
              <li className="listInfo">
                <strong>Service Recouvrement </strong>
                <Grid container>
                  <Grid md="auto">
                    <Box component="a" href="#!">
                      anna@zomatel.com/{" "}
                    </Box>
                  </Grid>
                  <Grid md="auto">
                    <Box> + 261 34 07 277 65</Box>
                  </Grid>
                </Grid>
              </li>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Contact setOpen={setOpen} />
      <Footer />
    </Box>
  );
};
