import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { Couleur } from "../../Header";
import GalerieService from "../../services/GalerieService";
import Swal from "sweetalert2";
import { baseUrl } from "../../services/module";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import { useLocation, useParams } from "react-router-dom";
import MenuService from "../../services/MenuService";
import ServiceService from "../../services/ServiceService";

const SuccessNotification = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const SelectImage = () => {
  const { idMenu } = useParams();
  const location = useLocation();
  const [menu, setMenu] = useState();
  const [principal, setPrincipal] = useState();
  const [imagesNew, setImagesNew] = useState(null);
  const [number, setNumber] = useState(0);

  useEffect(() => {
    idMenu && getData(idMenu);
  }, [idMenu]);

  const getData = (idMenu) => {
    let arrayPath = location.pathname.split("/");

    arrayPath.includes("services")
      ? ServiceService.getService(idMenu).then((res) => {
          setMenu(res.data.data);
          setNumber(res.data.data.images.length);
        })
      : arrayPath.includes("sous_menu")
      ? MenuService.getSousMenu(idMenu).then((res) => {
          setMenu(res.data.data);
          setNumber(res.data.data.images.length);
        })
      : MenuService.getMenu(idMenu).then((res) => {
          setMenu(res.data.data);
          setNumber(res.data.data.images.length);
        });
  };

  const imageRef = useRef();
  const onChangePhoto = (e) => {
    const fileList = [];
    for (let i = 0; i < e.target.files.length; i++) {
      fileList.push(e.target.files[i]);
    }
    setImagesNew(fileList);
  };

  const onAdd = (e) => {
    e.preventDefault();
    if (imagesNew) {
      menu.item_menus
        ? ServiceService.storeServiceImage(
            { imageContentMenu: imagesNew },
            menu.id
          ).then((res) => {
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
            getData(idMenu);
            imageRef.current.value = null;
            setImagesNew(null);
          })
        : MenuService.storeMenuImage(
            { imageContentMenu: imagesNew },
            menu.id
          ).then((res) => {
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
            getData(idMenu);
            imageRef.current.value = null;
            setImagesNew(null);
          });
    }
  };

  const onCheck = (id, value) => {
    console.log(id, value);
    MenuService.setPrincipale({ estPrincipal: `${value}` }, id).then((res) => {
      getData(idMenu);
    });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Suppression",
      text: "Cette action est irreversible, continuer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#37474F",
      confirmButtonText: "oui, continuer",
      cancelButtonText: "annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        menu.item_menus
          ? ServiceService.deleteServiceImage(id)
              .then((res) => {
                getData(idMenu);
                SuccessNotification.fire({
                  icon: "success",
                  title: res.data.message,
                });
              })
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  title: "Oups...",
                  text: "Opération non effectuée",
                });
              })
          : MenuService.deleteMenuImage(id)
              .then((res) => {
                getData(idMenu);
                SuccessNotification.fire({
                  icon: "success",
                  title: res.data.message,
                });
              })
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  title: "Oups...",
                  text: "Opération non effectuée",
                });
              });
      }
    });
  };

  return (
    <Box>
      <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
        <Box
          component="ol"
          className="breadcrumb mb-4"
          sx={{
            backgroundColor: Couleur.jaune,
            p: 2,
            width: "100%",
            borderRadius: "4px",
          }}
        >
          <li className="breadcrumb-item">
            <a href="#!">
              <label>Page actuelle :</label>
            </a>
          </li>
          <li className="breadcrumb-item">
            <a href="#!">Dashboard</a>
          </li>
          {menu?.item_menus && (
            <li className="breadcrumb-item active">services</li>
          )}
          <li className="breadcrumb-item active">{menu?.titre}</li>
          <li className="breadcrumb-item active">images</li>
        </Box>
      </Box>

      <Box>
        <Card sx={{ maxWidth: 350, ml: 5 }}>
          <Box component="form" onSubmit={(e) => onAdd(e)}>
            <CardActions>Nouveau</CardActions>
            <CardContent>
              <Box
                mt={3}
                component="input"
                id="photo"
                type="file"
                accept="image/*"
                multiple
                ref={imageRef}
                onChange={(e) => onChangePhoto(e)}
                sx={{
                  fontFamily: "serif, arial",
                  fontSize: "18px",
                  color: Couleur.bordeau,
                  cursor: "pointer",
                }}
              ></Box>
            </CardContent>
            <CardActions>
              <Button variant="contained" type="submit">
                ajouter
              </Button>
            </CardActions>
          </Box>
        </Card>
      </Box>
      <Box px={5} my={5}>
        <Box component="p" fontSize="22px">
          {number} {number < 1 ? "image " : "images "}
        </Box>
        <Grid container spacing={3}>
          {menu?.images.length > 0 &&
            menu.images.map((image) => (
              <Grid
                key={image.id}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ maxHeight: 350, position: "relative" }}
              >
                <Box
                  component="img"
                  src={baseUrl + image.libelle}
                  sx={{ width: "100%", height: "100%" }}
                  alt="image"
                />
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "space-between",
                    bottom: "0px",
                    width: "95%",
                  }}
                >
                  <Box>
                    <Checkbox
                      sx={{ color: "white" }}
                      onChange={() =>
                        onCheck(image.id, image.est_principale === 1 ? 0 : 1)
                      }
                      checked={image.est_principale === 1 ? true : false}
                    />
                  </Box>
                  <Box>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => onDelete(image.id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};
