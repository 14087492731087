import { Box, Button, Card, CardActions, CardContent, Divider, Grid, IconButton, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { Couleur } from '../../Header'
import Swal from "sweetalert2";
import DeviseService from '../../services/DeviseService';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const SuccessNotification = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

export const Devise = () => {
  const [listDevise, setListDevise] = useState([])
  const [valeur, setValeur] = useState('')
  const [devise, setDevise] = useState('')
  const [idToUpdate, setIdToUpdate] = useState(null)
  const [title, setTitle] = useState('NOUVEAU')

  const nouveauAction = useRef()
  const modifierAction = useRef()

  useEffect(() => {
    DeviseService.listDevise()
      .then(res => {
        setListDevise(res.data.data)
      })
  }, [])

  const clearValue = () => {
    setValeur('')
    setDevise('')
    setTitle('NOUVEAU')
    setIdToUpdate(null)
    nouveauAction.current.hidden = false
    modifierAction.current.hidden = true
  }

  const onSubmit = e => {
    e.preventDefault()

    if (valeur && devise) {
      if (!idToUpdate) { // ajouter
        DeviseService.storeDevise({ 'libelle': devise, 'valeur_ariary': valeur })
          .then(res => {
            SuccessNotification.fire({
              icon: 'success',
              title: res.data.message
            });
            DeviseService.listDevise()
              .then(res => {
                setListDevise(res.data.data);
              });
            clearValue()
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Oups...',
              text: err.response.data.message,
            });
          });
      }
      else { // modifier
        DeviseService.updateDevise({ 'libelle': devise, 'valeur_ariary': valeur }, idToUpdate)
          .then(res => {
            SuccessNotification.fire({
              icon: 'success',
              title: res.data.message
            });
            DeviseService.listDevise()
              .then(res => {
                setListDevise(res.data.data);
              });
            clearValue()
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Oups...',
              text: err.response.data.message,
            });
          });
      }
    }
  }

  const onEdit = (id) => {
    const item = listDevise.find(ls => ls.id === id)
    if (item) {
      setDevise(item.libelle)
      setValeur(item.valeur_ariary)
      setTitle('MODIFIER')
      setIdToUpdate(id)

      nouveauAction.current.hidden = true
      modifierAction.current.hidden = false
    }
  }

  const onCancel = () => {
    clearValue()
  }

  const onDelete = (id) => {
    Swal.fire({
      title: 'Suppression',
      text: "Cette action est irreversible, continuer?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#37474F',
      confirmButtonText: 'oui, continuer',
      cancelButtonText: 'annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        DeviseService.deleteDevise(id)
          .then(res => {
            DeviseService.listDevise()
              .then(res => {
                setListDevise(res.data.data);
              });
            clearValue()
            SuccessNotification.fire({
              icon: 'success',
              title: res.data.message
            });
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Oups...',
              text: 'Opération non effectuée',
            });
          })
      }
    })
  }

  return (
    <Box>
      <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
        <Box component='ol' className="breadcrumb mb-4" sx={{ backgroundColor: Couleur.jaune, p: 2, width: '100%', borderRadius: '4px' }}>
          <li className="breadcrumb-item"><a href="#!"><label>Page actuelle :</label></a></li>
          <li className="breadcrumb-item"><a href="#!">Dashboard</a></li>
          <li className="breadcrumb-item active">Devise</li>
        </Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <Card sx={{ maxWidth: 350, ml: 5 }}>
            <Box component='form' onSubmit={(e) => onSubmit(e)}>
              <CardActions>{title}</CardActions>
              <CardContent>
                <Stack spacing={2.5}>
                  <TextField label="Devise" value={devise} onChange={e => setDevise(e.target.value)} variant="standard" />
                  <TextField label="Valeur en Ar" value={valeur} onChange={e => setValeur(e.target.value)} variant="standard" />
                </Stack>
              </CardContent>
              <CardActions ref={nouveauAction}>
                <Button variant='contained' type='submit'>ajouter</Button>
              </CardActions>
              <CardActions ref={modifierAction} hidden>
                <Button variant='contained' color='warning' type='submit'>modifier</Button>
                <Button variant='contained' onClick={onCancel} sx={{ backgroundColor: '#eeeeee', color: 'black', '&:hover': { backgroundColor: '#eeefff' } }}>annuler</Button>
              </CardActions>
            </Box>
          </Card>
        </Grid>
        <Grid item>
          {
            listDevise.length > 0 &&
            <Card sx={{ width: 350 }}>
              <CardActions sx={{ backgroundColor: 'black', color: 'white' }}>Nos devises</CardActions>
              <CardContent>
                <Stack>
                  {
                    listDevise.map((currency, index) => (
                      <>
                        {index !== 0 && <Divider />}
                        <Box key={currency.id} display='flex' justifyContent='space-between'>
                          <Box pt={1}>
                            1 <b>{currency.libelle}</b> = <u>{currency.valeur_ariary}</u> <b>Ar</b>
                          </Box>
                          <Box>
                            <IconButton aria-label="delete" size="sm" onClick={() => onEdit(currency.id)}>
                              <EditIcon color='warning' />
                            </IconButton>
                            <IconButton aria-label="delete" size="sm" onClick={() => onDelete(currency.id)}>
                              <DeleteIcon color='error' />
                            </IconButton>
                          </Box>
                        </Box>
                      </>
                    ))
                  }
                </Stack>
              </CardContent>
            </Card>
          }

        </Grid>
      </Grid>
    </Box>
  )
}
