import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyIcon from "@mui/icons-material/Key";
import logo from "../../assets/images/logo.png";
import { Couleur } from "../Header";
import { YellowButton } from "../tools/Buttons";
import { useNavigate } from "react-router-dom";
import Auth from "../services/Auth";
import Swal from "sweetalert2";

export const Login = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [etape, setEtape] = useState(1);
  const [load, setLoad] = useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    localStorage.getItem("user_token") && navigate("/admin/dashboard");
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const user_data = {
      email: email,
      password: password,
    };
    setLoad(true);
    if (etape === 1) {
      Auth.newLogin({ email: email })
        .then((res) => {
          Swal.fire({
            icon: "info",
            title: "Information",
            text: res.data.message,
          });
          setEtape(2);
          setLoad(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oups...",
            text: "Utilisateur non reconnu",
          });
        });
    } else {
      Auth.login(user_data)
        .then((res) => {
          localStorage.setItem("user_email", res.data.data.email);
          localStorage.setItem("user_token", res.data.data.token);
          navigate("/admin/dashboard");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oups...",
            text: "Email ou mot de passe incorrect",
          });
          setLoad(false);
        });
    }
  };
  return (
    <Box display="flex" justifyContent="center" pt="10%">
      <Grid container width="1000px">
        <Grid item md={6} position="relative">
          <img src={logo} alt="logo zomatel" width="100%" />
        </Grid>
        <Grid item md={6} sx={{ position: "relative" }}>
          <Box
            className="family"
            sx={{
              position: "absolute",
              top: "-25px",
              left: "28%",
              color: "white",
              backgroundColor: Couleur.bordeau,
              px: 4,
              py: 1,
              fontSize: "22px",
              borderTopLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            AUTHENTIFICATION
          </Box>
          <Paper elevation={3} sx={{ height: "100%", pt: 9, pl: 4 }}>
            <Box component="form" onSubmit={onSubmit}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <AccountCircle
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  type="email"
                  sx={{ width: "80%" }}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  variant="standard"
                  disabled={etape === 1 ? false : true}
                />
              </Box>
              {etape !== 1 && (
                <Box mt={3} sx={{ display: "flex", alignItems: "flex-end" }}>
                  <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <TextField
                    type="password"
                    sx={{ width: "80%" }}
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    variant="standard"
                  />
                </Box>
              )}
              {load && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                  <CircularProgress sx={{ color: Couleur.bordeau }} />
                </Box>
              )}
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  bottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  pr: 7,
                }}
              >
                <Box>
                  <Button
                    onClick={() => setEtape(1)}
                    variant="contained"
                    disabled={etape === 1 ? true : false}
                  >
                    Précédent
                  </Button>
                </Box>
                <Box>
                  <YellowButton
                    type="submit"
                    variant="contained"
                    h="35px"
                    widthed={false}
                  >
                    {etape === 1 ? "Recevoir le mot de passe" : "Se connecter"}
                  </YellowButton>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
