import styled from "@emotion/styled";
import { Button } from "@mui/material";


export const YellowButton = styled(Button)(({widthed, w, h, fs})=>({
    height: `${h? h : '60px'}`,
    width: `${widthed === false? 'none' : w ? w : '200px'}`,
    backgroundColor: '#F2D860',
    fontSize:`${fs? fs : '18px'}`,
    fontFamily:"'Glacial Indiferrence', serif",
    color: '#850000',
    textTransform:'uppercase',
    '&:hover': { color: '#F2D860', backgroundColor: '#850000' }
}))