import { Grid, Typography } from "@mui/material";

import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";

import $ from "jquery";

import AOS from "aos";
import "aos/dist/aos.css";

// ---------- PHOTOS ----------------
import photoChambre from "../../assets/images/chambre.jpg";
import photoEvenement from "../../assets/images/nosEvenements.jpg";

import photoSnack from "../../assets/images/snack.jpg";
import photoBienEtre from "../../assets/images/bienEtre.jpg";
import photoFianarantsoa from "../../assets/images/Fianarantsoa.jpg";
import photoZomatel from "../../assets/images/WhatsApp Image 2023-03-13 at 15.19.31.jpg";
import photoWifi from "../../assets/images/iconService/wifi.png";
import photoClime from "../../assets/images/iconService/air-conditioner.png";
import photoSupport from "../../assets/images/iconService/online-support.png";
import photoIconBienEtre from "../../assets/images/iconService/bath-towel.png";
import photoParking from "../../assets/images/iconService/car-parking.png";
import { useTranslation } from "react-i18next";

// ----------------------------------

// -------------- ICONS ----------------
import StarIcon from "@mui/icons-material/Star";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// -------------------------------------
import "../../assets/scss/content.scss";
import { Couleur } from "../Header";
import { YellowButton } from "../tools/Buttons";
import GaleriePart from "./content-part/GaleriePart";
import { useNavigate } from "react-router-dom";
import MenuService from "../services/MenuService";
import { MENU_ID_SITE } from "../services/menuID";
import { useLangue } from "../../i18n";

const BackgroundBox = styled(Box)(({ urls, h, w }) => ({
  backgroundImage: `url(${urls})`,
  height: `${h ? h : "60vh"}`,
  width: `${w ? w : "80vw"}`,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const Text = styled(Typography)(({ f }) => ({
  fontSize: `${f ? f : "24px"}`,
}));

export const FullBox = styled(Box)(({ h = "96vh" }) => ({
  height: h,
}));

export const Content = (props) => {
  const [gastronomies, setGastronomies] = useState(null);
  const [bienEtreLoisirs, setBienEtreLoisirs] = useState(null);
  const [evenements, setEvenements] = useState(null);
  const [t, i18n] = useTranslation("common");
  const isFrancais = useLangue();


  let navigate = useNavigate();
  useEffect(() => {
    getData();
    AOS.init();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    $("body").css({
      overflow: "visible",
    });
  }, []);

  const getData = async () => {
    let gastros = await MenuService.getAllSousMenu(MENU_ID_SITE.GASTRONOMIE);
    setGastronomies(gastros?.data?.data);
    let biens = await MenuService.getAllSousMenu(MENU_ID_SITE.BIEN_ETRE_LOISIR);
    setBienEtreLoisirs(biens?.data?.data);
    let evenements = await MenuService.getAllSousMenu(
      MENU_ID_SITE.EVENEMENT_SEMINAIRE
    );
    setEvenements(evenements?.data?.data);
  };

  return (
    <Box>
      <Box px={9} data-aos="fade-up" data-aos-duration="1500" mb="6%">
        <Box display="flex" justifyContent="center" data-aos="fade-up">
          <BackgroundBox urls={photoFianarantsoa} h="100%" w="90vw">
            <Grid container py={8}>
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={7}
                position="relative"
                p={4}
              >
                <Box
                  className="cadreBox"
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    opacity: ".8",
                  }}
                >
                  <Box p={4}>
                    <Box textAlign="center" mb={5}>
                      <Typography
                        color={Couleur.bordeau}
                        sx={{
                          fontSize: {
                            xs: "45px !important",
                            sm: "45px !important",
                            md: "60px !important",
                          },
                        }}
                        component="p"
                        className="pTitle"
                      >
                        {t("landingPage.part1.title.h1")}
                      </Typography>
                      <Typography
                        color="gray"
                        component="p"
                        sx={{
                          fontSize: {
                            xs: "25px !important",
                            sm: "25px !important",
                            md: "30px !important",
                          },
                        }}
                        className="pText"
                      >
                        <b>{t("landingPage.part1.title.h2")}</b>
                      </Typography>
                    </Box>

                    <Box>
                      <Text component="p" className="pText">
                        {t("landingPage.part1.paragraphes.p1")}
                      </Text>
                    </Box>
                    <Box>
                      <Text component="p" className="pText" mt={2}>
                        {t("landingPage.part1.paragraphes.p2")}
                      </Text>
                    </Box>
                    <Box>
                      <Text component="p" className="pText" mt={2}>
                        {t("landingPage.part1.paragraphes.p3")}
                        <br />
                        {t("landingPage.part1.paragraphes.p4")}{" "}
                        <StarIcon fontSize="small" />
                        <StarIcon fontSize="small" />
                        <StarIcon fontSize="small" />
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </BackgroundBox>
        </Box>
      </Box>

      <Box px={9} mb="6%">
        <Grid container>
          <Grid
            item
            md={12}
            lg={5}
            data-aos="fade-right"
            data-aos-duration="1500"
            sx={{ position: "relative" }}
          >
            {/* <Box >
                            <Box > */}
            <Box
              sx={{
                backgroundColor: Couleur.jaune,
                borderRadius: "10px",
                height: "100%",
                position: "relative",
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Box
                  sx={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "white",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "white",
                  }}
                ></Box>
              </Box>
              <Box textAlign="center" mt={6}>
                <Typography
                  color={Couleur.bordeau}
                  component="span"
                  sx={{ lineHeight: "55px" }}
                  className="pTitle"
                >
                  {t("landingPage.part2.title")}
                </Typography>
              </Box>
              <Box p={5}>
                <Text component="p" className="pText">
                  {t("landingPage.part2.paragraphes.p1")}
                </Text>
                <br />
                <Text component="p" className="pText">
                  {t("landingPage.part2.paragraphes.p2")}
                </Text>
                <br />
                <Text component="p" className="pText">
                  {t("landingPage.part2.paragraphes.p3")}
                </Text>
              </Box>
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  right: 0,
                  bottom: 0,
                  position: "absolute",
                  backgroundColor: "white",
                }}
              ></Box>
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  left: 0,
                  bottom: 0,
                  position: "absolute",
                  backgroundColor: "white",
                }}
              ></Box>
            </Box>
            {/* </Box>
                        </Box> */}
          </Grid>
          <Grid
            item
            md={12}
            lg={7}
            px={2}
            mt={{ md: 2, lg: 0 }}
            data-aos="fade-left"
            data-aos-duration="1500"
            sx={{ height: "auto", position: "relative" }}
          >
            <Box
              component="img"
              src={photoZomatel}
              alt="image"
              position="relative"
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                height: "100%",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box px={9} mb="6%">
        <Grid container>
          <Grid
            item
            px={2}
            md={12}
            lg={7}
            data-aos="fade-right"
            data-aos-duration="1500"
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            <img src={photoChambre} height="100%" width="100%" />
          </Grid>

          <Grid
            item
            md={12}
            lg={5}
            display="flex"
            justifyContent="center"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <Box
              sx={{
                display: { sm: "none", md: "none", lg: "block" },
              }}
            >
              <Box px={2} pt={7}>
                <Box textAlign="center" mt={8}>
                  <Typography
                    color={Couleur.bordeau}
                    component="p"
                    className="pTitle"
                  >
                    {t("landingPage.part3.title")}
                  </Typography>
                </Box>
                <Box p={4}>
                  <Text component="p" className="pText" f={"28px"}>
                    {t("landingPage.part3.paragraphes.p1")}
                  </Text>
                </Box>
              </Box>
              <Box mt={12} textAlign="center">
                <YellowButton
                  size="large"
                  endIcon={<NavigateNextIcon />}
                  onClick={() => navigate("/chambre")}
                  widthed={false}
                >
                {t("btn.decouvreNosChambres")}
                </YellowButton>
              </Box>
            </Box>
            {/* View For sm */}
            <BackgroundBox
              urls={photoChambre}
              h="80vh"
              sx={{
                display: { xs: "none", sm: "block", md: "block", lg: "none" },
              }}
            >
              <Box>
                <Box px={2} pt={7}>
                  <Box textAlign="center" mt={8}>
                    <Typography
                      color={Couleur.bordeau}
                      component="p"
                      className="pTitle"
                    >
                      {t("landingPage.part3.title.p1")}
                    </Typography>
                  </Box>
                  <Box p={4}>
                    <Text component="p" className="pText" f={"28px"}>
                      {t("landingPage.part3.paragraphes.p1")}
                    </Text>
                  </Box>
                </Box>
                <Box mt={12} textAlign="center">
                  <YellowButton
                    size="large"
                    endIcon={<NavigateNextIcon />}
                    onClick={() => navigate("/chambre")}
                    widthed={false}
                  >
                    Découvrir nos chambres
                  </YellowButton>
                </Box>
              </Box>
            </BackgroundBox>
          </Grid>
        </Grid>
      </Box>

      <Box
        px={9}
        data-aos="fade-up"
        data-aos-duration="500"
        position="relatvie"
        mb="6%"
      >
        <Box display="flex" justifyContent="center">
          <BackgroundBox urls={photoSnack} p={3} h="100%" w="90vw">
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={8}
                sx={{ pl: { xs: 2, sm: 6, md: 15, lg: 15 } }}
              >
                <Typography
                  variant="h4"
                  color={Couleur.bordeau}
                  className="pTitle"
                  component="p"
                >
                  {t("landingPage.part4.title")}
                </Typography>
              </Grid>
              <Grid item md={12} lg={5}>
                <Box ml={8}>
                  <Box
                    mt={7}
                    ml={8}
                    sx={{ maxWidth: "600px", textAlign: "justify" }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { sm: "22px", md: "28px" },
                        fontWeight: "light",
                      }}
                      component="div"
                      className="pText"
                    >
                      {t("landingPage.part4.paragraphes.p1")}
                    </Typography>
                  </Box>
                </Box>

                {gastronomies && (
                  <Box
                    ml={{ xs: "5%", sm: "25%", md: "25%", lg: "25%" }}
                    mt="7%"
                  >
                    <Box>
                      <YellowButton
                        fs="22px"
                        sx={{
                          textTransform: "capitalize",
                          width: "300px !important",
                        }}
                        onClick={() =>
                          navigate(`/gastronomie/${gastronomies[0]?.id}`)
                        }
                        endIcon={<NavigateNextIcon />}
                      >
                        { isFrancais? gastronomies[0]?.titre.substring(2) : gastronomies[0]?.en_titre}
                      </YellowButton>
                    </Box>
                    <Box my={6}>
                      <YellowButton
                        fs="22px"
                        sx={{
                          textTransform: "capitalize",
                          width: "300px !important",
                        }}
                        onClick={() =>
                          navigate(`/gastronomie/${gastronomies[1]?.id}`)
                        }
                        endIcon={<NavigateNextIcon />}
                      >
                        {isFrancais ? gastronomies[1]?.titre : gastronomies[1]?.en_titre}
                      </YellowButton>
                    </Box>
                    <Box>
                      <YellowButton
                        fs="22px"
                        sx={{
                          textTransform: "capitalize",
                          width: "300px !important",
                        }}
                        onClick={() =>
                          navigate(`/gastronomie/${gastronomies[2]?.id}`)
                        }
                        endIcon={<NavigateNextIcon />}
                      >
                        { isFrancais? gastronomies[2]?.titre : gastronomies[2]?.en_titre}
                      </YellowButton>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </BackgroundBox>
        </Box>
      </Box>

      <Box px={9} mb="6%">
        <Grid container>
          <Grid
            item
            mt={5}
            md={12}
            lg={5}
            display="flex"
            justifyContent="center"
            data-aos="fade-right"
            data-aos-duration="1500"
            height="auto"
          >
            <Box
              sx={{
                display: { xs: "block", sm: "none", md: "none", lg: "block" },
              }}
            >
              <Box px={2} pt={5}>
                <Box textAlign="center" px={3}>
                  <Typography
                    color={Couleur.bordeau}
                    component="p"
                    className="pTitle"
                    sx={{ lineHeight: "55px" }}
                  >
                    {t("landingPage.part5.title")}
                  </Typography>
                </Box>
                <Box p={4}>
                  <Text component="p" className="pText" f={"28px"}>
                    {t("landingPage.part5.paragraphes.p1")}
                  </Text>
                </Box>
              </Box>
              {bienEtreLoisirs && (
                <Box align="center">
                  <Box mt="7%">
                    <Box>
                      <YellowButton
                        fs="22px"
                        sx={{
                          textTransform: "capitalize",
                          width: "300px !important",
                        }}
                        onClick={() =>
                          navigate(`/bien-etre?#${bienEtreLoisirs[0].id}`)
                        }
                        endIcon={<NavigateNextIcon />}
                      >
                        { isFrancais? bienEtreLoisirs[0].titre.split(" ")[0] : bienEtreLoisirs[0].en_titre.split(" ")[1]}
                      </YellowButton>
                    </Box>
                    <Box my={6}>
                      <YellowButton
                        fs="22px"
                        sx={{
                          textTransform: "capitalize",
                          width: "300px !important",
                        }}
                        onClick={() =>
                          navigate(`/bien-etre?#${bienEtreLoisirs[1].id}`)
                        }
                        endIcon={<NavigateNextIcon />}
                      >
                        {isFrancais? bienEtreLoisirs[1].titre.split(" ")[0] : bienEtreLoisirs[1].en_titre.split(" ")[0]}
                      </YellowButton>
                    </Box>
                    <Box>
                      <YellowButton
                        fs="22px"
                        sx={{
                          textTransform: "capitalize",
                          width: "300px !important",
                        }}
                        onClick={() =>
                          navigate(`/bien-etre?#${bienEtreLoisirs[2].id}`)
                        }
                        endIcon={<NavigateNextIcon />}
                      >
                        {isFrancais? bienEtreLoisirs[2].titre.split(" ")[0] :bienEtreLoisirs[2].en_titre.split(" ")[0] }
                      </YellowButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <BackgroundBox
              urls={photoBienEtre}
              h="80vh"
              sx={{
                display: { xs: "none", sm: "block", md: "block", lg: "none" },
              }}
            >
              <Box px={2} pt={5}>
                <Box textAlign="center" px={3}>
                  <Typography
                    color={Couleur.bordeau}
                    component="p"
                    className="pTitle"
                    sx={{ lineHeight: "55px" }}
                  >
                    {t("landingPage.part5.title")}
                  </Typography>
                </Box>
                <Box p={4}>
                  <Text component="p" className="pText" f={"28px"}>
                    {t("landingPage.part5.paragraphes.p1")}
                  </Text>
                </Box>
              </Box>
              <Box align="center">
                <Box mt="7%">
                  <Box>
                    <YellowButton
                      fs="22px"
                      sx={{
                        textTransform: "capitalize",
                        width: "300px !important",
                      }}
                      onClick={() => navigate("/bien-etre/massage")}
                      endIcon={<NavigateNextIcon />}
                    >
                      Massage
                    </YellowButton>
                  </Box>
                  <Box my={6}>
                    <YellowButton
                      fs="22px"
                      sx={{
                        textTransform: "capitalize",
                        width: "300px !important",
                      }}
                      onClick={() => navigate("/bien-etre/detente")}
                      endIcon={<NavigateNextIcon />}
                    >
                      Détente
                    </YellowButton>
                  </Box>
                  <Box>
                    <YellowButton
                      fs="22px"
                      sx={{
                        textTransform: "capitalize",
                        width: "300px !important",
                      }}
                      onClick={() => navigate("/bien-etre/loisir")}
                      endIcon={<NavigateNextIcon />}
                    >
                      Loisir
                    </YellowButton>
                  </Box>
                </Box>
              </Box>
            </BackgroundBox>
          </Grid>

          <Grid
            item
            px={2}
            lg={7}
            data-aos="fade-left"
            data-aos-duration="1500"
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
              height: "auto",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src={photoBienEtre}
              alt="image"
              sx={{ height: "100%", width: "100%" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        px={9}
        data-aos="fade-up"
        data-aos-duration="1500"
        position="relative"
        mb="6%"
      >
        <Box display="flex" justifyContent="center">
          <BackgroundBox urls={photoEvenement} p={3} h="100%" w="90vw">
            <Grid container>
              <Grid item md={12} lg={5} pb={8}>
                <Box p={2}>
                  <Box mt={8} ml={8}>
                    <Typography
                      variant="h4"
                      color={Couleur.bordeau}
                      className="pTitle"
                      component="p"
                    >
                      {t("landingPage.part6.title")}
                    </Typography>
                  </Box>
                  <Box
                    mt={7}
                    ml={8}
                    sx={{ maxWidth: "600px", textAlign: "justify" }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { sm: "22px", md: "28px" },
                        fontWeight: "light",
                      }}
                      component="div"
                      className="pText"
                    >
                      {t("landingPage.part6.paragraphes.p1")}
                    </Typography>
                  </Box>
                </Box>
                {evenements && (
                  <Box mt={8} sx={{ position: "relative", ml: 4 }}>
                    <Stack
                      direction={{
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      }}
                      spacing={{ xs: 4, sm: 4, md: 7, lg: 7 }}
                    >
                      <Box display="flex" justifyContent="center">
                        <YellowButton
                          w="250px"
                          fs="22px"
                          endIcon={<NavigateNextIcon />}
                          onClick={() =>
                            navigate(`/evenement-seminaire?${evenements[0].id}`)
                          }
                        >
                          {isFrancais? evenements[0].titre.split(" ")[0] : evenements[0].en_titre.split(" ")[0]}
                        </YellowButton>
                      </Box>
                      <Box display="flex" justifyContent="center">
                        <YellowButton
                          w="250px"
                          fs="22px"
                          endIcon={<NavigateNextIcon />}
                          onClick={() =>
                            navigate(`/evenement-seminaire?${evenements[1].id}`)
                          }
                        >
                          {isFrancais? evenements[1].titre.split(" ")[0] : evenements[1].en_titre.split(" ")[0]}
                        </YellowButton>
                      </Box>
                    </Stack>
                    <Stack
                      direction={{
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      }}
                      spacing={{ xs: 4, sm: 4, md: 7, lg: 7 }}
                      mt={4}
                    >
                      <Box display="flex" justifyContent="center">
                        <YellowButton
                          w="250px"
                          fs="22px"
                          endIcon={<NavigateNextIcon />}
                          onClick={() =>
                            navigate(`/evenement-seminaire?${evenements[2].id}`)
                          }
                        >
                          {isFrancais? evenements[2].titre.split(" ")[0] : evenements[2].en_titre}
                        </YellowButton>
                      </Box>
                      <Box display="flex" justifyContent="center">
                        <YellowButton
                          w="250px"
                          fs="22px"
                          endIcon={<NavigateNextIcon />}
                          onClick={() =>
                            navigate(`/evenement-seminaire?${evenements[3].id}`)
                          }
                        >
                          {isFrancais ? evenements[3].titre.split(" ")[0] : evenements[3].en_titre.split(" ")[0]}
                        </YellowButton>
                      </Box>
                    </Stack>
                  </Box>
                )}
              </Grid>
            </Grid>
          </BackgroundBox>
        </Box>
      </Box>

      <Box data-aos="zoom-in" data-aos-duration="1500" mb="6%">
        <Box display="flex" justifyContent="center">
          <Box>
            <Typography
              color={Couleur.bordeau}
              sx={{
                fontSize: { sm: "40px !important", md: "60px !important" },
              }}
              component="p"
              className="pTitle"
            >
              À votre service
            </Typography>
          </Box>
        </Box>

        <Box mt={2} display="flex" justifyContent="center" pt={3}>
          <Paper elevation={8}>
            <Grid
              container
              justifyContent="center"
              textAlign="center"
              pt={8}
              sx={{ width: "60vw" }}
            >
              <Grid item mb={10} xs={6} sm={4}>
                <Stack direction="column" sx={{ alignItems: "center" }}>
                  <img src={photoSupport} height="70px" alt="SUPPORT" />
                  <Typography mt={1} component="p">
                    ACCUEIL 24H/24
                  </Typography>
                </Stack>
              </Grid>
              <Grid item mb={10} xs={6} sm={4}>
                <Stack direction="column" sx={{ alignItems: "center" }}>
                  <img src={photoWifi} height="70px" alt="WIFI" />
                  <Typography mt={1} component="p">
                    WIFI GRATUIT
                  </Typography>
                </Stack>
              </Grid>
              <Grid item mb={10} xs={6} sm={4}>
                <Stack direction="column" sx={{ alignItems: "center" }}>
                  <img src={photoClime} height="70px" alt="CLIMATISATION" />
                  <Typography mt={1} component="p">
                    CLIMATISATION
                  </Typography>
                </Stack>
              </Grid>
              <Grid item mb={10} xs={6} sm={4}>
                <Stack direction="column" sx={{ alignItems: "center" }}>
                  <img src={photoParking} height="70px" alt="PARKING" />
                  <Typography mt={1} component="p">
                    PARKING PRIVÉ
                  </Typography>
                </Stack>
              </Grid>
              <Grid item mb={10} xs={6} sm={4}>
                <Stack direction="column" sx={{ alignItems: "center" }}>
                  <img src={photoIconBienEtre} height="70px" alt="BIEN-ETRE" />
                  <Typography mt={1} component="p">
                    BIEN-ETRE
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        <Box textAlign="center" mt={4}>
          <YellowButton
            size="large"
            widthed={false}
            onClick={() => navigate("/service")}
          >
            tous nos services
          </YellowButton>
        </Box>
      </Box>

      <Box data-aos="fade-up" data-aos-duration="1500" px={9}>
        <Box textAlign="center">
          <Typography color={Couleur.bordeau} component="p" className="pTitle">
            Galerie photo
          </Typography>
        </Box>
        <GaleriePart />
      </Box>
    </Box>
  );
};
