import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";

import photoChambre from "../../../../assets/images/chambre.jpg";
import { Couleur } from "../../../Header";
import { baseUrl } from "../../../services/module";
import { ArrowedCarousel } from "../../../tools/ArrowedCarousel";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid " + Couleur.bordeau,
  boxShadow: 24,
  p: 4,
};

export const ListChambre = (props) => {
  const { modelChambre, isChecked, isHaveSelected } = props;
  const [image, setImage] = useState([]);
  const [open, setOpen] = useState(false);
  const [hasPrincipal, setHasPrincipal] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // load carousel images
    const listImage = [];
    modelChambre.image_chambre.map((img) =>
      listImage.push({ src: baseUrl + img.libelle, alt: "image" })
    );
    setImage(listImage);

    // set the first image to show
    modelChambre.image_chambre.map(
      (img) => img.est_principale === 1 && setHasPrincipal(true)
    );
  }, []);

  return (
    <Box>
      <Paper elevation={5} sx={{ width: "100%" }}>
        <Grid
          container
          sx={{ border: "1px solid #850000", width: "100%" }}
          p={2}
        >
          <Grid item md={5} positon="relative">
            {modelChambre.image_chambre.length > 0 &&
              modelChambre.image_chambre.map(
                (img) =>
                  img.est_principale === 1 && (
                    <Box
                      component="img"
                      key={img.id}
                      src={baseUrl + img.libelle}
                      alt="chambre"
                      width="100%"
                      height="auto"
                    />
                  )
              )}
            {!hasPrincipal && (
              <Box
                component="img"
                src={baseUrl + modelChambre.image_chambre[0].libelle}
                alt="chambre"
                width="100%"
                height="auto"
              />
            )}
          </Grid>
          <Grid item md={7} pl={5}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                component="p"
                variant="h4"
                sx={{
                  fontFamily: "'Glacial Indifference', serif",
                  color: Couleur.bordeau,
                }}
              >
                {modelChambre.type || "null"}
              </Typography>
            </Box>

            <Box display="inline-block" my={2}>
              <Box
                component="span"
                className="family"
                sx={{ p: 1, fontSize: "20px" }}
              >
                {modelChambre.caracteristique && (
                  <Box
                    component="span"
                    className="family"
                    sx={{ p: 1, fontSize: "20px" }}
                  >
                    {modelChambre.caracteristique.split(",")[0]}
                  </Box>
                )}
              </Box>
            </Box>
            <Box component="p" className="family" sx={{ fontSize: 19 }} px={1}>
              {modelChambre.description}
            </Box>
            <Box>
              {modelChambre.accessoire.map(
                (accessoire, index) =>
                  index < 2 && (
                    <ListItem key={accessoire.id}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          mt={1}
                          src={baseUrl + accessoire.icon}
                          alt={accessoire.libelle}
                          sx={{ width: "35px", height: "35px" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Box
                          component="span"
                          className="family"
                          fontSize="18px"
                        >
                          {accessoire.libelle}
                        </Box>
                      </ListItemText>
                    </ListItem>
                  )
              )}
            </Box>
            <Box display="flex" justifyContent="space-between" pr={2}>
              <Button
                variant="Text"
                sx={{
                  fontFamily: "'Glacial Indifference', serif",
                  textDecoration: "underline",
                  fontSize: "19px",
                  textTransform: "capitalize",
                  "&:hover": {
                    color: Couleur.bordeau,
                    backgroundColor: "white",
                  },
                }}
                onClick={handleOpen}
              >
                Détails
              </Button>
              <Box
                component="span"
                className="family"
                sx={{
                  color: Couleur.bordeau,
                  fontSize: "18px",
                  backgroundColor: "#eeeeee",
                  boxShadow: "1px 0",
                  p: 1,
                }}
              >
                {props.disponibilite}{" "}
                {props.disponibilite > 1 ? " disponibles" : " disponible"}
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            md={12}
            position="relative"
            mt={4}
            sx={{ borderTop: "2px dashed black" }}
          >
            <Box mt={2} display="flex" justifyContent="space-around">
              {/* tarif text */}
              {/* FOR ALL EXCEPT SMALL */}
              <Typography
                sx={{
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
                component="p"
                variant="h4"
                fontFamily="'Glacial Indifference', serif"
              >
                Meilleur tarif
              </Typography>
              {/* FOR SMALL */}
              <Typography
                sx={{
                  display: { xs: "block", sm: "block", md: "none", lg: "none" },
                }}
                component="p"
                variant="h4"
                fontFamily="'Glacial Indifference', serif"
              >
                Tarif:
              </Typography>
              {/* end of tarif text */}
              <Typography
                component="p"
                variant="h4"
                fontFamily="'Glacial Indifference', serif"
              >
                {props.currentDevise ? (
                  <>
                    {(
                      modelChambre.tarif / props.currentDevise.valeur_ariary
                    ).toLocaleString(
                      props.currentDevise.libelle.toLowerCase() === "dollar"
                        ? "en-En"
                        : "fr-Fr"
                    )}{" "}
                    {props.currentDevise.libelle.toLowerCase() === "dollar"
                      ? "$"
                      : props.currentDevise.libelle.toLowerCase() === "euro"
                      ? "€"
                      : props.currentDevise.libelle}
                  </>
                ) : (
                  <>{modelChambre.tarif.toLocaleString("fr-Fr")} Ar</>
                )}
              </Typography>
              <Typography
                component="p"
                variant="h4"
                fontFamily="'Glacial Indifference', serif"
              >
                {(!isHaveSelected || isChecked) && (
                  <Checkbox
                    size="large"
                    onChange={(e) =>
                      props.CheckboxHandle(e.target.checked, modelChambre)
                    }
                    checked={isChecked || false}
                  />
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* MODAL */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <IconButton
              sx={{
                color: "white",
                position: "absolute",
                left: "50%",
                top: "45px",
                transition: "all .5s",
                "&:hover": { color: "#850000" },
              }}
              onClick={handleClose}
            >
              <HighlightOffIcon sx={{ fontSize: "50px", fontStyle: "light" }} />
            </IconButton>
            <Box sx={style}>
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <Stack>
                    <Box
                      mb={3}
                      className="family"
                      sx={{
                        fontSize: "3rem",
                        textAlign: "center",
                        color: Couleur.bordeau,
                      }}
                    >
                      <span>{modelChambre.type || null}</span>
                    </Box>
                    <Box className="family" sx={{ fontSize: "1.8rem" }}>
                      {modelChambre.caracteristique &&
                        modelChambre.caracteristique
                          .split(",")
                          .map((carac, index) => (
                            <Box pl={2} key={index}>
                              . {carac}
                            </Box>
                          ))}
                    </Box>
                    <Box mt={3}>
                      {modelChambre.accessoire.map((accessoire, index) => (
                        <ListItem key={accessoire.id}>
                          <ListItemIcon>
                            <Box
                              component="img"
                              mt={1}
                              src={baseUrl + accessoire.icon}
                              alt={accessoire.libelle}
                              sx={{ width: "35px", height: "35px" }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <Box
                              component="span"
                              className="family"
                              fontSize="20px"
                            >
                              {accessoire.libelle}
                            </Box>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item md={8}>
                  <ArrowedCarousel images={image} w="100%" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Paper>
    </Box>
  );
};
