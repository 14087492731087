import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css'

// images par défaut
import Carousel1 from '../../assets/images/defaultCarousel/1.jpg'
import Carousel2 from '../../assets/images/defaultCarousel/2.jpg'
import Carousel3 from '../../assets/images/defaultCarousel/3.jpg'
import Carousel4 from '../../assets/images/defaultCarousel/4.jpg'
import { Paper } from '@mui/material';

const defaultImages = [
    {
        src: Carousel1,
        alt: 'Carousel 1'
    },
    {
        src: Carousel2,
        alt: 'Carousel 2'
    },
    {
        src: Carousel3,
        alt: 'Carousel 3'
    },
    {
        src: Carousel4,
        alt: 'Carousel 4'
    }
]

export const ArrowedCarousel = (props) => {
    const [index, setIndex] = useState(0)
    const [images, setImages] = useState([])

    useEffect(() => {
        if (props.images && props.images.length > 0) {
            setImages(props.images)
        }
        else {
            setImages(defaultImages)
        }

    }, [props.images, props.h, props.w])

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Paper elevation={8} sx={{ width: props.w, position: 'relative' }}>
            <Carousel activeIndex={index} onSelect={handleSelect}
                textAlign='center'
            >
                {
                    images.map((image, index) => (
                        <Carousel.Item key={index}>
                            <Box
                                component='img'
                                src={image.src}
                                alt={image.alt}
                                sx={{
                                    width: '100%',
                                    height: props.h
                                }}
                            />
                            <Carousel.Caption>
                                <h3>{props.caption === true && props.alt}</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))
                }

            </Carousel>
        </Paper>
    );
}

ArrowedCarousel.defaultProps={
    w:'65vw',
    h:'70vh',
    caption:false
}