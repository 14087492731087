import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import { Box, Button, Card, CardActions, CardContent, IconButton, LinearProgress, Stack } from '@mui/material';
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AccessoireService from '../../services/AccessoireService';
import Swal from "sweetalert2";
import { Couleur } from '../../Header';
import { baseUrl } from '../../services/module';
import { set } from 'date-fns';

const SuccessNotification = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});


const ListItems = styled('li')({
  display: 'flex',
  justifyContent: 'space-between'
})

export const Accessoire = () => {
  const [accessoireList, setAccessoireList] = useState([])
  const [libelle, setLibelle] = useState('')
  const [enLibelle, setEnLibelle] = useState('')
  const [icon, setIcon] = useState(null)
  const [idToUpdate, setIdtoUpdate] = useState(null)

  const loadRef = useRef()
  const listRef = useRef()
  const iconRef = useRef()
  const nouveauAction = useRef()
  const modifierAction = useRef()

  useEffect(() => {
    AccessoireService.accessoireList()
      .then(res => {
        setAccessoireList(res.data.data);
        loadRef.current.hidden = true
        listRef.current.hidden = false
      });
  }, [])

  const onChangePhoto = (e) => {
    const img = {
      "iconFile": e.target.files[0]
    }
    setIcon(img);
  };

  const clearValue = () => {
    setLibelle('');
    setEnLibelle('');
    setIcon(null)
    setIdtoUpdate(null)
    iconRef.current.value = null
    modifierAction.current.hidden = true
    nouveauAction.current.hidden = false
  }

  const onAdd = (e) => {
    e.preventDefault()
    let datas = {
      "libelle": libelle,
      "en_libelle": enLibelle,
    }
    if (icon) {
      datas["icon"] = icon.iconFile
    }

    if (!idToUpdate) { // add new data
      if (libelle && icon) {
        AccessoireService.storeAccessoire(datas)
          .then(res => {
            SuccessNotification.fire({
              icon: 'success',
              title: res.data.message
            });
            AccessoireService.accessoireList()
              .then(res => {
                setAccessoireList(res.data.data);
              });
            clearValue()

          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Oups...',
              text: err.response.data.message,
            });
          });
      }
    }
    else { // update current data
      if (libelle && enLibelle) {
        AccessoireService.updateAccessoire(datas, idToUpdate)
          .then(res => {
            SuccessNotification.fire({
              icon: 'success',
              title: res.data.message
            });
            AccessoireService.accessoireList()
              .then(res => {
                setAccessoireList(res.data.data);
              });
            clearValue()

          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Oups...',
              text: err.response.data.message,
            });
          });
      }
    }
  }

  const onEdit = (id) => {
    const item = accessoireList.find((access) => access.id === id)
    if (item) {
      setIdtoUpdate(id)
      setLibelle(item.libelle)
      setEnLibelle(item.en_libelle)
      modifierAction.current.hidden = false
      nouveauAction.current.hidden = true
    }
  }

  const onDelete = (id) => {
    Swal.fire({
      title: 'Suppression',
      text: "Cette action est irreversible, continuer?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#37474F',
      confirmButtonText: 'oui, continuer',
      cancelButtonText: 'annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        AccessoireService.deleteAccessoire(id)
          .then(res => {
            AccessoireService.accessoireList()
              .then(res => {
                setAccessoireList(res.data.data);
              });
            SuccessNotification.fire({
              icon: 'success',
              title: res.data.message
            });
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Oups...',
              text: 'Opération non effectuée',
            });
          })
      }
    })
  }

  return (
    <Box>
      <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
        <Box component='ol' className="breadcrumb mb-4" sx={{ backgroundColor: Couleur.jaune, p: 2, width: '100%', borderRadius: '4px' }}>
          <li className="breadcrumb-item"><a href="#!"><label>Page actuelle :</label></a></li>
          <li className="breadcrumb-item"><a href="#!">Dashboard</a></li>
          <li className="breadcrumb-item active">Accessoire</li>
        </Box>
      </Box>
      <Box px={5} mb={4}>
        <span>Rendez vous au site </span>
        <a target='_blank' href='https://www.flaticon.com/fr/'>https://www.flaticon.com/fr</a>
        <span> pour choisir les icons</span>
        </Box>
      <Card sx={{ maxWidth: 350, ml: 5 }}>
        <Box component='form' onSubmit={(e) => onAdd(e)}>
          <CardActions>Nouveau</CardActions>
          <CardContent>
          <Stack direction="row" spacing={3}>
            <TextField
            sx={{ width: "100%" }}
            label="libelle"
            value={libelle}
            onChange={e => setLibelle(e.target.value)}
            variant="standard"
            />
            <TextField
            sx={{ width: "100%" }}
            label="libelle en anglais"
            value={enLibelle}
            onChange={e => setEnLibelle(e.target.value)}
            variant="standard"
            />
          </Stack>
            <Box mt={3}
              component="input"
              id="photo"
              type="file"
              accept="image/*"
              ref={iconRef}
              onChange={(e) => onChangePhoto(e)}
              sx={{
                fontFamily: "serif, arial",
                fontSize: "18px",
                color: Couleur.bordeau,
                cursor: "pointer",
              }}
            ></Box>
          </CardContent>
          <CardActions ref={nouveauAction}>
            <Button variant="contained" type="submit">
              ajouter
            </Button>
          </CardActions>
          <CardActions ref={modifierAction} hidden>
            <Button variant="contained" color="warning" type="submit">
              modifier
            </Button>
            <Button
              variant="contained"
              onClick={clearValue}
              sx={{
                backgroundColor: "#eeeeee",
                color: "black",
                "&:hover": { backgroundColor: "#eeefff" },
              }}
            >
              annuler
            </Button>
          </CardActions>
        </Box>
      </Card>

      <Box sx={{ width: 500, mt: 4, ml: 5 }}>
        <Box ref={loadRef} sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
        <Box component='ul' hidden ref={listRef} className="list-group">
          <li className="list-group-item active" aria-current="true">Liste des accessoires</li>
          {
            accessoireList.length > 0 &&
            accessoireList.map((access, index) => (
              <ListItems className="list-group-item" key={access.id}>
                <Box>
                <Stack direction="row" spacing={4} >
                  <Box component='img' src={access.icon && baseUrl + access.icon} alt={access.libelle} sx={{ width: '35px', height: '35px' }} mr={1} />
                  <span className='mt-2'>{access.libelle}</span>
                  <span className='mt-2'>{access.en_libelle?? "vide"}</span>
                  </Stack>
                </Box>
                <Box>
                  <IconButton aria-label="edit" size="sm" onClick={() => onEdit(access.id)}>
                    <EditIcon color='warning' />
                  </IconButton>
                  <IconButton aria-label="delete" size="sm" onClick={() => onDelete(access.id)}>
                    <DeleteIcon color='error' />
                  </IconButton>
                </Box>
              </ListItems>
            ))
          }
        </Box>
      </Box>
    </Box>
  )
}
