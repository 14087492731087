import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import { Box, Button, Grid, Paper, TextField, Stepper, Step, StepLabel, StepContent, Typography, Input } from "@mui/material";
import { YellowButton } from "../../tools/Buttons";
import { Resume } from "./composants/Resume";

import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import HomeIcon from "@mui/icons-material/Home";
import BadgeIcon from "@mui/icons-material/Badge";
import PaidIcon from "@mui/icons-material/Paid";

import { Stack } from "@mui/system";
import ReserverService from "../../services/ReserverService";
import Swal from "sweetalert2";
import { Couleur } from "../../Header";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";

const steps = [
  {
    label: 'Vérification',
    description: "Cette étape nous permettra de vous identifier",
  },
  {
    label: 'Renseignements',
    description:
      "",
  },
];


export const InformationClient = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [foundClient, setFoundClient] = useState(false)


  const [activeStep, setActiveStep] = useState(0);

  const [clientID, setClientID] = useState(0)
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [cinPasseport, setCinPasseport] = useState("");
  const [commentaire, setCommentaire] = useState("");

  let navigate = useNavigate()

  useEffect(() => {
    AOS.init();
    props.setShowPayementButton(false)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.setShowPayementButton(false)
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChangeNom = (event) => {
    setNom(event.target.value);
  };
  const handleChangePrenom = (event) => {
    setPrenom(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeTelephone = (event) => {
    setTelephone(event.target.value);
  };
  const handleChangeAdresse = (event) => {
    setAdresse(event.target.value);
  };
  const handleChangeCinPasseport = (event) => {
    setCinPasseport(event.target.value);
  };
  const handleChangeCommentaire = (event) => {
    setCommentaire(event.target.value);
  };

  /* Check if string is email */
  const checkIfEmail = (str) => {
    // Regular expression to check if string is email
    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    return regexExp.test(str);
  }

  const clearValue = () => {
    setNom('')
    setPrenom('')
    setTelephone('')
    setAdresse('')
    setCinPasseport('')
    setFoundClient(false)
    setClientID(0)
  }

  const onVerifyEmail = () => {
    if (checkIfEmail(email)) {
      ReserverService.checkClient(email)
        .then(res => {
          if (res.data.data) {
            setNom(res.data.data.nom || '')
            setPrenom(res.data.data.prenom || '')
            setTelephone(res.data.data.telephone || '')
            setAdresse(res.data.data.adresse || '')
            setCinPasseport(res.data.data.cin_passeport || '')
            setClientID(res.data.data.id)
            setFoundClient(true)
            setError(false)
          }
          else {
            clearValue()
          }
        })
      setEmailError(false)
      props.setShowPayementButton(true)
      handleNext()
    }
    else {
      setEmailError(true)
    }
  }

  useImperativeHandle(ref, () => ({
    handleSubmit(e) {
      e && e.preventDefault()
      if (nom && email && telephone && adresse && cinPasseport) {
        setOpen(true)
        let listChoixChambre = props.listChoixChambre;
        let amount = 0;

        // calcul montant listChoixChambre
        listChoixChambre.length > 0 &&
          listChoixChambre.map(
            (chambres, index) =>
              (chambres["montant"] =
                props.listChambreSelected[index].tarif.toFixed(0) *
                props.sejour) && (amount += chambres["montant"])
          );
        // ----------------------------------------

        let datas = {
          nom: nom,
          email: email,
          telephone: telephone,
          adresse: adresse,
          cin_passeport: cinPasseport,
          type_reservation: "en ligne",
          reserver_du: props.dates.dtArrivee,
          jusqu_au: props.dates.dtDepart,
          total: amount,
          listChoixChambre: props.listChoixChambre,
          client_id: clientID,
        };
        if (prenom) {
          datas["prenom"] = prenom;
        }
        if (commentaire) {
          datas["commentaire"] = commentaire;
        }
        if (props.currentDevise) {
          datas["devise_id"] = props.currentDevise.id;
        }


        ReserverService.reserver(datas)
          .then((res) => {
            setOpen(false)
            Swal.fire({
              icon: "success",
              title: "Succès",
              text: "Réservation effectuée",
            });
            props.setClient(res.data.data)
            props.setIsShowFinalResume(true)
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: "Une erreur s'est produite, nous vous prions de contacter le service client",
            });
          });

        setError(false);
      } else {
        setError(true);
      }
    }
  }));



  return (
    <Box position="relative" px={{ xs: 1, sm: 1, md: 9, lg: 9 }}>
      {open &&
        <Backdrop
          sx={{ color: Couleur.bordeau, zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      <Box component='form' onSubmit={(e) => props.handlePaiement(e)}>
        <Stepper activeStep={activeStep} orientation="vertical" >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 1 ? (
                    <Typography variant="caption">Dernière étape avant le paiement</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography pb={3}>{step.description}</Typography>
                {/* COMPONENT CONTENT */}
                {index === 0 &&
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 4 }}>
                      <MailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField
                        label="Email"
                        type="email"
                        error={emailError}
                        variant="standard"
                        sx={{ width: "40%" }}
                        required
                        value={email}
                        onChange={handleChangeEmail}
                      />
                    </Box>
                  </>
                }

                {
                  index === 1 &&
                  <Box sx={{ width: "100%" }}>
                    <Box component="p" className="family" color={Couleur.jaune} fontWeight={"bold"} fontSize={18}>
                      {foundClient ?
                        "Veuillez vérifier vos informations" :
                        "Veuillez compléter vos informations"
                      }

                    </Box>
                    <Paper elevation={5}>
                      <Box component="form" noValidate autoComplete="off" p={{ xs: 0, sm: 2, md: 5, lg: 5 }}>
                        <Stack
                          direction={{ xs: 'column', sm: 'column', md: "row" }}
                          display="flex"
                          justifyContent="center"
                          spacing={5}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "70%",
                            }}
                            mt={2}
                          >
                            <AccountCircle />
                            <TextField
                              label="Nom"
                              variant="standard"
                              sx={{ width: "100%" }}
                              required
                              value={nom}
                              onChange={handleChangeNom}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "70%",
                            }}
                          >
                            <AccountCircle />
                            <TextField
                              id="input-with-sx"
                              label="Prénom"
                              variant="standard"
                              sx={{ width: "100%" }}
                              value={prenom}
                              onChange={handleChangePrenom}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction={{ xs: 'column', sm: 'column', md: "row" }}
                          display="flex"
                          justifyContent="center"
                          spacing={5}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "70%",
                            }}
                            mt={5}
                          >
                            <MailIcon />
                            <TextField
                              id="input-with-sx"
                              label="Email"
                              type="email"
                              variant="standard"
                              sx={{ width: "100%" }}
                              required
                              value={email}
                              onChange={handleChangeEmail}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "70%",
                            }}
                          >
                            <SmartphoneIcon />
                            <TextField
                              id="input-with-sx"
                              label="Téléphone"
                              variant="standard"
                              sx={{ width: "100%" }}
                              required
                              value={telephone}
                              onChange={handleChangeTelephone}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction={{ xs: 'column', sm: 'column', md: "row" }}
                          display="flex"
                          justifyContent="center"
                          spacing={5}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "70%",
                            }}
                            mt={5}
                          >
                            <HomeIcon />
                            <TextField
                              id="input-with-sx"
                              label="Adresse"
                              variant="standard"
                              sx={{ width: "100%" }}
                              required
                              value={adresse}
                              onChange={handleChangeAdresse}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "70%",
                            }}
                          >
                            <BadgeIcon />
                            <TextField
                              id="input-with-sx"
                              label="CIN ou Passeport"
                              variant="standard"
                              sx={{ width: "100%" }}
                              required
                              value={cinPasseport}
                              onChange={handleChangeCinPasseport}
                            />
                          </Box>
                        </Stack>
                        <Box
                          mt={5}
                          display={error ? "flex" : "none"}
                          justifyContent="center"
                        >
                          <Box
                            component="p"
                            sx={{ color: "red", fontSize: "20px" }}
                            className="family"
                          >
                            les champs <Box component="span">*</Box> sont obligatoires
                          </Box>
                        </Box>
                        <Box mt={error ? 5 : 10}>
                          <TextField
                            label="Un commentaire"
                            multiline
                            rows={8}
                            sx={{ width: "100%" }}
                            value={commentaire}
                            onChange={handleChangeCommentaire}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                }
                {/* END OF COMPONENT CONTENT */}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <YellowButton
                      h="40px"
                      widthed={false}
                      onClick={
                        index === steps.length - 1 ?
                          (e) => props.handlePaiement(e) :
                          onVerifyEmail
                      }
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? 'Proceder à la réservation' : 'Continuer'}
                    </YellowButton>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      retour
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>


    </Box>
  );
})

InformationClient.defaultProps = {
  dates: { arrivee: "", depart: "" },
};
