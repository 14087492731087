import styled from "@emotion/styled";
import {
  AppBar,
  Button,
  Modal,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// ---------- PHOTOS ----------------
import premierPlan from "../assets/images/premierPlan.jpg";
import logoZomatel from "../assets/images/logo.png";
// ----------------------------------

import "../assets/scss/header.scss";
import HeaderMenu from "./HeaderMenu";
import { frontUrl } from "./services/module";

const HeaderBox = styled(Box)(({ backgurl }) => ({
  backgroundImage: `url(${backgurl ? backgurl : premierPlan})`,
  // minHeight: '100vh',
  backgroundSize: "cover",
  marginBottom: "100px",
  backgroundPosition: "center",
}));

const MyLink = styled(Link)({
  textDecoration: "none",
  color: "black",
});

export const Couleur = {
  jaune: "#F2D860",
  bordeau: "#850000",
  gris: "#37474f",
  marron: "#5d4037",
  grisBleue: "#607d8b",
  orange: "#bf360c",
  grisClair: "#eeeeee",
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid " + Couleur.bordeau,
  boxShadow: 24,
  p: 4,
};

const lngs = {
  en: { nativeName: "English", code: "en" },
  fr: { nativeName: "French", code: "fr" },
};

export const Header = (props) => {
  const [navbar, setNavbar] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [currentLang, setCurrentLang] = useState(lngs.fr.code);
  const [t, i18n] = useTranslation("common");
  const menuBox = useRef();
  const headerRef = useRef();
  const boxLogo = useRef();
  const boxButton = useRef();
  const btnReserver = useRef();
  const appBarRef = useRef();

  let navigate = useNavigate();

  useEffect(() => {
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const StyledAppBar = styled(AppBar)({
    background: navbar ? "white" : "none",
    // background: navbar ? props.bgColor : props.defaultBgColor,
    boxShadow: navbar ? "" : "none",
    width: "100%",
    transition: ".5s ease-in-out",
  });
  const btnLngClicked = () => {
    if (currentLang === lngs.fr.code) {
      setCurrentLang(lngs.en.code);
    } else {
      setCurrentLang(lngs.fr.code);
    }
    i18n.changeLanguage(currentLang);
  };

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleChange = () => {
    $("#menu-icon").toggleClass("open");
    if (menuBox.current.hidden) {
      menuBox.current.hidden = false;
      $("body").css({
        overflow: "hidden",
      });

      boxLogo.current.style.width = "125px";
      boxLogo.current.style.marginTop = "0%";
      boxButton.current.style.marginTop = "0.7%";
      btnReserver.current.style.fontSize = "23px";
      appBarRef.current.style.backgroundColor = "white";
    } else {
      menuBox.current.hidden = true;
      $("body").css({
        overflow: "visible",
      });

      boxLogo.current.style.width = "150px";
      boxLogo.current.style.marginTop = "1%";
      boxButton.current.style.marginTop = "1%";
      btnReserver.current.style.fontSize = "23px";

      if (window.scrollY >= 66) {
        setNavbar(true);
      } else {
        setNavbar(false);
        appBarRef.current.style.backgroundColor = "none !important";
      }
    }
  };

  const goHome = () => {
    if (window.location.href !== frontUrl) {
      navigate("/");
    }
  };

  return (
    <HeaderBox
      minHeight={props.minH}
      position="relative"
      backgurl={props.backgUrl}
      ref={headerRef}
    >
      {/* MENU */}
      <HeaderMenu menuBox={menuBox} handleChange={handleChange} />

      <StyledAppBar ref={appBarRef} position="fixed">
        <Stack
          direction="row"
          p={2}
          display="flex"
          justifyContent="space-between"
          py={0}
        >
          <Box display="flex" justifyContent="space-around">
            <div
              id="menu-icon"
              onClick={handleChange}
              ml={2}
              style={{
                alignSelf: "center",
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Box>
          <Box
            ref={boxLogo}
            ml="110px"
            sx={{
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
              position: "relative",
              width: `${navbar ? "125px" : "150px"}`,
              marginTop: `${navbar ? "0%" : "1%"}`,
              cursor: "pointer",
            }}
            onClick={goHome}
          >
            <img src={logoZomatel} alt="logo zomatel" width="100%" />
          </Box>
          <Box
            display={props.showRes}
            ref={boxButton}
            sx={{ alignSelf: "center" }}
          >
            <Button onClick={btnLngClicked}>{currentLang}</Button>
            <a href="https://zomatel.thais-hotel.com/direct-booking/calendar" style={{ textDecoration: "none" }}>
              <Button
                ref={btnReserver}
                sx={{
                  fontSize: `${navbar ? "23px" : "28px"}`,
                  fontFamily: "serif",
                  width: "170px",
                  backgroundColor: props.reserverBg,
                  color: props.reserverColor,
                  "&:hover": {
                    backgroundColor: Couleur.jaune,
                  },
                }}
              >
                {" "}
                {t("btn.reserver")}
              </Button>
            </a>
          </Box>
          <CallIcon
            onClick={handleOpenModal}
            style={{
              alignSelf: "center",
              fill: Couleur.bordeau,
              width: "160px",
              height: "50px",
              display: props.showCall,
              cursor: "pointer",
            }}
          />
        </Stack>
      </StyledAppBar>

      <Box
        position="absolute"
        sx={{
          bottom: "5%",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            color={"white"}
            component="p"
            sx={{
              fontSize: { xs: "60px", sm: "80px", md: "100px" },
              fontStyle: "italic",
              textAlign: "center",
              fontFamily: "'Oleo Script', 'serif",
            }}
          >
            {props.headerTitle}
          </Typography>
        </Box>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={styleModal}>
          <Stack>
            <Box
              component="p"
              className="family"
              sx={{ fontSize: "20px", textAlign: "center" }}
            >
              <Box component="span" sx={{ color: Couleur.bordeau }}>
                Adresse:{" "}
              </Box>
              BP: 1367, Place du Zoma
            </Box>
            <Box
              component="p"
              className="family"
              sx={{ fontSize: "20px", textAlign: "center" }}
            >
              <Box component="span" sx={{ color: Couleur.bordeau }}>
                E-mail:{" "}
              </Box>
              resa@zomatel.com
            </Box>
            <Box
              component="p"
              className="family"
              sx={{ fontSize: "20px", textAlign: "center" }}
            >
              <Box component="span" sx={{ color: Couleur.bordeau }}>
                Tel:{" "}
              </Box>
              <Box component="span">(+261) 20 75 507 97 </Box>
              <br />{" "}
              <Box component="span" pl={4}>
                (+261) 34 07 255 27
              </Box>{" "}
            </Box>
            <Box
              component="p"
              className="family"
              sx={{ fontSize: "20px", textAlign: "center" }}
            >
              <Box component="span" sx={{ color: Couleur.bordeau }}>
                Lien:{" "}
              </Box>
              zomatel-madagascar.com
            </Box>
            <Box display="flex" justifyContent="space-between">
              <a href="https://www.facebook.com/hotelzomatel" target="_blank">
                <IconButton color="primary">
                  <FacebookIcon sx={{ fontSize: "50px" }} />
                </IconButton>
              </a>
              <Box>
                <IconButton color="success">
                  <WhatsAppIcon sx={{ fontSize: "50px" }} />
                </IconButton>
              </Box>
            </Box>
          </Stack>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography> */}
        </Box>
      </Modal>

      {/* <Box position='relative'>
                <svg className="triangle-overlay" style={{height:'170px', width:'100%', position:'absolute', top:'81.7vh'}} viewBox="0 0 200 100" preserveAspectRatio="none">
                    <polygon points="200 100 0 100 200 0 200 100"></polygon>
                </svg>
                </Box> */}
    </HeaderBox>
  );
};

Header.defaultProps = {
  reserverBg: Couleur.bordeau,
  reserverColor: "white",
  minH: "100vh",
  bgColor: "white",
  defaultBgColor: "none",
  showRes: "block",
  showCall: "none",
};
Header.propTypes = {
  minH: PropTypes.string,
  bgColor: PropTypes.string,
  defaultBgColor: PropTypes.string,
};
