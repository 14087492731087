import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import { ListChambre } from "./composants/ListChambre";
import "../../../assets/scss/reserve.scss";

import AOS from "aos";
import "aos/dist/aos.css";
import { Grid, IconButton, Skeleton } from "@mui/material";
import { Couleur } from "../../Header";
import $ from "jquery";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

export const SelectionChambre = (props) => {
  const [newSelectChambre, setSelectChambre] = useState(false);
  const [indexMofify, setIndexModify] = useState(null);
  const [modelChambreChecked, setModelChambreChecked] = useState(null);
  const [roomToShow, setRoomToShow] = useState([]); // list of room to show or choosable
  const [load, setLoad] = useState(true);

  useEffect(() => {
    AOS.init();

    // ---------------------------
    var btn = $("#button");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    // -----------------------
    if (props.modelChambre && props.listModeleChambre) {
      const maxPerson =
        Number(props.modelChambre.adults) + Number(props.modelChambre.kids);
      const roomList = props.listModeleChambre.filter(
        (modeleChambre) => modeleChambre.max_personne >= maxPerson
      );
      setRoomToShow(roomList);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    setLoad(false);
  }, [props.modelChambre, props.listModeleChambre]);

  useEffect(() => {
    if (props.indexModify >= 0) setIndexModify(props.indexModify);
  }, [props.indexModify]);

  useEffect(() => {
    if (!props.currentSelected) {
      setSelectChambre(false);
      setModelChambreChecked(null);
    } else {
      setSelectChambre(true);
      setModelChambreChecked(props.currentSelected);
      setIndexModify(props.indexModify);
    }
  }, [props.currentSelected]);

  const CheckboxHandle = (checked, modelChambre) => {
    if (checked) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setModelChambreChecked(modelChambre);
      setTimeout(() => {
        document.location.hash = "#resumeSection";
      }, 200);
    } else {
      setModelChambreChecked(null);
    }
    props.updateGlobaleTable(checked, modelChambre, indexMofify);
    setSelectChambre(checked);
  };

  const isShow = (modelChambre) => {
    let dispo = modelChambre.disponible - props.findDisponibilite(modelChambre);
    if (dispo === 0) {
      if (!modelChambreChecked) return [false, dispo];
      if (modelChambreChecked.id === modelChambre.id) return [true, dispo];
      return [false, dispo];
    }
    return [true, dispo];
  };

  return (
    <Box px={{ xs: 2, sm: 9, md: 9, lg: 9 }}>
      <Box className="scrollButton">
        <IconButton
          aria-label="delete"
          id="button"
          size="large"
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
        >
          <KeyboardDoubleArrowUpIcon
            sx={{
              fontSize: "30px",
              color: "white",
              "&:hover": { fontSize: "40px" },
              transition: "all .4s",
            }}
          />
        </IconButton>
      </Box>

      <Box mt={4}>
        {load && (
          <>
            {[0, 1].map((x) => (
              <Box mb={2}>
                <Skeleton variant="rectangular" width="100%" height={300} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                </Box>
              </Box>
            ))}
          </>
        )}
        {
          !load && roomToShow.length === 0 && (
            <>
              {[0, 1].map((x) => (
                <Box mb={2}>
                  <Skeleton variant="rectangular" width="100%" height={300} />
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                  </Box>
                </Box>
              ))}
            </>
          )
          // <Box component="p" className="family" sx={{ color: Couleur.bordeau, fontSize: 40, textAlign: "center" }}> Aucune chambre disponible pour ce nombre de personne à la date que vous avez choisie.</Box>
        }
        {roomToShow.length > 0 &&
          roomToShow.map((modelChambre) => {
            let [result, dispo] = isShow(modelChambre);
            if (result) {
              return (
                <Box mt={2} key={modelChambre.id}>
                  <ListChambre
                    currentDevise={props.currentDevise}
                    disponibilite={dispo}
                    CheckboxHandle={CheckboxHandle}
                    modelChambre={modelChambre}
                    isHaveSelected={newSelectChambre}
                    isChecked={
                      newSelectChambre &&
                      modelChambre.id === modelChambreChecked.id
                    }
                  />
                </Box>
              );
            }
          })}
      </Box>
    </Box>
  );
};

SelectionChambre.defaultProps = {
  dates: { arrivee: "", depart: "" },
};
