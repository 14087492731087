import axios from "axios";
import { apiUrl } from "./module";


const header = ()=>{

    const user_token = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
    const config = {
        headers: {
            Authorization: `Bearer ${user_token}`,
            'Content-Type':'multipart/form-data',
            "Access-Control-Allow-Origin": "*",
        }
    }
    return config;
}

class GalerieService {
    storeGalerie(datas){
        return axios.post(apiUrl + "/galerieZomatel", datas, header());
    }
    listGalerie(){
        return axios.get(apiUrl + "/galerieZomatel");
    }
    updateGalerie(id){
        return axios.get(apiUrl + "/updateGalerieZomatel/"+id, header());
    }
    deleteGalerie(id){
        return axios.get(apiUrl + "/galerieZomatel/"+id, header());
    }
}

export default new GalerieService();