import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Header } from '../Header'
import photoChambre from '../../assets/images/chambre.jpg'
import { Container, Grid } from '@mui/material'
import { useTranslation } from "react-i18next";


import { Link, useParams } from 'react-router-dom'
import { FigureText, SimpleTitle, SubTitle } from '../tools/Texts'
import '../../assets/scss/chambre.scss'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from '../Footer'
import ModeleChambreService from '../services/ModeleChambreService'
import { baseUrl } from '../services/module'
import { useLangue } from '../../i18n'


export const Chambre = () => {
  const [listHebergement, setListHebergement] = useState([])
  const { modeleParam } = useParams()
  const isFrancais = useLangue();
  const [t, i18n] = useTranslation("common");


  useEffect(() => {

    ModeleChambreService.listModeleChambre()
      .then(res => {
        let newListD = []
        let newListT = []
        let newListS = []
        let newListF = []
        let newListA = []
        res.data.data.forEach(listM => {
          if (listM.type.toLowerCase().includes('double')) {
            listM['text'] = 'Nos chambres doubles'
            listM['modele'] = 'double'
            newListD = [...newListD, listM]
          }
          if (listM.type.toLowerCase().includes('triple')) {
            listM['text'] = 'Nos chambres triples'
            listM['modele'] = 'triple'
            newListT.push(listM)
          }
          if (listM.type.toLowerCase().includes('suite')) {
            listM['text'] = 'Nos suites'
            listM['modele'] = 'suite'
            newListS.push(listM)
          }
          if (listM.type.toLowerCase().includes('familiale')) {
            listM['text'] = 'Nos chambres familiales'
            listM['modele'] = 'familiale'
            newListF.push(listM)
          }
          if (listM.type.toLowerCase().includes('appartement')) {
            listM['text'] = 'Nos appartements'
            listM['modele'] = 'appartement'
            newListA.push(listM)
          }
          // set the image to show
          let hasPrincipale = false
          let imgPath = null
          listM.image_chambre.forEach(img => {
            if (img.est_principale === 1) {
              hasPrincipale = true
              imgPath = img.libelle
            }
          })
          hasPrincipale ? listM["imgPath"] = imgPath : listM["imgPath"] = listM?.image_chambre[0]?.libelle
        })


        setListHebergement([
          newListD,
          newListT,
          newListS,
          newListF,
          newListA,
        ])
      })

    if (modeleParam) {
      setTimeout(() => {
        document.location.hash = "#" + modeleParam
      }, 1500);
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [modeleParam])

  useEffect(()=>{
    AOS.init();
  });

  return (
    <Box>
      <Header backgUrl={photoChambre} headerTitle='Hébergement' />
      <Box data-aos="fade-up" data-aos-duration="1000">
        {/* <Box className='boxToAnimate'>
          <Box className='contentAnimate'> */}
        <Box
          sx={{
            top: { xs: '57%', sm: '30%', md: '30%' },
          }}
        >
          <Box textAlign='center'>
            <SimpleTitle>Nos chambres <br />& Suites</SimpleTitle>
          </Box>

          <Container>
            <Box component='p' mt={5} className='text-adjust' px='10%' data-aos="fade-up" data-aos-duration="800">
            {t("hebergement.paragraphes.p1")}<br/>{t("hebergement.paragraphes.p2")}
            </Box>
          </Container>
        </Box>
        {/* <Box className='circle'></Box>
            <Box className='circle'></Box>
            <Box className='circle'></Box>
            <Box className='circle'></Box>
            <Box className='circle'></Box>
            <Box className='circle'></Box> */}
        {/* </Box>
        </Box> */}
      </Box>

      {
        listHebergement.map((liste, index) => (
          <Box px={9} mb={10} key={index} id={liste[0].modele}>
            {
              liste.length > 1 ?
                <SubTitle>{liste[0].text}</SubTitle> :
                <SubTitle>{liste[0].type}</SubTitle>
            }
            <Grid container spacing={5} justifyContent="center">
              {
                liste.map((ls, idx) => (
                    <Grid className='roomImage' item sm={12} md={6} position='relative' display='flex' justifyContent='center'
                    key={idx} data-aos={`${idx % 2 === 0 ? "fade-right" : "fade-left"}`} data-aos-duration="1500">
                      <Box className='overlap-img cadreImage' component='figure' sx={{ height: '100%' }}>
                        <Box component='img' src={baseUrl + ls.imgPath} sx={{ width: '100%', height: '90%' }} alt="Chambres" />
                        <Link to={`/detailChambre/${ls.id}/${ls.modele}`}>
                          <figcaption>
                            <FigureText component='p'>{isFrancais? ls.type : ls.en_type ?? ls.type}</FigureText>
                          </figcaption>
                        </Link>
                      </Box>
                    </Grid>
                ))
              }
            </Grid>
          </Box>
        ))
      }
      <Footer />
    </Box>
  )
}
