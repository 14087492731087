import { Box } from "@mui/material";
import { Content } from "./Content";
import { Header } from "../Header";
import { Footer } from "../Footer";

function App() {
  return (
    <Box>
      <Header />
      <Content />
      <Footer />
    </Box>
  );
}

export default App;
