import axios from "axios";
import { apiUrl } from "./module";


const header = ()=>{

    const user_token = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
    const config = {
        headers: {
            Authorization: `Bearer ${user_token}`,
            "Access-Control-Allow-Origin": "*",
        }
    }
    return config;
}

class ChambreService {
    storeChambre(datas){
        return axios.post(apiUrl + "/storeChambre", datas, header());
    }
    listChambre(){
        return axios.get(apiUrl + "/listChambre", header());
    }
    deleteChambre(id){
        return axios.get(apiUrl + "/deleteChambre/"+id, header());
    }
}

export default new ChambreService();