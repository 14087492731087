import { Grid, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import pplanService from "../../assets/images/Service/pplanService.JPG";
import { Couleur, Header } from "../Header";

import AOS from "aos";
import "aos/dist/aos.css";
import styled from "@emotion/styled";
import "../../assets/scss/nosServices.scss";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Footer } from "../Footer";
import ServiceService from "../services/ServiceService";
import MenuService, { getImg } from "../services/MenuService";
import { MENU_ID_SITE } from "../services/menuID";
import {  useLangue } from "../../i18n";

const TextHead = styled("p")({
  fontSize: "50px",
  fontFamily: "'Oleo Script', 'Times New Roman', serif",
});
const ListText = styled("span")({
  fontSize: "25px",
  fontFamily: "'Glacial Indifference', 'Times New Roman', serif",
});

const BoxImg = (props) => {
  const { service } = props;
  const isFrancais = useLangue();

  const imgHtml = () => {
    return (
      <Grid item sm={12} md={6} position="relative">
        <img
          src={
            service?.images.length === 0
              ? pplanService
              : getImg(service?.images)
          }
          alt="image service"
          width="100%"
          height="100%"
        />
      </Grid>
    );
  };

  return (
    <Box my={9} px="10%" h="60vh" data-aos="fade-up" data-aos-duration="800">
      <Grid container>
        {props.pos === "L" && imgHtml()}
        <Grid item sm={12} md={6} position="relative">
          <Box
            p={5}
            sx={{ backgroundColor: Couleur.grisClair, height: "100%" }}
          >
            <TextHead>{ isFrancais ? service.titre : service.en_titre }</TextHead>
            <Box>
              {service.item_menus.map((item) => (
                <List key={item.id} dense>
                  <ListItem>
                    <ListItemIcon>
                      <RadioButtonCheckedIcon fontSize="medium" />
                      {/* <WatchLaterIcon fontSize="medium" /> */}
                    </ListItemIcon>
                    <ListText>{isFrancais ? item.libelle : item.en_libelle } </ListText>
                  </ListItem>
                </List>
              ))}
            </Box>
          </Box>
        </Grid>
        {props.pos === "R" && imgHtml()}
      </Grid>
    </Box>
  );
};

export const NosServices = () => {
  const [menu, setMenu] = useState();
  const [services, setServices] = useState();
  const isFrancais = useLangue();


  useEffect(() => {
    MenuService.getMenu(MENU_ID_SITE.SERVICES).then((res) => {
      setMenu(res.data.data);
    });
    ServiceService.listService().then((res) => {
      setServices(res.data.data);
    });
  }, []);

  useEffect(() => {
    AOS.init();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      <Header backgUrl={getImg(menu?.images)} headerTitle={isFrancais? menu?.titre : menu?.en_titre ?? menu?.titre } />
      <Box px={9} mb={5}>
        <Box
          textAlign="center"
          pb={3}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Typography
            color={Couleur.bordeau}
            component="p"
            sx={{
              fontSize: { xs: "60px", sm: "100px" },
              lineHeight: { xs: "55px", sm: "100px" },
              fontFamily: "'Oleo Script', 'Times New Roman', serif",
            }}
          >
            { isFrancais? menu?.sousTitre ?? menu?.titre : menu?.en_sousTitre ?? menu?.en_titre ?? menu?.sousTitre ?? menu?.titre }
          </Typography>
        </Box>
        <Box
          component="p"
          className="text-adjust"
          px="10%"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          { isFrancais? menu?.description : menu?.en_description ?? menu?.description }
        </Box>
      </Box>

      {services?.map((service, index) => (
        <BoxImg service={service} pos={index % 2 === 0 ? "L" : "R"} />
      ))}
      <Footer />
    </Box>
  );
};
