import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Header, Couleur } from "../Header";
import "../../assets/scss/chambre.scss";
import { ArrowedCarousel } from "../tools/ArrowedCarousel";

import AOS from "aos";
import "aos/dist/aos.css";
import { SubTitle } from "../tools/Texts";
import { Footer } from "../Footer";
import { YellowButton } from "../tools/Buttons";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useParams } from "react-router-dom";
import massageCarte from "../../assets/pdf/MASSAGE TRADI.pdf";
import MenuService, { getImg } from "../services/MenuService";
import { baseUrl } from "../services/module";
import { MENU_ID_SITE } from "../services/menuID";
import { useLangue } from "../../i18n";
import { useTranslation } from "react-i18next";

export default function BienEtre() {
  const [menu, setMenu] = useState(null);
  const [sousMenu, setSousMenu] = useState([]);
  const { modeleParam } = useParams();
  const isFrancais = useLangue();
  const [t, i18n] = useTranslation("common");



  useEffect(() => {
    MenuService.getMenu(MENU_ID_SITE.BIEN_ETRE_LOISIR).then((res) => {
      setMenu(res.data.data);
    });
    MenuService.getAllSousMenu(MENU_ID_SITE.BIEN_ETRE_LOISIR).then((res) => {
      setSousMenu(res.data.data);
    });
  }, []);

  useEffect(() => {
    AOS.init();
    if (modeleParam) {
      setTimeout(() => {
        document.location.hash = "#goto_" + modeleParam;
      }, 500);
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [modeleParam]);

  const openCarte = (linkTo) => {
    if (linkTo.toLowerCase().includes("massage")) {
      window.open(massageCarte, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Box>
      <Header
        backgUrl={getImg(menu?.images)}
        headerTitle={isFrancais? menu?.titre : menu?.en_titre ?? menu?.titre }
      />

      <Box px={9} mb={10}>
        <Box textAlign="center" data-aos="fade-up" data-aos-duration="500">
          <Typography
            color={Couleur.bordeau}
            component="p"
            sx={{
              fontSize: { xs: "60px", sm: "100px" },
              lineHeight: { xs: "55px", sm: "100px" },
              fontFamily: "'Oleo Script', 'Times New Roman', serif",
            }}
          >
            {isFrancais?  menu?.sousTitre ?? menu?.titre : menu?.en_sousTitre ?? menu?.en_titre ?? menu?.sousTitre ?? menu?.titre}
          </Typography>
        </Box>
        <Box
          component="p"
          mt={4}
          className="text-adjust"
          px="10%"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          {isFrancais? menu?.description : menu?.en_description ?? menu?.description }
        </Box>
      </Box>
      {sousMenu.map((s) => (
        <Box key={s.id} px={9} mb={15} id={"goto_" + s.id}>
          <SubTitle>{isFrancais? s.titre : s.en_titre ?? s.en_sousTitre ?? s.titre}</SubTitle>
          <Box
            component="p"
            className="text-adjust"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            {isFrancais? s?.description : s?.en_description ?? s?.description }
          </Box>
          <Box
            width="100%"
            textAlign="center"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <YellowButton
              onClick={(e) => openCarte(s.titre)}
              startIcon={<ReceiptLongIcon />}
            >
            {t("btn.tarifs")}
            </YellowButton>
          </Box>
          {/* <TarifButton /> */}
          <Box
            display="flex"
            mt={1}
            justifyContent="center"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <ArrowedCarousel
              images={s?.images?.map((i) => {
                return {
                  src: baseUrl + i.libelle,
                  alt: "images",
                };
              })}
            />
          </Box>
        </Box>
      ))}
      <Footer />
    </Box>
  );
}
