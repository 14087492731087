import axios from "axios";
import { id } from "date-fns/locale";
import { apiUrl, baseUrl } from "./module";

const header = () => {
  const user_token = localStorage.getItem("user_token")
    ? localStorage.getItem("user_token")
    : "";
  const config = {
    headers: {
      Authorization: `Bearer ${user_token}`,
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return config;
};

export const getImg = (imgs) => {
  if (!imgs || imgs.length === 0) return "";
  let imgFilters = imgs?.filter((img) => img.est_principale === 1);
  if (imgFilters.length > 0) return baseUrl + imgFilters[0].libelle;
  return baseUrl + imgs[0].libelle;
};

class MenuService {
  storeMenu(datas) {
    return axios.post(apiUrl + "/menu", datas, header());
  }
  storeSous_Menu(datas) {
    return axios.post(apiUrl + "/sous_menu", datas, header());
  }
  storeMenuImage(datas, id) {
    return axios.post(apiUrl + `/menu/${id}/addImage`, datas, header());
  }
  listMenu() {
    return axios.get(apiUrl + "/menu");
  }
  getMenu(id) {
    return axios.get(apiUrl + "/menu/" + id);
  }
  getSousMenu(id) {
    return axios.get(apiUrl + "/sous_menu/" + id);
  }
  getAllSousMenu(id) {
    return axios.get(apiUrl + `/menu/${id}/sous_menu`);
  }
  setPrincipale(data, id) {
    return axios.post(apiUrl + "/menu/image/principale/" + id, data, header());
  }
  updateMenu(datas, id) {
    return axios.post(apiUrl + "/menu/" + id, datas, header());
  }
  updateSousMenu(datas, id) {
    return axios.post(apiUrl + "/sous_menu/" + id, datas, header());
  }
  deleteMenu(id) {
    return axios.delete(apiUrl + "/menu/" + id, header());
  }
  deleteMenuImage(id) {
    return axios.delete(apiUrl + "/menu/image/" + id, header());
  }
}

export default new MenuService();
