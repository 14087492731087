import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import {
  IconButton,
  LinearProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PhotoIcon from "@mui/icons-material/Photo";

import Swal from "sweetalert2";
import { getImg } from "../../../services/MenuService";

const SuccessNotification = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export const TableArtsMalagasy = (props) => {
  const [menu, setMenu] = useState(null);

  const loadRef = useRef();
  const listRef = useRef();

  useEffect(() => {
    if (props.menu) {
      setMenu(props.menu);
      loadRef.current.hidden = true;
    }
  }, [props.menu]);
  const row = (data) => {
    return (
      <>
      <TableRow
        key={`fr${data.id}`}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell rowSpan={2} component="th" scope="row">
          {data?.images?.length > 0 && (
            <Box
              component="img"
              sx={{ maxWidth: "200px" }}
              src={getImg(data.images)}
            />
          )}
          {data.name}
        </TableCell>
        <TableCell align="right">{data.titre}</TableCell>
        <TableCell align="right">{data.sousTitre}</TableCell>
        <TableCell align="right">{data.description}</TableCell>

        <TableCell rowSpan={2} align="right">
          <IconButton
            aria-label="image"
            onClick={(e) => props.selectImage(data.id)}
            size="large"
          >
            <PhotoIcon color="primary" />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => props.onEdit(data)}
            size="large"
          >
            <EditIcon color="warning" />
          </IconButton>
          {props.delete && (
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => props.onDelete(data)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow
        key={`en${data.id}`}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="right">{data.en_titre??""}</TableCell>
        <TableCell align="right">{data.en_sousTitre??""}</TableCell>
        <TableCell align="right">{data.en_description??""}</TableCell>
      </TableRow>
      </>
    );
  };

  return (
    <Box>
      <Box ref={loadRef} sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
      <TableContainer ref={listRef} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell align="right">Titre</TableCell>
              <TableCell align="right">sous-titre</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menu &&
              (menu.length !== undefined ? menu.map((d) => row(d)) : row(menu))}
            {/* {menu && row(menu)} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
