import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Couleur, Header } from "../Header";

import photoGastronomie from "../../assets/images/Gastronomie/gastronomie.jpg";
import photoObistro from "../../assets/images/Gastronomie/obistro.jpg";

import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FigureText } from "../tools/Texts";
import { Footer } from "../Footer";
import MenuService, { getImg } from "../services/MenuService";
import { baseUrl } from "../services/module";
import { MENU_ID_SITE } from "../services/menuID";
import { useLangue } from "../../i18n";

const Gastronomie = () => {
  const [menu, setMenu] = useState();
  const [sousMenu, setSousMenu] = useState();
  const isFrancais = useLangue();


  useEffect(() => {
    MenuService.getMenu(MENU_ID_SITE.GASTRONOMIE).then((res) => {
      setMenu(res.data.data);
    });
    MenuService.getAllSousMenu(MENU_ID_SITE.GASTRONOMIE).then((res) => {
      setSousMenu(res.data.data);
    });
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <Header
        backgUrl={
          menu?.images.length === 0 ? photoGastronomie : getImg(menu?.images)
        }
        headerTitle={isFrancais? menu?.titre : menu?.en_titre ?? menu?.titre }
      />

      <Box h="91vh" px={9}>
        <Box
          textAlign="center"
          py={2}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Typography
            color={Couleur.bordeau}
            component="p"
            sx={{
              fontSize: { xs: "60px", sm: "100px" },
              lineHeight: { xs: "55px", sm: "100px" },
              fontFamily: "'Oleo Script', 'Times New Roman', serif",
            }}
          >
            {isFrancais? menu?.sousTitre ?? menu?.titre : menu?.en_sousTitre ?? menu?.en_titre ?? menu?.sousTitre ?? menu?.titre}
          </Typography>
          <Box
            component="p"
            className="text-adjust"
            px="10%"
            data-aos="fade-up"
            data-aos-duration="500"
            pt={5}
          >
            { isFrancais? menu?.description : menu?.en_description ?? menu?.description}
          </Box>
        </Box>

        <Grid container spacing={5} justifyContent="center">
          {sousMenu?.map((s) => (
            <Grid
              key={s.id}
              className="roomImage"
              item
              sm={12}
              md={6}
              position="relative"
              display="flex"
              justifyContent="center"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <Box className="overlap-img cadreImage" component="figure">
                <img src={getImg(s.images)} width="100%" alt={isFrancais? s.titre : s.en_titre ?? s.en_sousTitre ?? s.titre } />
                <Link to={`/gastronomie/${s.id}`}>
                  <figcaption>
                    <FigureText component="p">{isFrancais? s.titre : s.en_titre ?? s.en_sousTitre ?? s.titre }</FigureText>
                  </figcaption>
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Footer />
    </Box>
  );
};

export default Gastronomie;
