import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Stack,
  StepIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";

import PhotoIcon from "@mui/icons-material/Photo";

import { Couleur } from "../../Header";
import MenuService from "../../services/MenuService";
import { SuccessNotification } from "./ModeleChambre";
import { TableArtsMalagasy } from "./extends/TableArtsMalagasy";
import { useNavigate } from "react-router-dom";
import ModeleChambreService from "../../services/ModeleChambreService";
import { baseUrl } from "../../services/module";
import ServiceService from "../../services/ServiceService";

const TableService = (props) => {
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState(null);
  const [item, setItem] = useState(null);
  const [imageChambre, setImageChambre] = useState(null);
  const [currentID, setCurrentID] = useState(0);

  const loadRef = useRef();
  const listRef = useRef();
  const imageRef = useRef();

  useEffect(() => {
    if (props.services) {
      setServices(props.services);
      loadRef.current.hidden = true;
    }
  }, [props.services]);
  const row = (data) => {
    return (
      <>
      <TableRow
        key={data.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell rowSpan={2} component="th" scope="row">
          {data?.images?.length > 0 && (
            <Box
              component="img"
              sx={{ maxWidth: "200px" }}
              src={baseUrl + data.images[0].libelle}
            />
          )}
          {data.name}
        </TableCell>
        <TableCell align="right">{data.titre}</TableCell>
        <TableCell align="right">
          {data?.item_menus?.map((item) => (
            <li key={item.id}>{item.libelle}</li>
          ))}
        </TableCell>

        <TableCell rowSpan={2} align="right">
          <IconButton
            aria-label="image"
            onClick={(e) => props.selectImage(data.id)}
            size="large"
          >
            <PhotoIcon color="primary" />
          </IconButton>
          <IconButton
            aria-label="image"
            onClick={(e) => props.selectItemService(data.id)}
            size="large"
          >
            <FormatListBulletedIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => props.onEdit(data)}
            size="large"
          >
            <EditIcon color="warning" />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => props.onDelete(data)}
            size="large"
          >
            <DeleteIcon color="warning" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        key={data.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="right">{data.en_titre}</TableCell>
        <TableCell align="right">
          {data?.item_menus?.map((item) => (
            <li key={item.id}>{item.en_libelle}</li>
          ))}
        </TableCell>
      </TableRow>
      </>
    );
  };

  return (
    <Box>
      <Box ref={loadRef} sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
      <TableContainer ref={listRef} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell align="right">Titre</TableCell>
              <TableCell align="right">item Service</TableCell>
              <TableCell align="right">action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.length > 0 && services.map((d) => row(d))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Service = (props) => {
  const [ID_MENU, setID_MENU] = useState();

  const [state, setState] = useState(false);
  const [isService, setIsService] = useState(false);
  const navigate = useNavigate();
  const [idMenu, setIdMenu] = useState(null);
  const [menu, setMenu] = useState(null);
  const [services, setServices] = useState([]);
  const [titre, setTitre] = useState("");
  const [enTitre, setEnTitre] = useState("");
  const [sousTitre, setSousTitre] = useState("");
  const [enSousTitre, setEnSousTitre] = useState("");
  const [description, setDescription] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [images, setImages] = useState(null);
  const imageRef = useRef();

  const onCancel = () => {
    setState(false);
    clearValue();
  };

  useEffect(() => {
    clearValue();
    setID_MENU(props.id);
    getData(props.id);
  }, [props.id]);

  const getData = (id) => {
    MenuService.getMenu(id ?? ID_MENU).then((res) => {
      setMenu({...res.data.data});
    });
    ServiceService.listService().then((res) => {
      setServices([...res.data.data]);
    });
  };
  const clearValue = () => {
    setIsService(false);

    setDescription("");
    setSousTitre("");
    setTitre("");
    setEnDescription("");
    setEnSousTitre("");
    setEnTitre("");
    setImages(null);
    setIdMenu(null);
    setState(false);
    imageRef.current.value = null;
  };
  const onChangePhoto = (e) => {
    const fileList = [];
    for (let i = 0; i < e.target.files.length; i++) {
      fileList.push(e.target.files[i]);
    }
    setImages(fileList);
  };
  const onDeleteMenu = (data) => {
    Swal.fire({
      title: "Suppression",
      text: "Cette action est irreversible, continuer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#37474F",
      confirmButtonText: "oui, continuer",
      cancelButtonText: "annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        ServiceService.deleteService(data.id)
          .then((res) => {
            getData(ID_MENU);
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: "Opération non effectuée",
            });
          });
      }
    });
  };

  const onEditMenu = (data) => {
    setState(true);
    setIdMenu(data.id);
    if (!data.description) {
      setTitre(data.titre);
      setEnTitre(data.en_titre);
      setIsService(false);
    } else {
      setIsService(true);
     if (data.estMenu === 0){
      setTitre(data.titre)
      setEnTitre(data.en_titre)
    }
     else{
      setTitre(null);
      setEnTitre(null);
    }
      setSousTitre(data.sousTitre ?? "");
      setDescription(data.description ?? "");
      setEnSousTitre(data.en_sousTitre ?? "");
      setEnDescription(data.en_description ?? "");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let datas = {};

    if (isService) {
      datas = {
        sousTitre,
        en_sousTitre: enSousTitre,
        description,
        en_description : enDescription,
      };
    } else {
      datas = { ...datas, titre , en_titre : enTitre };
    }
    if (images) datas["imageContentMenu"] = images;
    if (idMenu === null) {
      ServiceService.storeService(datas)
        .then((res) => {
          SuccessNotification.fire({
            icon: "success",
            title: res.data.message,
          });
          getData();
          clearValue();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oups...",
            text: err,
          });
        });
    } else {
      if (titre) {
        ServiceService.updateService(datas, idMenu)
          .then((res) => {
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
            getData();
            clearValue();
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: err,
            });
          });
      } else {
        MenuService.updateMenu(datas, idMenu)
          .then((res) => {
            SuccessNotification.fire({
              icon: "success",
              title: res.data.message,
            });
            getData();
            clearValue();
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oups...",
              text: err,
            });
          });
      }
    }
  };
  return (
    <div style={{ padding: "0 1rem" }}>
      <Box px={5} mb={7} mt={{ xs: 10, sm: 10, md: 1 }}>
        <Box
          component="ol"
          className="breadcrumb mb-4"
          sx={{
            backgroundColor: Couleur.jaune,
            p: 2,
            width: "100%",
            borderRadius: "4px",
          }}
        >
          <li className="breadcrumb-item">
            <a href="#!">
              <label>Page actuelle :</label>
            </a>
          </li>
          <li className="breadcrumb-item">
            <a href="#!">Dashboard</a>
          </li>
          <li className="breadcrumb-item active">{menu?.titre}</li>
        </Box>
      </Box>
      <div style={{ padding: "1rem" }}>
        <Card
          component="form"
          onSubmit={(e) => onSubmit(e)}
          sx={{ width: "800px", padding: "1rem" }}
        >
          {titre !== null && (
            <Stack direction="row" spacing={3}>
            <Box>
              <CardActions>Nouveau Sous Titre</CardActions>
                <TextField
                  label="titre"
                  value={titre}
                  onChange={(e) => setTitre(e.target.value)}
                  variant="standard"
                  sx={{
                    fontFamily: "serif, arial",
                    fontSize: "18px",
                    width: "100%",
                    color: Couleur.bordeau,
                    cursor: "pointer",
                  }}
                />
            </Box>
            <Box>
              <CardActions>Nouveau Sous Titre en englais</CardActions>
                <TextField
                  label="titre en englais"
                  value={enTitre}
                  onChange={(e) => setEnTitre(e.target.value)}
                  variant="standard"
                  sx={{
                    fontFamily: "serif, arial",
                    fontSize: "18px",
                    width: "100%",
                    color: Couleur.bordeau,
                    cursor: "pointer",
                  }}
                />
            </Box>
            
            </Stack>
          )}
          {isService && (
            <Stack direction="row" spacing={3}>
              <Box>
                <CardActions>Nouveau Sous Titre</CardActions>
                <TextField
                  label="sous titre"
                  value={sousTitre}
                  onChange={(e) => setSousTitre(e.target.value)}
                  variant="standard"
                  sx={{
                    fontFamily: "serif, arial",
                    fontSize: "18px",
                    width: "100%",
                    color: Couleur.bordeau,
                    cursor: "pointer",
                  }}
                />
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  variant="standard"
                  rows={4}
                  multiline
                  sx={{ width: "100%" }}
                />
              </Box>
              <Box>
                <CardActions>Sous Titre en englais</CardActions>
                <TextField
                  label="sous titre"
                  value={enSousTitre}
                  onChange={(e) => setEnSousTitre(e.target.value)}
                  variant="standard"
                  sx={{
                    fontFamily: "serif, arial",
                    fontSize: "18px",
                    width: "100%",
                    color: Couleur.bordeau,
                    cursor: "pointer",
                  }}
                />
                <TextField
                  label="Description"
                  value={enDescription}
                  onChange={(e) => setEnDescription(e.target.value)}
                  variant="standard"
                  rows={4}
                  multiline
                  sx={{ width: "100%" }}
                />
              </Box>
            </Stack>
          )}

          <Grid item md={12} px={3} py={2}>
            <Box
              component="input"
              id="photo"
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => onChangePhoto(e)}
              ref={imageRef}
              sx={{
                fontFamily: "serif, arial",
                fontSize: "18px",
                color: Couleur.bordeau,
                cursor: "pointer",
              }}
            ></Box>
          </Grid>

          {!state ? (
            <CardActions>
              <Button variant="contained" type="submit">
                ajouter
              </Button>
            </CardActions>
          ) : (
            <CardActions>
              <Button variant="contained" color="warning" type="submit">
                modifier
              </Button>
              <Button
                variant="contained"
                onClick={onCancel}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  "&:hover": { backgroundColor: "#eeefff" },
                }}
              >
                annuler
              </Button>
            </CardActions>
          )}
        </Card>
      </div>
      <Box>
        <TableArtsMalagasy
          selectImage={(id) => navigate(`/admin/dashboard/menu/${id}/images`)}
          onEdit={onEditMenu}
          menu={menu}
        />
      </Box>
      <Box>
        <Box component="h1">les sous Menu</Box>
        <TableService
          selectImage={(id) =>
            navigate(`/admin/dashboard/services/${id}/images`)
          }
          onDelete={onDeleteMenu}
          onEdit={onEditMenu}
          selectItemService={(id) => {
            navigate(`/admin/dashboard/services/${id}/serviceList`);
          }}
          services={services}
          titre={true}
        />
      </Box>
    </div>
  );
};

export default Service;
