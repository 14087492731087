import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Couleur } from "./Header";
import { MENU_ID_SITE } from "./services/menuID";
import MenuService from "./services/MenuService";
import { useLangue } from "../i18n";

const BoxMenu = styled(Box)({
  height: "100vh",
  zIndex: "100",
  width: "100%",
  backgroundColor: "black",
  opacity: "0.9",
  position: "relative",

  overflowX: "hidden",
});

const HeaderMenu = (props) => {
  let navigate = useNavigate();
  const [gastronomie, setGastronomie] = useState();
  const [art, setArt] = useState();
  const [bienEtre, setBienEtre] = useState();
  const [evenement, setEvenement] = useState();
  const isFrancais = useLangue();


  const goTo = (somewhere) => {
    props.handleChange();
    navigate(somewhere);
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let gastros = await MenuService.getAllSousMenu(MENU_ID_SITE.GASTRONOMIE);
    setGastronomie(gastros.data.data);
    let arts = await MenuService.getAllSousMenu(
      MENU_ID_SITE.ART_PRODUIT_MALAGASY
    );
    setArt(arts.data.data);
    let biens = await MenuService.getAllSousMenu(MENU_ID_SITE.BIEN_ETRE_LOISIR);
    setBienEtre(biens.data.data);
    let events = await MenuService.getAllSousMenu(
      MENU_ID_SITE.EVENEMENT_SEMINAIRE
    );
    setEvenement(events.data.data);
  };
  const TextLink = styled("span")({
    cursor: "pointer",
    color: Couleur.jaune,
    "&:hover": { color: Couleur.bordeau, marginLeft: "18px" },
    transition: "all .8s",
    fontFamily: "Maryellen, serif",
    fontSize: "3.8rem",
    lineHeight: "45px",
  });
  const SubTextLink = styled("span")({
    cursor: "pointer",
    color: "white",
    "&:hover": { color: Couleur.bordeau, marginLeft: "18px" },
    transition: "all .8s",
    fontSize: "1.55rem",
    fontWeight: "bold",
    textTransform: "capitalize",
    fontFamily: "Marllgon, serif",
    lineHeight: "40px",
  });

  return (
    <Box
      ref={props.menuBox}
      hidden
      position="fixed"
      sx={{ width: "100%", zIndex: "10" }}
    >
      {/* FOR MEDIUM AND LARGE SCREEN */}
      <BoxMenu
        sx={{
          overflowY: { sm: "auto" },
          textAlign: { xs: "center", sm: "left" },
          display: { xs: "block", sm: "none", md: "block" },
        }}
      >
        <Stack
          direction={{ sm: "column", md: "row" }}
          justifyContent="space-evenly"
          sx={{
            width: "100vw",
            overflowY: "visible",
            overfowX: "hidden",
            paddingTop: { xs: "25%", md: "20%", lg: "13%" },
          }}
        >
          <Box m={2}>
            <Stack sx={{ display: { sm: "block", md: "none" } }}>
              <Box mb={4}>
                <Typography component="p">
                  <TextLink onClick={() => goTo("/")}>.Accueil</TextLink>
                </Typography>
              </Box>
            </Stack>
            <Stack>
              <Box mb={4}>
                <Typography component="p">
                  <TextLink onClick={() => goTo("/chambre")}>
                    .Hébergement
                  </TextLink>
                </Typography>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre/double")}>
                  Chambres double
                </SubTextLink>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre/triple")}>
                  Chambres triple
                </SubTextLink>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre/suite")}>
                  Suite
                </SubTextLink>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre/familiale")}>
                  Familiale
                </SubTextLink>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre/appartement")}>
                  Appartement prestige
                </SubTextLink>
              </Box>
            </Stack>
          </Box>

          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p">
                  <TextLink onClick={() => goTo("/gastronomie")}>
                    .Gastronomie
                  </TextLink>
                </Typography>
              </Box>
              {gastronomie &&
                gastronomie.map((g, index) => (
                  <SubTextLink key={index} onClick={() => goTo(`/gastronomie/${g.id}`)}>
                    {isFrancais? g.titre : g.en_titre ?? g.titre}
                  </SubTextLink>
                ))}
            </Stack>
          </Box>

          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p" textAlign="center">
                  <TextLink onClick={() => goTo("/bien-etre")}>
                    .Bien-être & <br /> loisirs
                  </TextLink>
                </Typography>
              </Box>
              {bienEtre &&
                bienEtre.map((b, index) => (
                  <SubTextLink key={index} onClick={() => goTo("/bien-etre/" + b.id)}>
                  {isFrancais? b.titre : b.en_titre ?? b.titre}
                  </SubTextLink>
                ))}
            </Stack>
          </Box>

          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p" textAlign="center">
                  <TextLink onClick={() => goTo("/evenement-seminaire")}>
                    .Evènement & <br /> séminaire
                  </TextLink>
                </Typography>
              </Box>
              {evenement &&
                evenement.map((e, index) => (
                  <SubTextLink
                    key={index}
                    onClick={() => goTo("/evenement-seminaire/" + e.id)}
                  >
                  {isFrancais? e.titre : e.en_titre ?? e.titre}
                  </SubTextLink>
                ))}
            </Stack>
          </Box>

          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p" textAlign="center">
                  <TextLink onClick={() => goTo("/art")}>
                    .Art & produits
                    <br /> malagasy
                  </TextLink>
                </Typography>
              </Box>
              {art &&
                art.map((a, index) => (
                  <SubTextLink key={index} onClick={() => goTo("/art/" + a.id)}>
                    {isFrancais? a.titre : a.en_titre ?? a.titre}
                  </SubTextLink>
                ))}
            </Stack>
          </Box>
        </Stack>

        <Stack
          justifyContent="space-evenly"
          sx={{
            color: "white",
            bottom: "10px",
            paddingTop: 10,
          }}
        >
          <Grid
            container
            // mt={{ xs: "3rem" }}
            mt={{ xs: "1rem" }}
            textAlign="center"
          >
            <Grid item xs={12} sm={4} onClick={() => goTo("/information")}>
              <SubTextLink>Informations & contact</SubTextLink>
            </Grid>
            <Grid item xs={12} sm={4} onClick={() => goTo("/service")}>
              <SubTextLink>Services</SubTextLink>
            </Grid>
            <Grid item xs={12} sm={4} onClick={() => goTo("/galerie")}>
              <SubTextLink>Galerie photo</SubTextLink>
            </Grid>
          </Grid>
        </Stack>
        {/* {bottomText} */}
      </BoxMenu>

      {/* FOR SMALL SCREEN */}
      <BoxMenu
        sx={{
          display: { xs: "none", sm: "block", md: "none" },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-evenly"
          sx={{
            width: "100vw",
            overflowY: "visible",
            overfowX: "hidden",
            paddingTop: "18%",
          }}
        >
          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p">
                  <TextLink onClick={() => goTo("/chambre")}>
                    .Hébergement
                  </TextLink>
                </Typography>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre")}>
                  Chambres double
                </SubTextLink>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre")}>
                  Chambres triple
                </SubTextLink>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre")}>
                  Suite
                </SubTextLink>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre")}>
                  Familiale
                </SubTextLink>
              </Box>
              <Box>
                <SubTextLink onClick={() => goTo("/chambre")}>
                  Appartement prestige
                </SubTextLink>
              </Box>
            </Stack>
          </Box>

          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p">
                  <TextLink onClick={() => goTo("/gastronomie")}>
                    .Gastronomie
                  </TextLink>
                </Typography>
              </Box>
              {gastronomie &&
                gastronomie.map((g, index) => (
                  <SubTextLink key={index} onClick={() => goTo(`/gastronomie/${g.id}`)}>
                  {isFrancais? g.titre : g.en_titre ?? g.titre}
                  </SubTextLink>
                ))}
            </Stack>
          </Box>

          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p" textAlign="center">
                  <TextLink onClick={() => goTo("/bien-etre")}>
                    .Bien-être & <br /> loisirs
                  </TextLink>
                </Typography>
              </Box>
              {bienEtre &&
                bienEtre.map((b, index) => (
                  <SubTextLink key={index} onClick={() => goTo("/bien-etre/" + b.id)}>
                    {isFrancais? b.titre : b.en_titre ?? b.titre}
                  </SubTextLink>
                ))}
            </Stack>
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          sx={{
            width: "100vw",
            overflowY: "visible",
            overfowX: "hidden",
            paddingTop: "25px",
          }}
        >
          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p" textAlign="center">
                  <TextLink onClick={() => goTo("/evenement-seminaire")}>
                    .Evènement & <br /> séminaire
                  </TextLink>
                </Typography>
              </Box>
              {evenement &&
                evenement.map((e, index) => (
                  <SubTextLink
                    key={index}
                    onClick={() => goTo("/evenement-seminaire/" + e.id)}
                  >
                    {isFrancais?e.titre : e.en_titre ?? e.titre}
                  </SubTextLink>
                ))}
            </Stack>
          </Box>

          <Box m={2}>
            <Stack>
              <Box mb={4}>
                <Typography component="p" textAlign="center">
                  <TextLink onClick={() => goTo("/art")}>
                    .Art & produits
                    <br /> malagasy
                  </TextLink>
                </Typography>
              </Box>
              {art &&
                art.map((a, index) => (
                  <SubTextLink key={index} onClick={() => goTo("/art/" + a.id)}>
                    {isFrancais? a.titre : a.en_titre ?? a.titre}
                  </SubTextLink>
                ))}
            </Stack>
          </Box>
        </Stack>

        <Stack
          justifyContent="space-evenly"
          sx={{
            color: "white",
            bottom: "10px",
            paddingTop: 10,
          }}
        >
          <Grid
            container
            // mt={{ xs: "3rem" }}
            mt={{ xs: "1rem" }}
            textAlign="center"
          >
            <Grid item xs={12} sm={4} onClick={() => goTo("/information")}>
              <SubTextLink>Informations & contact</SubTextLink>
            </Grid>
            <Grid item xs={12} sm={4} onClick={() => goTo("/service")}>
              <SubTextLink>Services</SubTextLink>
            </Grid>
            <Grid item xs={12} sm={4} onClick={() => goTo("/galerie")}>
              <SubTextLink>Galerie photo</SubTextLink>
            </Grid>
          </Grid>
        </Stack>
      </BoxMenu>
    </Box>
  );
};

export default HeaderMenu;
