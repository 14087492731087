import { Box } from '@mui/material'
import React from 'react'
import '../../assets/scss/footer.scss'
import { Footer } from '../Footer'
import { Header } from '../Header'

export const ConditionsGenerales = () => {
    return (
        <Box>
            <Header
                className="contact-header"
                minH="5vh"
                backgUrl="none"
                showRes="block"
                showCall="none"
            />
            <section className='container' style={{padding: '0 6% 0 6%'}}>
                <h3>Conditions générales de vente</h3>
                <div className='mt-4'>
                    <p className='fw-bold'>Article 1 - durée du séjour :</p>
                    <p>
                        Le client signataire du contrat conclu pour une durée déterminée ne pourra en aucune circonstance se prévaloir d'un quelconque droit au maintien dans les lieux.
                    </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 2 - conclusion du contrat :</p>
                    <p>
                        La réservation en ligne devient effective dès lors que le client l’aura garantie par carte bancaire valide à la date du début du séjour.
                    </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 3 - annulation par le client:</p>
                    <p><b>a)</b> En cas d’annulation ou de modification jusqu’à  jours avant la date d’arrivée, aucun frais ne sera prélevé. </p>
                    <p><b>b)</b> En cas d’annulation ou de modification tardive ou de non-présentation, le propriétaire exige 100% du montant du séjour. </p>
                    <p><b>c)</b> En cas de séjour écourté, le prix correspondant au coût de l'hébergement reste acquis au propriétaire.  </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 4 - arrivée : </p>
                    <p>
                        Le client est accueilli le jour précisé à partir de 17 H ou plutôt selon la disponibilité. En cas d'arrivée tardive, le client doit prévenir le propriétaire.
                    </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 5 – Règlements : </p>
                    <p>
                        Règlement au propriétaire par : Espèces, CB ou virement.
                    </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 6 - taxe de séjour : </p>
                    <p>
                        La taxe de séjour est un impôt local que le client doit acquitter auprès du propriétaire qui la reverse ensuite au Trésor Public.
                    </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 7 - utilisation des lieux : </p>
                    <p>
                        Le client devra respecter le caractère paisible des lieux, en faire un usage conforme à leur destination. Il s’engage à rendre les hébergements en bon état.
                    </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 8 - animaux : </p>
                    <p>
                        Le contrat précise que le client ne peut séjourner en compagnie d'un animal domestique. En cas de non respect de cette clause par le client, le propriétaire peut refuser les animaux. Ce refus ne peut être en aucun cas considéré comme une modification ou une rupture du contrat à l'initiative du propriétaire, de sorte qu'en cas de départ du client, le prix correspondant au coût de l'hébergement reste acquis au propriétaire.
                    </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 9- capacité : </p>
                    <p>
                        Le contrat est établi pour un nombre précis de personnes. Si le nombre de clients dépasse ce nombre, le propriétaire est en mesure de refuser les clients supplémentaires. Ce refus ne peut en aucun cas être considéré comme une modification ou une rupture du contrat à l'initiative du propriétaire, de sorte qu'en cas de départ d'un nombre de clients supérieur à ceux refusés, le prix correspondant au coût de l'hébergement reste acquis au propriétaire.
                    </p>
                </div>
                <div className='mt-5 pt-4'>
                    <p className='fw-bold'>Article 10 - assurance : </p>
                    <p>
                        Le client est responsable de tous les dommages survenant de son fait.Il doit donc vérifier si son contrat d'habitation principale prévoit l'extension villégiature (location de vacances). Le locataire atteste être couvert par une assurance de responsabilité civile couvrant sa propre responsabilité et celle des personnes l'accompagnant.
                    </p>
                </div>
                <div className='my-5 pt-4'>
                    <p className='fw-bold'>Article 11 - litiges : </p>
                    <p>
                        Il est recommandé de s'adresser à l'Office de Tourisme qui interviendra pour favoriser le règlement à l'amiable des litiges - si la réclamation est formulée dans les trois premiers jours après l'arrivée, pour tout litige concernant l'état des lieux ou l'état descriptif,- à l'issue du séjour pour toutes les autres contestations.Pour tous les litiges qui naîtraient de l’exécution ou de l’interruption du présent contrat, seuls les Tribunaux du ressort du lieu de l’immeuble objet de la location sont compétents.
                    </p>
                </div>
            </section>
            <Footer />
        </Box>
    )
}