import { Button, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { FullBox } from '../accueil/Content'

import AOS from 'aos';
import 'aos/dist/aos.css';

import AccountCircle from '@mui/icons-material/AccountCircle';
import { Couleur } from '../Header';

import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContactService from '../services/ContactService.js';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';

export const Contact = (props) => {
    const [nom, setNom] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)
  const [t, i18n] = useTranslation("common");



    useEffect(() => {
        AOS.init()
    }, []);

    const clearValue = () => {
        setNom('')
        setEmail('')
        setMessage('')
        setError(false)
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const datas = {
            nom: nom,
            email: email,
            message: message
        }

        if (nom && email && message) {
            props.setOpen(true)
            ContactService.contactingZomatel(datas)
                .then(res => {
                    Swal.fire({
                        icon: "success",
                        title: "Contact",
                        text: res.data.message,
                    });
                    clearValue()
                    props.setOpen(false)
                })
                .catch(err => {
                    Swal.fire({
                        icon: "error",
                        title: "Oups...",
                        text: "Une erreur s'est produite, nous vous prions de contacter le service client",
                    });
                    props.setOpen(false)
                });
        }
        else {
            setError(true)
        }
    }

    return (
        <FullBox px={9} h='60vh' data-aos="zoom-in" data-aos-duration="1000" mt={20}>
            <Grid container justifyContent='center'>
                <Grid item md={3} position='relative'>
                    <Box sx={{ width: '100%', height: '100%', position: 'absolute', paddingTop: '36%', backgroundColor: Couleur.jaune }}>
                        <Typography component='p'
                            sx={{
                                fontSize: { xs: '40px', sm: '50px' },
                                lineHeight: { xs: '55px', sm: '100px' },
                                fontFamily: "'Oleo Script', 'Times New Roman', serif",
                                color: '#850000',
                                textAlign: 'center',
                                textDecoration: 'underline',
                                fontWeight: 'light'
                            }}
                        >{t("contact.Contactez-nous")}</Typography>

                    </Box>
                </Grid>
                <Grid item md={4} position='relative'>
                    <Paper elevation={5}>
                        <Box
                            component="form"
                            p={5}
                            onSubmit={e => onSubmit(e)}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <AccountCircle />
                                <TextField id="input-with-sx" label={t("contact.nom")} variant="standard" value={nom} sx={{ width: '100%' }} onChange={(e) => setNom(e.target.value)} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
                                <MailIcon />
                                <TextField id="input-with-sx" label="Email" type='email' value={email} variant="standard" sx={{ width: '100%' }} onChange={(e) => setEmail(e.target.value)} />
                            </Box>
                            <Box mt={6}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label={t("contact.votre message")}
                                    value={message}
                                    multiline
                                    rows={4}
                                    sx={{ width: '100%' }}
                                    onChange={(e) => setMessage(e.target.value)}
                                // defaultValue="Default Value"
                                />
                            </Box>
                            <Box component="p" sx={{ color: "red" }} mt={4} hidden={!error}>Tous les champs sont obligatoires</Box>
                            <Box mt={4} display='flex' justifyContent='space-between'>
                                <Box pt={2}>
                                    <Button variant='contained' type='submit' sx={{ backgroundColor: Couleur.jaune, color: Couleur.bordeau, '&:hover': { backgroundColor: Couleur.bordeau, color: Couleur.jaune } }} endIcon={<SendIcon />}>{t("btn.envoyer")}</Button>
                                </Box>
                                <Box>
                                    <a href="https://www.facebook.com/hotelzomatel" target="_blank">
                                        <IconButton color="primary" >
                                            <FacebookIcon sx={{ fontSize: '50px' }} />
                                        </IconButton>
                                    </a>
                                    <a href="#!">
                                        <IconButton color="success" >
                                            <WhatsAppIcon sx={{ fontSize: '50px' }} />
                                        </IconButton>
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </FullBox>
    )
}
