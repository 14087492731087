import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AOS from "aos";
import React, { useEffect, useState } from "react";
import { Couleur } from "../../../Header";
import DeviseService from "../../../services/DeviseService";
import { YellowButton } from "../../../tools/Buttons";
import ChambreResume from "./ChambreResume";
import Swal from "sweetalert2";

export const DEFAULT_LIST_CHAMBRE = [
  {
    chambre: 1,
    kids: 0,
    adults: 1,
    name: "",
    id: 0,
    montant: 0,
  },
];

export const Resume = (props) => {
  const [chambres, setChambres] = useState([]);
  const [listDevise, setListDevise] = useState(null);
  const [devise, setDevise] = useState(0); // store ID of currency
  const [listchambres, setListChambres] = useState(DEFAULT_LIST_CHAMBRE);

  useEffect(() => {
    setChambres(props.chambres);
  }, [props.chambres]);

  useEffect(() => {
    AOS.init();
    DeviseService.listDevise().then((res) => {
      setListDevise(res.data.data);
    });
  }, []);

  const addRoom = () => {
    const newChambre = {
      chambre: listchambres.length + 1,
      kids: 0,
      id: 0,
      adults: 1,
      montant: 0,
    };
    const newlistchambreSelected = [...props.listChambreSelected, 0];
    const newListChambre = [...listchambres, newChambre];
    setListChambres(newListChambre);
    props.setListChoixChambre(newListChambre);
    props.setListChambreSelected(newlistchambreSelected);
  };

  const ChambreResumeHandle = (chambre, index) => {
    const newlistchambres = [...listchambres];
    newlistchambres[index] = chambre;

    setListChambres(newlistchambres);
    props.setListChoixChambre(newlistchambres);

    if (props.listChambreSelected[index] !== 0) {
      const newlistchambreSelected = [...props.listChambreSelected];
      let newTotal = props.total;
      if (props.currentDevise) {
        newTotal -=
          (newlistchambreSelected[index].tarif /
            props.currentDevise.valeur_ariary) *
          props.sejour;
      } else {
        newTotal -= newlistchambreSelected[index].tarif * props.sejour;
      }

      // const newTotal = props.total - tarif * props.sejour
      props.updateTotal(newTotal);
      newlistchambreSelected[index] = 0;
      props.setListChambreSelected(newlistchambreSelected);
    }
  };

  const removeRoom = (index, tarif = 0, chambre = null) => {
    const newlistchambres = [...listchambres];
    const newlistchambreSelected = [...props.listChambreSelected];
    const chambres = newlistchambres.filter((chambre, index2) => {
      return index !== index2;
    });
    const chambreSelected = newlistchambreSelected.filter((chambre, index2) => {
      return index !== index2;
    });

    if (chambre) {
      props.setInDisponible(chambre);
    }
    let newTotal = props.total;
    if (props.currentDevise) {
      newTotal -=
        (newlistchambreSelected[index].tarif /
          props.currentDevise.valeur_ariary) *
        props.sejour;
    } else {
      newTotal -= newlistchambreSelected[index].tarif * props.sejour;
    }

    // const newTotal = props.total - tarif * props.sejour
    props.updateTotal(newTotal);

    setListChambres([...chambres]);
    props.setListChoixChambre([...chambres]);
    props.setListChambreSelected([...chambreSelected]);
    if (props.currentStep > 1) {
      props.setCurrentStep((s) => s - 1);
      props.setIndexListChambre((index) => index - 1);
    }
  };

  const selectChambre = () => {
    if (
      props.dateDepart &&
      props.dateArrivee &&
      props.dateArrivee !== props.dateDepart
    ) {
      props.step2();
      const CURRENT_DEVISE = listDevise
        ? listDevise.find((currency) => currency.id === devise)
        : null;
      props.updateDevise(CURRENT_DEVISE ? CURRENT_DEVISE : null);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oups...",
        text: "N'oubliez pas de choisir la date de votre séjour",
      });
    }
  };
  const nextStep = () => {
    if (!props.isShowInfoClient) {
      props.nextStep();
    } else {
      ///payement
    }
  };

  return (
    <Box>
      <Paper elevation={6} sx={{ width: { xs: "350px", sm: "450px" } }}>
        <Box
          sx={{
            backgroundColor: `${Couleur.bordeau}`,
            color: "white",
            p: 3,
          }}
        >
          <Box
            component="p"
            textAlign="center"
            py={1}
            fontSize="40px"
            className="family"
          >
            Votre séjour
          </Box>
          <Box marginTop="20px">
            <Box display="flex" justifyContent="space-between" my={1}>
              <Box component="span" className="family resumeText">
                ARRIVÉE :
              </Box>
              <Box component="span" className="family resumeText">
                {props.dateArrivee && props.dateArrivee}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" my={1}>
              <Box component="span" className="family resumeText">
                DÉPART<Box component="span" mr={1.35}></Box>:
              </Box>
              <Box component="span" className="family resumeText">
                {props.dateDepart && props.dateDepart}
              </Box>
            </Box>
            {props.sejour !== 0 && (
              <Box display="flex" justifyContent="center" my={1}>
                <Box
                  component="span"
                  className="family resumeText"
                  fontWeight="bold"
                >
                  {props.sejour} nuitée
                  {`${props.sejour > 1 ? "s" : ""}`}
                </Box>
              </Box>
            )}
          </Box>
          {/* start of choosen room */}
          {listchambres.map((chambre, index) => (
            <ChambreResume
              modifiable={index + 1 > props.currentStep}
              currentStep={props.currentStep}
              key={index}
              chambreSelected={
                props.listChambreSelected && props.listChambreSelected[index]
              }
              isShowInfoClient={props.isShowInfoClient}
              onChangeStep={props.onChangeStep}
              removeRoom={removeRoom}
              modelChambre={chambre}
              index={index}
              onChange={ChambreResumeHandle}
            />
          ))}

          {/* end of choosen room */}
          <Box
            mt={2}
            textAlign="center"
            display="flex"
            hidden={props.hideAddRoom}
            flexDirection="column"
          >
            {!props.isShowInfoClient && (
              <Box marginLeft="10%" marginRight="10%" onClick={addRoom}>
                <Typography
                  component="p"
                  width="100%"
                  border="1px solid white"
                  borderLeft="none"
                  borderRight="none"
                  py="10px"
                  sx={{
                    cursor: "pointer",
                    transition: "all .5s",
                    "&:hover": {
                      backgroundColor: "white",
                      color: Couleur.bordeau,
                    },
                    fontSize: "18px",
                  }}
                >
                  Ajouter une chambre
                </Typography>
              </Box>
            )}
          </Box>
          {props.currentStep !== 0 && (
            <Paper elevation={2}>
              <Box
                mt={2}
                textAlign="center"
                display="flex"
                justifyContent="space-between"
                sx={{ backgroundColor: "white", borderRadius: "2px" }}
                px={2}
              >
                <Box
                  component="span"
                  className="family"
                  fontSize="25px"
                  color={Couleur.bordeau}
                >
                  TOTAL
                </Box>
                <Box
                  component="span"
                  className="family"
                  fontSize="25px"
                  color={Couleur.bordeau}
                >
                  {props.currentDevise &&
                  props.currentDevise.libelle.toLowerCase() === "dollar"
                    ? `${props.total.toLocaleString("en-Us")} $`
                    : props.currentDevise &&
                      props.currentDevise.libelle.toLowerCase() === "euro"
                    ? `${props.total.toLocaleString("fr-Fr")} €`
                    : props.currentDevise
                    ? `${props.total.toLocaleString("fr-Fr")} ${
                        props.currentDevise.libelle
                      }`
                    : `${props.total.toLocaleString("fr-Fr")} Ar`}
                </Box>
              </Box>
            </Paper>
          )}
          <Box mt={4} align="right">
            {props.currentStep === 0 ? (
              <Box
                mt={4}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "45px",
                }}
              >
                <Box sx={{ backgroundColor: "white", borderRadius: "5px" }}>
                  <FormControl>
                    <Select
                      sx={{
                        minWidth: 110,
                        height: "45px",
                        borderRadius: "5px",
                      }}
                      value={devise}
                      onChange={(e) => setDevise(e.target.value)}
                    >
                      <MenuItem value={0}>Ariary</MenuItem>
                      {listDevise &&
                        listDevise.map((currency, index) => (
                          <MenuItem key={index} value={currency.id}>
                            {currency.libelle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <YellowButton
                    h="45px"
                    sx={{
                      transition: "all .5s",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "white !important",
                        color: "#850000 !important",
                      },
                    }}
                    startIcon={<SearchIcon />}
                    onClick={selectChambre}
                  >
                    Rechercher
                  </YellowButton>
                </Box>
              </Box>
            ) : (
              <YellowButton
                h="45px"
                widthed={false}
                disabled={props.isShowInfoClient && !props.showPayementButton}
                sx={{
                  transition: "all .5s",
                  borderRadius: "0px",
                  "&:hover": {
                    backgroundColor: "white !important",
                    color: "#850000 !important",
                  },
                }}
                onClick={
                  props.isShowInfoClient ? props.handlePaiement : nextStep
                }
              >
                {props.isShowInfoClient
                  ? "Proceder à la réservation"
                  : "Continuer"}
              </YellowButton>
            )}
            {props.children}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

Resume.defaultProps = {
  hideAddRoom: false,
  hideEditButton: false,
  currentStep: 1,
  total: 0,
};
