import {
  Grid,
  IconButton,
  NativeSelect,
  Paper,
  Typography,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from "@mui/material";

import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { DateRange } from "react-date-range";
import { Couleur } from "../../Header";
import { SimpleTitle } from "../../tools/Texts";

import { addDays } from "date-fns";
import { fr } from "date-fns/locale";
import { useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import "../../../assets/scss/reserve.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export const SelectionDate = (props) => {
  const [arrivee, setArrivee] = useState([new Date(), 0]);
  const [depart, setDepart] = useState([new Date(), 0]);
  const [sejour, setSejour] = useState(0);
  const [listchambres, setListChambres] = useState([
    {
      chambre: 1,
      kids: 0,
      adults: 1,
      name: "",
      id: 0,
      montant: 0,
    },
  ]);

  const [dates, setDates] = useState({
    choosable: {
      startDate: addDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "choosable",
      color: Couleur.bordeau,
    },
    unchoosable: {
      startDate: null,
      endDate: addDays(new Date(), -1),
      key: "unchoosable",
      color: "gray",
      disabled: true,
    },
  });

  useEffect(() => {
    AOS.init();
    setDates({
      choosable: {
        startDate: addDays(new Date(props.checkedArrivee), 0),
        endDate: addDays(new Date(props.checkedDepart), 0),
        key: "choosable",
        color: Couleur.bordeau,
      },
      unchoosable: {
        startDate: null,
        endDate: addDays(new Date(), -1),
        key: "unchoosable",
        color: "gray",
        disabled: true,
      },
    });
  }, []);

  const getValue = (item) => {
    setDates({
      choosable: {
        startDate: addDays(item.choosable.startDate, 0),
        endDate: addDays(item.choosable.endDate, 0),
        key: "choosable",
        color: Couleur.bordeau,
      },
      unchoosable: {
        startDate: null,
        endDate: addDays(new Date(), -1),
        key: "unchoosable",
        color: "gray",
        disabled: true,
      },
    });

    let jour_arrivee =
      item.choosable.startDate.getDate() +
      " " +
      months[item.choosable.startDate.getMonth()] +
      " " +
      item.choosable.startDate.getFullYear();
    let jour_depart =
      item.choosable.endDate.getDate() +
      " " +
      months[item.choosable.endDate.getMonth()] +
      " " +
      item.choosable.endDate.getFullYear();
    setArrivee([item.choosable.startDate, 0]);
    setDepart([item.choosable.endDate, 0]);

    props.setDateArrivee(jour_arrivee);
    props.setDateDepart(jour_depart);
    props.setCheckedArrivee(item.choosable.startDate);
    props.setCheckedDepart(item.choosable.endDate);

    // --- différence de jours entre deux dates stocké dans la variable sejourValue
    const date_start = new Date(item.choosable.startDate);
    const date_end = new Date(item.choosable.endDate);
    const time_diff = date_end.getTime() - date_start.getTime();

    const sejourValue = Math.ceil(time_diff / (1000 * 3600 * 24));
    props.newSejour(sejourValue);
    setSejour(sejourValue);

    const MONTH_ARRIVEE = item.choosable.startDate.getMonth() + 1;

    let dtArrivee =
      item.choosable.startDate.getFullYear() +
      "-" +
      (item.choosable.startDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      item.choosable.startDate.getDate().toString().padStart(2, "0");
    let dtDepart =
      item.choosable.endDate.getFullYear() +
      "-" +
      (item.choosable.endDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      item.choosable.endDate.getDate().toString().padStart(2, "0");

    props.setDate({ dtArrivee, dtDepart });

    if (dtArrivee !== dtDepart) {
      let copy_listChambreSelected = [...props.listChambreSelected];
      copy_listChambreSelected = copy_listChambreSelected.map((list) => 0);
      props.setListChambreSelected(copy_listChambreSelected);
      props.updateTarif(0);
    }
  };

  return (
    <Box px={{ xs: 2, sm: 9, md: 9, lg: 9 }} mb={8}>
      <Box textAlign="center">
        <SimpleTitle>Réserver votre séjour</SimpleTitle>
      </Box>
      <Box mt={8}>
        <Paper elevation={2}>
          <Box
            component="p"
            sx={{
              color: Couleur.jaune,
              fontSize: "50px",
              fontFamily: "'Glacial Indifference', serif",
            }}
          >
            Sélectionner une date
          </Box>
          {/* FOR SCREEN MORE THAN EXTRA SMALL */}
          <Box
            className="dateRangeWrapper"
            sx={{
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
            p={2}
          >
            <DateRange
              locale={fr}
              editableDateInputs={true}
              onChange={(item) => getValue(item)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              minDate={addDays(new Date(), 0)}
              ranges={[dates.choosable]}
              months={2}
              direction="horizontal"
            />
          </Box>
          {/* FOR EXTRA SMALL SCREEN */}
          <Box
            className="dateRangeWrapper"
            p={2}
            sx={{
              display: { xs: "block", sm: "none", md: "none", lg: "none" },
            }}
          >
            <DateRange
              locale={fr}
              editableDateInputs={true}
              onChange={(item) => getValue(item)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              minDate={addDays(new Date(), 0)}
              ranges={[dates.choosable]}
              months={2}
              direction="vertical"
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
